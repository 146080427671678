import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { parseString } from 'xml2js';
import { Nav, Modal, Button } from 'react-bootstrap';
import './styles.css';
import CryptoJS from 'crypto-js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, parse, parseISO, isValid } from 'date-fns';
import Slider from 'rc-slider';
import "rc-slider/assets/index.css";
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';
import '@fortawesome/fontawesome-free/css/all.min.css';

const FlightInfoOrigin = ({ origin, onFlightOriginChange }) => {
  useEffect(() => {
    const newOrigin = origin;
    onFlightOriginChange(newOrigin);
  }, []);
  return <span>{origin}</span>;
};


const FlightInfoDestination = ({ Destination, onFlightDestinationChange }) => {
  useEffect(() => {
    const newDestination = Destination;
    onFlightDestinationChange(newDestination);
  }, []);
  return <span>{Destination}</span>;
};

const FlightInfoDepartureDate = ({ DepartureDate, onFlightDepartureDateChange }) => {
  useEffect(() => {
    const newDepartureDate = DepartureDate;
    onFlightDepartureDateChange(newDepartureDate);
  }, []);
  return <span>{DepartureDate}</span>;
};


const SearchFlight = () => {
  const [loadingg, setLoadingg] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [formtaxivaxi, setFormtaxivaxi] = useState([]);
  let storedFormData = sessionStorage.getItem('formtaxivaxiData');
  useEffect(() => {
    if (storedFormData) {
      let formtaxivaxiData1 = JSON.parse(storedFormData);
      setFormtaxivaxi(formtaxivaxiData1);

    }
  }, []);


  const [flightorigins, setflightOrigin] = useState('');
  const [flightdestinations, setflightDestination] = useState('');
  const [flightDepartureDates, setflightDepartureDate] = useState('');
  const [show, setShow] = useState(false);
  const [flightOptions, setFlightOptions] = useState([]);
  const [flightairoption, setFlightAirOptions] = useState([]);
  const [flightDetails, setFlightDetails] = useState([]);
  const [flightErrors, setFlighterrors] = useState([]);
  const [Passengerarray, setPassengerkeys] = useState([]);
  const [Passengerxml, setPassengerxml] = useState([]);
  const [SegmentList, setSegment] = useState([]);
  const [HostList, setHostlist] = useState([]);
  const [FareList, setFarelist] = useState([]);
  const [Airlines, setAirlineOptions] = useState([]);
  const [Airports, setAirportOptions] = useState([]);

  const handleFlightOriginChange = (newOrigin) => {
    setflightOrigin(newOrigin);
  };

  const handleFlightDestinationChange = (newDestination) => {
    setflightDestination(newDestination);
  };

  const handleFlightDepartureDateChange = (newDepartureDate) => {
    setflightDepartureDate(newDepartureDate);
  };
  // let dataFound = false;

  const airlines = location.state && location.state.responseData.airlinedata;
  const apiairports = location.state && location.state.responseData.apiairportsdata;
  const airports = location.state && location.state.responseData.airportData;
  const response = location.state && location.state.responseData.responsedata;
  const adult = location.state && location.state.responseData.selectadult;
  const child = location.state && location.state.responseData.selectchild;
  const infant = location.state && location.state.responseData.selectinfant;
  const cabinclass = location.state && location.state.responseData.selectclass;
  const requesttype = location.state && location.state.responseData?.requesttype;
  const spocemail = location.state && location.state.responseData?.spocemail;
  const additionalemail = location.state && location.state.responseData?.additionalemail;
  const ccmail = location.state && location.state.responseData?.ccmail;
  const clientname = location.state && location.state.responseData?.clientname;
  const spocname = location.state && location.state.responseData?.spocname;
  const markupdata = location.state && location.state.responseData?.markupdata;
  const bookingid = location.state && location.state.responseData?.bookingid;
  const is_approved = location.state && location.state.responseData?.isapproved;
  const searchdeparturedate = location.state && location.state.responseData?.searchdeparturedate;
  // alert(searchdeparturedate);
  const searchreturnd = location.state && location.state.responseData?.searchreturnd;
  let no_of_seats = location.state && location.state.responseData?.no_of_seats;
  const request_id = location.state && location.state.responseData?.request_id;
  const clientid = location.state && location.state.responseData?.clientid;
  const is_gst_benefit = location.state && location.state.responseData?.is_gst_benefit;
  const flight_type = location.state && location.state.responseData?.flighttype;
  const access_token = location.state && location.state.responseData?.accessToken;

  const adultCounts = parseInt(adult, 10) || 0;
  const childCounts = parseInt(child, 10) || 0;
  const infantCounts = parseInt(infant, 10) || 0;
  const [adultCount, setAdultCount] = useState(adultCounts);
  const [childCount, setChildCount] = useState(childCounts);
  const [infantCount, setInfantCount] = useState(infantCounts);

  if (no_of_seats === '' || no_of_seats === 'NULL' || no_of_seats === 'undefined' || no_of_seats == null) {
    no_of_seats = adultCount + childCount + infantCount;
  }

  const request = location.state?.responseData || {};
  const [SearchFinalResponse, setSearchFinalResponse] = useState(response);
  const [isOpen, setIsOpen] = useState(false);
  const [isdepOpen, setdepIsOpen] = useState(false);
  const [isretOpen, setretIsOpen] = useState(false);

  const [cabinClass, setCabinClass] = useState(cabinclass);
  const [inputOrigin, setInputOrigin] = useState(request.searchfromcity);
  const [showOriginDropdown, setShowOriginDropdown] = useState(false);

  const [ismodify, setismodify] = useState(true);
  const [origin, setOrigin] = useState([]);
  const [allAirportsOrigin, setAllAirportsOrigin] = useState([]);
  const [airportOriginCodes, setAirportOriginCodes] = useState(null);

  const [inputDestination, setInputDestination] = useState(request.searchtocity);
  const [showDestinationDropdown, setShowDestinationDropdown] = useState(false);
  const [destination, setDestination] = useState([]);
  const [allAirportsDestination, setAllAirportsDestination] = useState([]);
  const [airportDestinationCodes, setAirportDestinationCodes] = useState(null);
  const [lastActionWasSwap, setLastActionWasSwap] = useState(false);
  const [pricesegment, setpricesegment] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [fareInfoRefsState, setFareInfoRefsState] = useState([]);
  const [allSegmentKeys, setAllSegmentKeys] = useState([]);

  const handleSegmentKeyMatch = (segmentKey) => {
    setFareInfoRefsState([]);
    const matchedBookingInfo = flightairoption.filter(
      (bookingInfo) => bookingInfo['SegmentRef'] === segmentKey
    );
    setFareInfoRefsState([...matchedBookingInfo]);
  };

  const handleach = (fareInfoRefKey) => {
    setLoading(true);
    const bookingCode = fareInfoRefKey['BookingCode'];

    const matchedData = flightairoption.filter(
      (fareInfo) =>
        fareInfo.BookingCode === bookingCode &&
        allSegmentKeys.includes(fareInfo.SegmentRef) // Use allSegmentKeys from state for connecting flights
    );
    // console.log("Matched Data:", matchedData);

    const segmentArray = matchedData
    .flatMap((fareInfo) => {
      const segmentkey = fareInfo['SegmentRef'];
      const hostkey = fareInfo['HostTokenRef'];
  
      return SegmentList
        ?.filter((segment) => segment?.['$']?.['Key'] === segmentkey) // Ensure segment exists
        .map((segment) => {
          if (!segment || !segment.$) return null; // Skip undefined segments
  
          // Clone the segment to avoid modifying the original object
          let updatedSegment = { ...segment, $: { ...segment.$ } };
  
          // Update ProviderCode if available
          if (segment['air:AirAvailInfo']?.$?.ProviderCode) {
            updatedSegment.$.ProviderCode = segment['air:AirAvailInfo'].$.ProviderCode;
          }
  
          // Add additional properties
          updatedSegment.$.HostTokenRef = hostkey;
          updatedSegment.$.ClassOfService = fareInfo['BookingCode'];
  
          // Remove unnecessary properties
          delete updatedSegment['air:FlightDetailsRef'];
  
          return updatedSegment;
        });
    })
    .filter(Boolean) // Remove null values
    .reduce((uniqueSegments, segment) => {
      // Check if segment with the same Key already exists in the unique list
      if (!uniqueSegments.some((item) => item.$.Key === segment.$.Key)) {
        uniqueSegments.push(segment);
      }
      return uniqueSegments;
    }, []);
  
  // console.log("segmentArray", segmentArray);
  

    const comHostTokens = matchedData.map((fareInfo) => {
      const hostkey = fareInfo['HostTokenRef'];

      return HostList
        .filter(hostToken => hostToken['$'] && hostToken['$']['Key'] === hostkey) // Match the hostkey
        .map(hostToken => ({
          $: { Key: hostToken['$'].Key }, // Use the Key
          _: hostToken._ // Add the token value
        }));
    }).flat();
  
    const airPricingCommand = matchedData.map((fareInfo) => {
      const farekey = fareInfo['FareInfoRef'];
      const segmentkey = fareInfo['SegmentRef'];

      return FareList
        .filter(fareInfo => fareInfo['$'] && fareInfo['$']['Key'] === farekey) // Match the farekey
        .map(fareInfo => {
          const fareBasisCode = fareInfo['$'].FareBasis;
          if (fareBasisCode) {
            return {
              'air:AirSegmentPricingModifiers': {
                $: {
                  AirSegmentRef: segmentkey, // Use the already available segmentkey
                  FareBasisCode: fareBasisCode,
                }
              }
            };
          }

          return null; // Skip entries where FareBasisCode is missing
        })
        .filter(Boolean); // Remove nulls
    }).flat();

    // console.log("Processed airPricingCommand:", airPricingCommand);
    const builder = require('xml2js').Builder;
    var pricepointXMLpc = new builder().buildObject({
      'soap:Envelope': {
        '$': {
          'xmlns:soap': 'http://schemas.xmlsoap.org/soap/envelope/'
        },
        'soap:Body': {
          'air:AirPriceReq': {
            '$': {
              'AuthorizedBy': 'TAXIVAXI',
              'TargetBranch': 'P7206253',
              'FareRuleType': 'short',
              'TraceId': 'TVSBP001',
              'xmlns:air': 'http://www.travelport.com/schema/air_v52_0',
              'xmlns:com': 'http://www.travelport.com/schema/common_v52_0'
            },
            'BillingPointOfSaleInfo': {
              '$': {
                'OriginApplication': 'UAPI',
                'xmlns': 'http://www.travelport.com/schema/common_v52_0'
              },
            },
            'air:AirItinerary': {
              'air:AirSegment': segmentArray,
              'com:HostToken': comHostTokens,
            },
            'air:AirPricingModifiers': {
              '$': {
                'InventoryRequestType': 'DirectAccess',
                'ETicketability': 'Yes',
                'FaresIndicator': "AllFares"
              },
              'air:PermittedCabins': {
                'com:CabinClass': {
                  '$': {
                    'Type': formData.classType,
                  },
                },
              },
              'air:BrandModifiers': {
                'air:FareFamilyDisplay': {
                  '$': {
                    'ModifierType': 'FareFamily',
                  },
                },
              },
            },
            'com:SearchPassenger': Passengerxml,
            'air:AirPricingCommand': airPricingCommand
          }
        }
      }
    });

    const makeSoapRequest = async () => {
      var pricepointXML = pricepointXMLpc;
      console.log('main_prc', pricepointXML); 
      // console.log("in api1")
      try {
        const priceresponse = await axios.post(
          'https://devapi.taxivaxi.com/reactSelfBookingApi/v1/makeFlightAirServiceRequest',
          pricepointXML);
        
        const priceResponse = priceresponse.data;
        console.log('priceResponse', priceResponse);
        parseString(priceResponse, { explicitArray: false }, (err, priceresult) => {
          if (err) {
            console.error('Error parsing XML:', err);
            return;
          }
          const AirPriceRsp = priceresult['SOAP:Envelope']['SOAP:Body']['air:AirPriceRsp'];

          if (AirPriceRsp !== null && AirPriceRsp !== undefined) {
            const pricereponse = priceresult['SOAP:Envelope']['SOAP:Body']['air:AirPriceRsp']['air:AirPriceResult']['air:AirPricingSolution'];
            const segmentpricereponse = priceresult['SOAP:Envelope']['SOAP:Body']['air:AirPriceRsp']['air:AirItinerary']['air:AirSegment'];
            const Priceinginfoselected = pricereponse;
            const airPricingInfo = pricereponse['air:AirPricingInfo'];
            const combinedArray = [];
            if (Array.isArray(airPricingInfo)) {
              if (Array.isArray(airPricingInfo[0]['air:BookingInfo'])) {
                airPricingInfo[0]['air:BookingInfo'].forEach(bookinginfo => {
                  combinedArray.push({
                    segmentRef: bookinginfo['$']['SegmentRef'],
                    hostTokenRef: bookinginfo['$']['HostTokenRef']
                  });
                });
              } else {
                combinedArray.push({
                  segmentRef: airPricingInfo[0]['air:BookingInfo']['$']['SegmentRef'],
                  hostTokenRef: airPricingInfo[0]['air:BookingInfo']['$']['HostTokenRef']
                });
              }
            } else {
              if (Array.isArray(airPricingInfo['air:BookingInfo'])) {
                airPricingInfo['air:BookingInfo'].forEach(bookinginfo => {
                  combinedArray.push({
                    segmentRef: bookinginfo['$']['SegmentRef'],
                    hostTokenRef: bookinginfo['$']['HostTokenRef']
                  });
                });
              } else {
                combinedArray.push({
                  segmentRef: airPricingInfo['air:BookingInfo']['$']['SegmentRef'],
                  hostTokenRef: airPricingInfo['air:BookingInfo']['$']['HostTokenRef']
                });
              }
            }

            const HostToken = pricereponse['common_v52_0:HostToken'];
            const SegmentParse = segmentpricereponse;

            // Convert SegmentParse to an array if it's a single object
            const segmentArray = Array.isArray(SegmentParse) ? SegmentParse : [SegmentParse];

            let finaldeparturedate = '';
            let finalreturndate = '';
            let finalarrivaldate = '';

            if (formData.bookingType === "Return") {
              segmentArray.forEach((segmentInfo, segmentindex) => {
                if (segmentindex === 0) {
                  finaldeparturedate = segmentInfo['$']['DepartureTime'];
                }
                if (segmentInfo['$']['Group'] === '1') {
                  finalreturndate = segmentInfo['$']['DepartureTime'];
                }
              });
            } else {
              // Handle cases where the index is not 0
              segmentArray.forEach((segmentInfo, segmentindex) => {
                if (segmentindex === 0) {
                  finaldeparturedate = segmentInfo['$']['DepartureTime'];
                  finalarrivaldate = segmentInfo['$']['ArrivalTime'];
                } else {
                  // Handle subsequent segments (not index 0)
                  finaldeparturedate = segmentInfo['$']['DepartureTime'];
                  finalarrivaldate = segmentInfo['$']['ArrivalTime'];
                }
              });

              // If it's a single segment, ensure the dates are still assigned
              if (segmentArray.length === 1 && segmentArray[0]['$']) {
                finaldeparturedate = segmentArray[0]['$']['DepartureTime'];
                finalarrivaldate = segmentArray[0]['$']['ArrivalTime'];
              }
            }
            // Handle 'air:Connection' assignment for segments within the same group
            for (let i = 0; i < segmentArray.length; i++) {
              let currentSegment = segmentArray[i];

              for (let j = i + 1; j < segmentArray.length; j++) {
                const nextSegment = segmentArray[j];
                if (currentSegment.$.Group === nextSegment.$.Group) {
                  currentSegment['air:Connection'] = "";
                  break;
                }
              }
            }

            // Assign 'HostTokenRef' based on segmentRef
            segmentArray.forEach(segment => {
              const segmentKey = segment['$'].Key;
              const matchedEntry = combinedArray.find(entry => entry.segmentRef === segmentKey);
              if (matchedEntry) {
                segment['$'].HostTokenRef = matchedEntry.hostTokenRef;
              }
            });

            const builder = require('xml2js').Builder;
            var servicerequestXML = new builder().buildObject({
              'soap:Envelope': {
                '$': {
                  'xmlns:soap': 'http://schemas.xmlsoap.org/soap/envelope/'
                },
                'soap:Header': {

                },
                'soap:Body': {
                  '$': {
                    'xmlns:air': 'http://www.travelport.com/schema/air_v52_0',
                    'xmlns:com': 'http://www.travelport.com/schema/common_v52_0',
                  },
                  'air:AirMerchandisingOfferAvailabilityReq': {
                    '$': {
                      'TargetBranch': 'P7206253',
                      'TraceId': 'ac191f0b9c0546659065f29389eae552'
                    },
                    'com:BillingPointOfSaleInfo': {
                      '$': {
                        'OriginApplication': 'UAPI'
                      },
                    },
                    'air:AirSolution': {
                      'air:AirSegment': SegmentParse,
                      'com:HostToken': HostToken
                    },

                  }
                }
              }
            });
            // console.log('servicerequestXML', servicerequestXML);
            const serviceresponse = axios.post(
              'https://devapi.taxivaxi.com/reactSelfBookingApi/v1/makeFlightAirServiceRequest', servicerequestXML);
            const serviceResponse = serviceresponse.data;
            // console.log('serviceResponse', serviceresponse);

            const serviceData = {
              apiairportsdata: apiairports,
              servicedata: serviceresponse.data,
              SegmentPricelist: SegmentParse,
              packageselected: Priceinginfoselected,
              hostToken: HostToken,
              classtype: formData.classType,
              Passengerarray: Passengerarray,
              searchdeparture: finaldeparturedate,
              searchreturn: finalreturndate,
              searcharrivaldate: finalarrivaldate,
              origin: formData.flightOrigin,
              destination: formData.flightDestination,
              finalorigin: inputOrigin,
              finaldestination: inputDestination,
              bookingtype: formData.bookingType,
              Airports: Airports,
              Airlines: Airlines,
              finalairlines: airlines,
              finalairports: airports,
              searchfinaldeparture: formData.departureDate,
              searchfinalreturn: formData.returnDate,
              adult: formData.adult,
              child: formData.child,
              infant: formData.infant,
              FinalResponse: SearchFinalResponse,
              formtaxivaxi: formtaxivaxi,
              booking_id: bookingid,
              client_id: clientid,
              is_gst_benefit: is_gst_benefit,
              accesstoken: access_token

            };
            setLoading(false);
            console.log('servicedata1', serviceData);
            navigate('/bookingProcess', { state: { serviceData } });

          } else {
            const error = priceresult['SOAP:Envelope']['SOAP:Body']['SOAP:Fault']['faultstring'];
            // ErrorLogger.logError('price_api',pricepointXML,error);
            Swal.fire({
              title: 'Something Went Wrong !',
              text: 'Please try again later',
              confirmButtonText: 'OK'
            });
          }


        });
      } catch (error) {
        // ErrorLogger.logError('price_api','Error',error);
        navigate('/tryagainlater');
      }
      finally {
        setLoadingg(false);

      }
    };

    makeSoapRequest();
  }

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const calculateFinalPrice = (totalPrice, markup, seatType, fareName, airline, flighttype) => {

    // Parse markup if it's a JSON string
    if (typeof markup === "string") {
      try {
        markup = JSON.parse(markup);
      } catch (error) {
        return parseFloat(totalPrice.replace("INR", "").trim()); // Return the original price
      }
    }

    // Ensure totalPrice is numeric for calculations
    const numericPrice = parseFloat(totalPrice.replace("INR", "").trim());

    if (!Array.isArray(markup)) {
      return numericPrice; // Return the original price
    }

    // Find the applicable markup based on fareName

    let applicableMarkup = markup.find((m) => {
      const seatTypeMatch = m.seat_type === seatType || m.seat_type === '';
      const fareNameMatch = !fareName || m.fare_name === fareName || m.fare_name === '' || m.fare_name == null;

      const flightTypeMatch = !flighttype || m.flight_type === flighttype || m.flight_type === '';

      const airlineMatch = !airline || m.airline_full_name === airline || m.airline_full_name === '' || m.airline_full_name == null;

      return seatTypeMatch && fareNameMatch && flightTypeMatch && airlineMatch;
    });

    // console.log("applicableMarkup",applicableMarkup)
    // If no specific fareName markup found, fallback to Base Fare
    if (!applicableMarkup) {
      applicableMarkup = markup.find(
        (m) => m.seat_type === seatType && m.fare_name === "Base Fare"
      );
    }

    if (!applicableMarkup) {
      console.warn("No applicable markup found; applying original price.");
      return numericPrice;
    }
    // console.log("Without markup", numericPrice)
    // console.log("markup type",applicableMarkup.markup_type)
    // console.log("markup value",applicableMarkup.markup_value)

    // Calculate the final price based on markup type
    const markupValue = parseFloat(applicableMarkup.markup_value);
    if (applicableMarkup.markup_type === "Fixed") {
      // console.log('with markup', numericPrice + markupValue);
      return numericPrice + markupValue; // Add fixed value
    } else if (applicableMarkup.markup_type === "Percentage") {
      // console.log('with markup', numericPrice + (numericPrice * markupValue) / 100);
      return numericPrice + (numericPrice * markupValue) / 100; // Add percentage
    }

  };

  const swapOriginAndDestination = () => {
    if (lastActionWasSwap) {
      setInputOrigin(inputDestination);
      setInputDestination(inputOrigin);
    } else {
      const temp = inputOrigin;
      setInputOrigin(inputDestination);
      setInputDestination(temp);
    }
    setismodify(false);
    setLastActionWasSwap(!lastActionWasSwap);
  };

  useEffect(() => {
    if (response) {
      parseString(response, { explicitArray: false }, (err, result) => {
        if (err) {
          console.error('Error parsing XML:', err);
          return;
        }
        const lowFareSearchRsp = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp'];
        if (lowFareSearchRsp !== null && lowFareSearchRsp !== undefined) {
          const pricepointlist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:AirPricePointList']['air:AirPricePoint'];

          const extractedBookingInfo = [];
          const pricepointlistArray = Array.isArray(pricepointlist) ? pricepointlist : [pricepointlist];
          // Iterate through the AirPricePoint list
          pricepointlistArray.forEach((airPricePoint) => {
            const airPricingInfo = airPricePoint['air:AirPricingInfo'];
            if (!airPricingInfo) return; // Skip if no AirPricingInfo is found

            const flightOptionsList = airPricingInfo['air:FlightOptionsList'];
            if (!flightOptionsList) return; // Skip if no FlightOptionsList is found

            const flightOptions = flightOptionsList['air:FlightOption'];
            const flightOptionArray = Array.isArray(flightOptions) ? flightOptions : [flightOptions]; // Normalize to array

            // Iterate through each air:FlightOption
            flightOptionArray.forEach((flightOption) => {
              const options = flightOption['air:Option'];
              const optionsArray = Array.isArray(options) ? options : [options]; // Normalize to array

              // Iterate through each air:Option
              optionsArray.forEach((airOption) => {
                const bookingInfo = airOption['air:BookingInfo'];
                const bookingInfoArray = Array.isArray(bookingInfo) ? bookingInfo : [bookingInfo]; // Normalize to array

                // Extract the "$" part from each bookingInfo
                bookingInfoArray.forEach((info) => {
                  if (info && info["$"]) {
                    extractedBookingInfo.push(info["$"]); // Push the extracted info into the result array
                  }
                });
              });
            });
          });

          // console.log("Extracted Booking Info:", extractedBookingInfo);

          const Segmentlist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:AirSegmentList']['air:AirSegment'];
          const flightdetailist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:FlightDetailsList']['air:FlightDetails'];
          const hosttokenlist = result?.['SOAP:Envelope']?.['SOAP:Body']?.['air:LowFareSearchRsp']?.['air:HostTokenList']?.['common_v52_0:HostToken'] || [];
          const fareinfolist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:FareInfoList']['air:FareInfo'];
          setFlightOptions(Array.isArray(pricepointlist) ? pricepointlist : [pricepointlist]);
          setFlightAirOptions(Array.isArray(extractedBookingInfo) ? extractedBookingInfo : [extractedBookingInfo]);

          setFlightDetails(Array.isArray(flightdetailist) ? flightdetailist : [flightdetailist]);
          setSegment(Array.isArray(Segmentlist) ? Segmentlist : [Segmentlist]);
          setHostlist(Array.isArray(hosttokenlist) ? hosttokenlist : [hosttokenlist]);
          setFarelist(Array.isArray(fareinfolist) ? fareinfolist : [fareinfolist]);

        } else {
          const error = result['SOAP:Envelope']['SOAP:Body']['SOAP:Fault']['faultstring'];

          setFlighterrors(error);

        }


      });
    }
  }, []);

  const [priceParse, setpriceparse] = useState(null);
  // console.log('priceparse', priceParse);
  const [minvalue, setMinvalue] = useState(0);
  const [maxvalue, setMaxvalue] = useState(10000000);

  useEffect(() => {
    const totalPriceValues = flightOptions.map((item) => {
      const totalPrice = parseFloat(item.$.TotalPrice.replace(/[^\d.]/g, ''));
      return !isNaN(totalPrice) ? totalPrice : 0;
    });
    const validTotalPriceValues = totalPriceValues.filter(value => !isNaN(value));
    const minprice = Math.min(...validTotalPriceValues);
    const maxprice = Math.max(...validTotalPriceValues);
    setMinvalue(minprice);
    setMaxvalue(maxprice);
  }, [flightOptions]);

  const [priceRange, setPriceRange] = useState([minvalue, maxvalue]);

  const handlePriceChange = (value) => {
    setPriceRange(value);
  };

  const handleweekdatemonthyear = (date) => {
    const arrivalTime = new Date(date);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const weekday = weekdays[arrivalTime.getDay()];
    const day = arrivalTime.getDate();
    const month = months[arrivalTime.getMonth()];
    const year = arrivalTime.getFullYear();

    const formattedDateString = `${weekday}, ${day} ${month} ${year}`;
    return formattedDateString;
  }

  const handleflyghtHeadInfoDate = (date) => {
    const arrivalTime = new Date(date);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const weekday = weekdays[arrivalTime.getDay()];
    const day = arrivalTime.getDate();
    const month = months[arrivalTime.getMonth()];
    const formattedDateString = `${weekday}, ${day} ${month}`;

    return formattedDateString;
  }
  const handledatemonth = (date) => {
    const arrivalTime = new Date(date);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const weekday = weekdays[arrivalTime.getDay()];
    const day = arrivalTime.getDate();
    const month = months[arrivalTime.getMonth()];
    const formattedDateString = `${day} ${month}`;

    return formattedDateString;
  }
  const handledatemonthsplit = (date) => {
    const dateParts = date;
    const day = parseInt(dateParts[0]);
    const monthIndex = parseInt(dateParts[1]) - 1;
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = months[monthIndex];
    const data = `${day} ${month}`;
    return data;
  }

  const [selectedAirlines, setSelectedAirlines] = useState([]);
  const [selectedreturnAirlines, setreturnSelectedAirlines] = useState([]);
  const [selectedStops, setSelectedStops] = useState([]);
  const [selectedreturnStops, setreturnSelectedStops] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState([]);
  const [selectedTimeRanges, setSelectedTimeRanges] = useState([]);


  const handleTimeRangeClick = (timeRange) => {
    setSelectedTimeRange((prevSelectedRanges) =>
      prevSelectedRanges.includes(timeRange)
        ? prevSelectedRanges.filter((range) => range !== timeRange) // Remove if already selected
        : [...prevSelectedRanges, timeRange] // Add if not selected
    );
  };

  const handleTimeRangeClicks = (timeRange) => {
    setSelectedTimeRanges((prevSelectedRanges) =>
      prevSelectedRanges.includes(timeRange)
        ? prevSelectedRanges.filter((range) => range !== timeRange) // Remove if already selected
        : [...prevSelectedRanges, timeRange] // Add if not selected
    );
  };

  const handleAirlineCheckboxChange = (airline) => {
    if (selectedAirlines.includes(airline)) {
      setSelectedAirlines(selectedAirlines.filter((selected) => selected !== airline));
    } else {
      setSelectedAirlines([...selectedAirlines, airline]);
    }
  };

  const handlereturnAirlineCheckboxChange = (airline) => {
    if (selectedreturnAirlines.includes(airline)) {
      setreturnSelectedAirlines(selectedreturnAirlines.filter((selected) => selected !== airline));
    } else {
      setreturnSelectedAirlines([...selectedreturnAirlines, airline]);
    }
  };

  // Function to handle checkbox change for stops
  const handleStopCheckboxChange = (stops) => {
    // alert(stops);
    if (selectedStops.includes(stops)) {
      setSelectedStops(selectedStops.filter((selected) => selected !== stops));
    } else {
      setSelectedStops([...selectedStops, stops]);
    }
  };

  const handlereturnStopCheckboxChange = (stops) => {
    if (selectedreturnStops.includes(stops)) {
      setreturnSelectedStops(selectedreturnStops.filter((selected) => selected !== stops));
    } else {
      setreturnSelectedStops([...selectedreturnStops, stops]);
    }
  };


  const [sortingCriterion, setSortingCriterion] = useState('1'); // Track the sorting criterion
  const [sortDirection, setSortDirection] = useState('asc'); // Track the sorting direction ('asc' or 'desc')

  const handleSortingCriterionClick = (criteria) => {
    if (sortingCriterion === criteria) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortingCriterion(criteria);
      setSortDirection('asc');
    }
  };

  const calculateTravelTime = (traveltimes) => {
    const days = traveltimes.match(/(\d+)DT/) ? parseInt(traveltimes.match(/(\d+)DT/)[1]) : 0;
    const hours = traveltimes.match(/(\d+)H/) ? parseInt(traveltimes.match(/(\d+)H/)[1]) : 0;
    const minutes = traveltimes.match(/(\d+)M/) ? parseInt(traveltimes.match(/(\d+)M/)[1]) : 0;

    return days * 24 * 60 + hours * 60 + minutes;
  }

  function convertMinutesToHours(minutes) {
    const hours = Math.floor(minutes / 60); // Get the whole hours
    const remainingMinutes = minutes % 60; // Get the remaining minutes
    return `${hours} hrs ${remainingMinutes} mins`;
  }

  const calculateTravellingTime = (traveltimes) => {
    const days = traveltimes.match(/(\d+)DT/) ? parseInt(traveltimes.match(/(\d+)DT/)[1]) : 0;
    const hours = traveltimes.match(/(\d+)H/) ? parseInt(traveltimes.match(/(\d+)H/)[1]) : 0;
    const minutes = traveltimes.match(/(\d+)M/) ? parseInt(traveltimes.match(/(\d+)M/)[1]) : 0;
    const finaltime = `${days}D ${hours}H ${minutes}M`;
    return finaltime;
  }

  const formatISODuration = (duration) => {
    if (!duration) return "00:00:00";

    // Match the components in the ISO 8601 duration
    const daysMatch = duration.match(/P(\d+)D/);
    const hoursMatch = duration.match(/T(\d+)H/);
    const minutesMatch = duration.match(/T\d+H(\d+)M/);
    const secondsMatch = duration.match(/T\d+H\d+M(\d+)S/);

    const days = daysMatch ? parseInt(daysMatch[1], 10) : 0;
    const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0;
    const minutes = minutesMatch ? parseInt(minutesMatch[1], 10) : 0;
    const seconds = secondsMatch ? parseInt(secondsMatch[1], 10) : 0;

    // Convert days to hours and add to total hours
    const totalHours = days * 24 + hours;

    // Format as HH:MM:SS
    return `${String(totalHours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  const calculateDepartureTime = (depareturetimes) => {
    const date = new Date(depareturetimes);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }

  const handleClasstype = (value) => {
    setismodify(false);
    setCabinClass(value);
    setFormData({ ...formData, classType: value });
  };

  const handleAdult = (value) => {
    setismodify(false);
    setAdultCount(value);
    setFormData({ ...formData, adult: value });
  };

  const handleChild = (value) => {
    setismodify(false);
    setChildCount(value);
    setFormData({ ...formData, child: value });
  };

  const handleInfant = (value) => {
    setismodify(false);
    setInfantCount(value);
    setFormData({ ...formData, infant: value });
    const infanterror = document.querySelector('.infantmore');
    if (value > adultCount) {
      infanterror.style.display = 'block';
    } else {
      infanterror.style.display = 'none';
    }
  };

  const handleToggle = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  const [activeTab, setActiveTab] = useState([]);

  useEffect(() => {
    const updatedTabs = flightOptions.map((pricepoint, priceindex) => `Flight_Detail${priceindex}`);
    setActiveTab(updatedTabs);
  }, [flightOptions]);

  useEffect(() => {
    if (airlines) {
      parseString(airlines, { explicitArray: false }, (errs, airlineresult) => {
        if (errs) {
          console.error('Error parsing XML:', errs);
          return;
        }
        const airlinelist = airlineresult['SOAP:Envelope']['SOAP:Body']['util:ReferenceDataRetrieveRsp']['util:ReferenceDataItem'];
        setAirlineOptions(airlinelist);
      });
    }
  }, []);

  useEffect(() => {
    if (airports) {
      parseString(airports, { explicitArray: false }, (errs, airportresult) => {
        if (errs) {
          console.error('Error parsing XML:', errs);
          return;
        }
        const airportlist = airportresult['SOAP:Envelope']['SOAP:Body']['util:ReferenceDataRetrieveRsp']['util:ReferenceDataItem'];
        setAirportOptions(airportlist);
        const tempAirportCodes = {};
        airportlist.forEach((airport) => {
          tempAirportCodes[airport.$.Code] = airport.$.Name;
        });
        setAirportOriginCodes(tempAirportCodes);
        setAllAirportsOrigin(airportlist);

        setAirportDestinationCodes(tempAirportCodes);
        setAllAirportsDestination(airportlist);
      });
    }
  }, []);

  const handleAirline = (carrier) => {
    const airline = Airlines.find((airlineInfo) => {
      return airlineInfo['$'] && airlineInfo['$']['Code'] === carrier;
    });
    if (airline) {
      return airline['$']['Description'];
    } else {
      return "Airline";
    }
  }

  const handleApiAirport = (airportcode) => {
    if (apiairports) {
      const airportapi = apiairports.find((apiairportsInfo) => {
        return apiairportsInfo && apiairportsInfo['airport_iata_code'] === airportcode;
      });
      if (airportapi) {
        return (airportapi['airport_name']);
      } else {
        return "";
      }
    }

  }

  const handleAirport = (airportcode) => {
    const airport = Airports.find((airportInfo) => {
      return airportInfo['$'] && airportInfo['$']['Code'] === airportcode;
    });
    if (airport) {
      return airport['$']['Name'];
    } else {
      return "Airport";
    }
  }

  const [visibleFlightIndex, setVisibleFlightIndex] = useState(null);

  // const handleFlightDetails = (index) => {
  //   setVisibleFlightIndex(index === visibleFlightIndex ? null : index);
  // };
  const handleFlightDetails = (index) => {
    if (index === visibleFlightIndex) {
      setVisibleFlightIndex(null);
    } else {
      setVisibleFlightIndex(index);
      setActiveTab(`Flight_Detail${index}`); // Reset the active tab to the first tab for the selected flight
    }
  };

  const [segmentpriceParse, setsegmentpriceparse] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // useEffect(() => {
  const handlePriceSubmit = (event, priceindex) => {
    setLoading(true);
    setFareInfoRefsState([]);
    setpriceparse([]);
    setPassengerxml([]);

    event.preventDefault();
    // alert(isDropdownVisible)
    // alert(selectedPriceIndex)
    // alert(ismodify)
    if (isDropdownVisible && selectedPriceIndex === priceindex) {
      // If the dropdown for this priceIndex is already open, close it
      setIsDropdownVisible(false);
      setSelectedPriceIndex(null);
      setLoadingIndex(null);
    } else {

      setLoadingIndex(priceindex); // Set the loading index
      // Simulate an async operation
      setTimeout(() => {
        setLoadingIndex(null); // Clear the loading state after the operation
      }, 2000);

      if (ismodify) {
        setLoading(true);
        setIsDropdownVisible(true);
        setSelectedPriceIndex(priceindex);
        const searchData = new FormData(event.target);
        var SearchPriceTotalPrice = event.target.SearchPriceTotalPrice.value;
        sessionStorage.setItem('SearchPriceTotalPrice', SearchPriceTotalPrice);

        console.log('SearchPriceTotalPrice', SearchPriceTotalPrice);

        // console.log('segarr', segmentArrayJSON);
        const segmentArray = [];
        const bookingInfoArray = [];
        const totalpassengers = adultCount + childCount + infantCount;

        const generateRandomKey = () => {
          const randomBytes = CryptoJS.lib.WordArray.random(16);
          const base64Key = CryptoJS.enc.Base64.stringify(randomBytes);
          return base64Key;
        };

        const generatePassengerKeys = (adultCount, childCount, infantCount) => {
          const keys = [];

          for (let i = 0; i < adultCount; i++) {
            keys.push({
              Key: generateRandomKey(),
              Code: 'ADT'
            });
          }

          for (let i = 0; i < childCount; i++) {
            keys.push({
              Key: generateRandomKey(),
              Code: 'CNN',
              Age: "10"
            });
          }

          for (let i = 0; i < infantCount; i++) {
            keys.push({
              Key: generateRandomKey(),
              Code: 'INF',
              Age: "01"
            });
          }

          return keys;
        };

        const passengerKeys = generatePassengerKeys(adultCount, childCount, infantCount);
        setPassengerkeys(passengerKeys);
        const passengerKeysXml = passengerKeys.map(passenger => ({
          '$': {
            'Key': passenger.Key,
            'Code': passenger.Code,
            'Age': passenger.Age

          }
        }));

        setPassengerxml(passengerKeysXml);
        var segmentArrayJSON = Array.from(searchData.getAll('Segmentarray[]'));
        var BookingInfoJSON = Array.from(searchData.getAll('BookingInfoData[]'));
        const passengerKeysXmlString = JSON.stringify(passengerKeysXml);
        sessionStorage.setItem('passengerKeysXml', passengerKeysXmlString);
        sessionStorage.setItem('classType', formData.classType);

        segmentArrayJSON.forEach(jsonString => {
          segmentArray.push(JSON.parse(jsonString));
        });

        BookingInfoJSON.forEach(jsonString => {
          bookingInfoArray.push(JSON.parse(jsonString));
        });

        const segmentRefs = bookingInfoArray.map(bookingInfo => {
          if (bookingInfo['$'] && bookingInfo['$']['SegmentRef']) {
            return bookingInfo['$']['SegmentRef'];
          }
          return null; // In case SegmentRef is not available
        }).filter(Boolean);

        const providerCode = segmentArray.find(segment =>
          segment['$'].ProviderCode || (segment['air:AirAvailInfo'] && segment['air:AirAvailInfo']['$'] && segment['air:AirAvailInfo']['$'].ProviderCode)
        );

        const providerCodeValue = providerCode ?
          (providerCode['$'].ProviderCode || providerCode['air:AirAvailInfo']['$'].ProviderCode)
          : null;

        if (providerCodeValue === '1G') {
          console.log('hi');
          segmentArray.forEach(segment => {
            if (segment['$']) {
              segment['$'].ProviderCode = '1G';
            }
            delete segment['air:FlightDetailsRef'];
          });
          // console.log('updatedsegmentar', segmentArray);

          for (let i = 0; i < segmentArray.length; i++) {
            let currentSegment = segmentArray[i];
            for (let j = i + 1; j < segmentArray.length; j++) {
              const nextSegment = segmentArray[j];
              if (currentSegment.$.Group === nextSegment.$.Group) {
                currentSegment['air:Connection'] = "";
                currentSegment = segmentArray[j];
                break;
              }
            }
          }

          setpricesegment(segmentArray);
          sessionStorage.setItem('segmentarray', JSON.stringify(segmentArray));
          const builder = require('xml2js').Builder;
          var pricepointXMLpc = new builder().buildObject({
            'soap:Envelope': {
              '$': {
                'xmlns:soap': 'http://schemas.xmlsoap.org/soap/envelope/'
              },
              'soap:Body': {
                'air:AirPriceReq': {
                  '$': {
                    'AuthorizedBy': 'TAXIVAXI',
                    'TargetBranch': 'P7206253',
                    'FareRuleType': 'short',
                    'TraceId': 'TVSBP001',
                    'xmlns:air': 'http://www.travelport.com/schema/air_v52_0',
                    'xmlns:com': 'http://www.travelport.com/schema/common_v52_0'
                  },
                  'BillingPointOfSaleInfo': {
                    '$': {
                      'OriginApplication': 'UAPI',
                      'xmlns': 'http://www.travelport.com/schema/common_v52_0'
                    },
                  },
                  'air:AirItinerary': {
                    'air:AirSegment': segmentArray
                  },
                  'air:AirPricingModifiers': {
                    '$': {
                      'InventoryRequestType': 'DirectAccess',
                      'ETicketability': 'Yes',
                      'FaresIndicator': "AllFares"
                    },
                    'air:PermittedCabins': {
                      'com:CabinClass': {
                        '$': {
                          'Type': formData.classType,
                        },
                      },
                    },
                    'air:BrandModifiers': {
                      'air:FareFamilyDisplay': {
                        '$': {
                          'ModifierType': 'FareFamily',
                        },
                      },
                    },
                  },
                  'com:SearchPassenger': passengerKeysXml,
                  'air:AirPricingCommand': ''
                }
              }
            }
          });
          console.log('prc_1g',pricepointXMLpc); 
        }

        else if (providerCodeValue === 'ACH') {
          const segmentKeys = segmentArray.map((segment) => segment["$"]["Key"]); // Get all segment keys
          setAllSegmentKeys(segmentKeys);
          const key = segmentArray[0]["$"]["Key"];
          handleSegmentKeyMatch(key);
          setLoading(false);
          return;

          // console.log('segmentArray',segmentArray);  

        }

        const makeSoapRequest = async () => {
          var pricepointXML = pricepointXMLpc;
          console.log("in api2")
          // console.log('main_prc', pricepointXML);
          try {
            const priceresponse = await axios.post(
              'https://devapi.taxivaxi.com/reactSelfBookingApi/v1/makeFlightAirServiceRequest',
              pricepointXML, { headers: { 'Content-Type': 'text/xml' } }
            );
            const priceResponse = priceresponse.data;
            // console.log('priceResponse', priceResponse);

            parseString(priceResponse, { explicitArray: false }, (err, priceresult) => {
              if (err) {
                console.error('Error parsing XML:', err);
                return;
              }
              const AirPriceRsp = priceresult['SOAP:Envelope']['SOAP:Body']['air:AirPriceRsp'];

              if (AirPriceRsp !== null && AirPriceRsp !== undefined) {
                const pricereponse = priceresult['SOAP:Envelope']['SOAP:Body']['air:AirPriceRsp']['air:AirPriceResult']['air:AirPricingSolution'];
                const segmentpricereponse = priceresult['SOAP:Envelope']['SOAP:Body']['air:AirPriceRsp']['air:AirItinerary']['air:AirSegment'];
                setpriceparse(Array.isArray(pricereponse) ? pricereponse : [pricereponse]);
                setsegmentpriceparse(Array.isArray(segmentpricereponse) ? segmentpricereponse : [segmentpricereponse]);
                // setShow(true);
              } else {
                Swal.fire({
                  title: 'Something Went Wrong !',
                  text: 'Please try again later',
                  confirmButtonText: 'OK'
                });
              }
            });
          } catch (error) {
            navigate('/tryagainlater');
          }
          finally {
            setLoading(false);
          }
        };
        makeSoapRequest();
      } else {
        Swal.fire({
          title: 'Something has been changed !',
          text: 'Please click on Search Button',
          confirmButtonText: 'OK'
        });
      }
    }

  }
  // }, []); 

  const navigate = useNavigate();

  const [selectedPriceParseIndices, setSelectedPriceParseIndices] = useState([]);
  const togglePriceIndex = (priceParseindex) => {
    setSelectedPriceParseIndices((prevIndices) => {
      if (prevIndices.includes(priceParseindex)) {
        // Remove the index if already selected
        return prevIndices.filter((index) => index !== priceParseindex);
      } else {
        // Add the index if not selected
        return [...prevIndices, priceParseindex];
      }
    });
  };

  const [selectedFlightss, setSelectedFlightss] = useState([]); // Use state for the selected flights
  // console.log('selected', selectedFlightss);

  const handleSegmentRefClick = (segmentRef, price, name) => {
    console.log(segmentRef, price, name);
    const matchingSegment = SegmentList.find(
      (segment) => segment["$"]["Key"] === segmentRef
    );

    if (matchingSegment) {
      // Check if the flight already exists in selectedFlightss
      const existingFlight = selectedFlightss.find(
        (flight) => flight.segment["$"]["Key"] === segmentRef
      );

      if (existingFlight) {
        // If the flight exists, check if the fare exists
        const fareIndex = existingFlight.fares.findIndex(
          (fare) => fare.name === name && fare.price === price
        );

        if (fareIndex !== -1) {
          // If the fare exists, remove it
          existingFlight.fares.splice(fareIndex, 1);
          if (existingFlight.fares.length === 0) {
            // If no fares are left, remove the flight
            setSelectedFlightss(selectedFlightss.filter(flight => flight !== existingFlight));
          } else {
            // Otherwise, just update the fares list
            setSelectedFlightss([...selectedFlightss]);
          }
        } else {
          // If the fare does not exist, add it
          existingFlight.fares.push({ name, price });
          setSelectedFlightss([...selectedFlightss]); // Trigger re-render
        }
      } else {
        // If the flight doesn't exist, add it with the fare
        setSelectedFlightss([
          ...selectedFlightss,
          { segment: matchingSegment, fares: [{ name, price }] },
        ]);
      }
    } else {
      console.log('Segment not found');
    }
  };

  const handleselectedContinue = (selectedprice) => {
    setLoading(true);

    const Priceinginfoselected = priceParse[selectedprice];
    sessionStorage.setItem('packageselectedPrice', Priceinginfoselected['$']['TotalPrice']);
    sessionStorage.setItem('formdata_bookingtype', formData.bookingType);
    const airPricingInfo = priceParse[selectedprice]['air:AirPricingInfo'];

    const combinedArray = [];
    if (Array.isArray(airPricingInfo)) {
      if (Array.isArray(airPricingInfo[0]['air:BookingInfo'])) {
        airPricingInfo[0]['air:BookingInfo'].forEach(bookinginfo => {
          combinedArray.push({
            segmentRef: bookinginfo['$']['SegmentRef'],
            hostTokenRef: bookinginfo['$']['HostTokenRef']
          });
        });
      } else {
        combinedArray.push({
          segmentRef: airPricingInfo[0]['air:BookingInfo']['$']['SegmentRef'],
          hostTokenRef: airPricingInfo[0]['air:BookingInfo']['$']['HostTokenRef']
        });
      }
    } else {
      if (Array.isArray(airPricingInfo['air:BookingInfo'])) {
        airPricingInfo['air:BookingInfo'].forEach(bookinginfo => {
          combinedArray.push({
            segmentRef: bookinginfo['$']['SegmentRef'],
            hostTokenRef: bookinginfo['$']['HostTokenRef']
          });
        });
      } else {
        combinedArray.push({
          segmentRef: airPricingInfo['air:BookingInfo']['$']['SegmentRef'],
          hostTokenRef: airPricingInfo['air:BookingInfo']['$']['HostTokenRef']
        });
      }
    }

    const HostToken = priceParse[selectedprice]['common_v52_0:HostToken'];

    const SegmentParse = segmentpriceParse;
    // console.log('HostToken',HostToken);
    // console.log('SegmentParse',SegmentParse);
    let finaldeparturedate = '';
    let finalreturndate = '';
    let finalarrivaldate = '';

    if (formData.bookingType === "Return") {
      SegmentParse.map((segmentInfo, segmentindex) => {

        if (segmentindex === 0) {
          finaldeparturedate = segmentInfo['$']['DepartureTime'];
        }
        if (segmentInfo['$']['Group'] === '1') {
          finalreturndate = segmentInfo['$']['DepartureTime'];
          return true;
        }
        return false;
      });
    } else {
      SegmentParse.map((segmentInfo, segmentindex) => {

        if (segmentindex === 0) {
          finaldeparturedate = segmentInfo['$']['DepartureTime'];
          finalarrivaldate = segmentInfo['$']['ArrivalTime'];
        }

      });
    }

    for (let i = 0; i < SegmentParse.length; i++) {
      let currentSegment = SegmentParse[i];
      for (let j = i + 1; j < SegmentParse.length; j++) {
        const nextSegment = SegmentParse[j];
        if (currentSegment.$.Group === nextSegment.$.Group) {
          currentSegment['air:Connection'] = "";
          currentSegment = SegmentParse[j];
          break;
        }
      }
    }
    SegmentParse.forEach(segment => {
      const segmentKey = segment['$'].Key;
      const matchedEntry = combinedArray.find(entry => entry.segmentRef === segmentKey);
      if (matchedEntry) {
        segment['$'].HostTokenRef = matchedEntry.hostTokenRef;
      }
    });

    const makeServicesRequest = async () => {
      const builder = require('xml2js').Builder;
      var servicerequestXML = new builder().buildObject({
        'soap:Envelope': {
          '$': {
            'xmlns:soap': 'http://schemas.xmlsoap.org/soap/envelope/'
          },
          'soap:Header': {

          },
          'soap:Body': {
            '$': {
              'xmlns:air': 'http://www.travelport.com/schema/air_v52_0',
              'xmlns:com': 'http://www.travelport.com/schema/common_v52_0',
            },
            'air:AirMerchandisingOfferAvailabilityReq': {
              '$': {
                'TargetBranch': 'P7206253',
                'TraceId': 'ac191f0b9c0546659065f29389eae552'
              },
              'com:BillingPointOfSaleInfo': {
                '$': {
                  'OriginApplication': 'UAPI'
                },
              },
              'air:AirSolution': {
                'air:AirSegment': SegmentParse,
                'com:HostToken': HostToken
              },

            }
          }
        }
      });

      try {
        const serviceresponse = await axios.post(
          'https://devapi.taxivaxi.com/reactSelfBookingApi/v1/makeFlightAirServiceRequest', servicerequestXML);
        // const serviceResponse = serviceresponse.data;
        console.log('serviceresponse',serviceresponse.data);
        const serviceData = {
          apiairportsdata: apiairports,
          servicedata: serviceresponse.data,
          SegmentPricelist: SegmentParse,
          packageselected: Priceinginfoselected,
          hostToken: HostToken,
          classtype: formData.classType,
          Passengerarray: Passengerarray,
          searchdeparture: finaldeparturedate,
          searchreturn: finalreturndate,
          searcharrivaldate: finalarrivaldate,
          origin: formData.flightOrigin,
          destination: formData.flightDestination,
          finalorigin: inputOrigin,
          finaldestination: inputDestination,
          bookingtype: formData.bookingType,
          Airports: Airports,
          Airlines: Airlines,
          finalairlines: airlines,
          finalairports: airports,
          searchfinaldeparture: formData.departureDate,
          searchfinalreturn: formData.returnDate,
          adult: formData.adult,
          child: formData.child,
          infant: formData.infant,
          FinalResponse: SearchFinalResponse,
          formtaxivaxi: formtaxivaxi,
          booking_id: bookingid,
          client_id: clientid,
          is_gst_benefit: is_gst_benefit,
          accesstoken: access_token

        };
        setLoading(false);
        console.log('servicedata', serviceData);
        navigate('/bookingProcess', { state: { serviceData } });

      } catch (error) {
        console.error(error);
        // ErrorLogger.logError('service_api',servicerequestXML,error);
        navigate('/tryagainlater');
      }
      finally {
        setLoading(false);
      }
    };

    makeServicesRequest();
  };

  function formattedDate(date) {
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    };
    return date.toLocaleString('en-US', options);
  }

  function isDateFormat(str) {
    const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
    return dateRegex.test(str);
  }

  function ddmmyyyyformatDate(date) {
    // alert(date);
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // function ddmmyyyyformatDate(date) {
  //   // Ensure the input is a string
  //   alert(date);
  //   date = String(date);

  //   let day, month, year;

  //   if (date.includes('-')) {
  //     // Input format: YYYY-MM-DD
  //     [year, month, day] = date.split('-');
  //   } else if (date.includes('/')) {
  //     // Input format: DD/MM/YYYY
  //     [day, month, year] = date.split('/');
  //   } else {
  //     throw new Error('Unsupported date format. Use "YYYY-MM-DD" or "DD/MM/YYYY".');
  //   }

  //   return `${day}/${month}/${year}`;
  // }

  let depFormattedDate = null;
  let retFormattedDate = null;
  if (isDateFormat(request.searchdeparture)) {
    const [depday, depmonth, depyear] = request.searchdeparture.split('/');
    depFormattedDate = new Date(Date.UTC(parseInt(depyear, 10), parseInt(depmonth, 10) - 1, parseInt(depday, 10)));

    if (request.searchreturnDate) {
      const [retday, retmonth, retyear] = request.searchreturnDate.split('/');
      retFormattedDate = new Date(Date.UTC(parseInt(retyear, 10), parseInt(retmonth, 10) - 1, parseInt(retday, 10)));
    }
  } else {
    depFormattedDate = request.searchdeparture;
    if (request.searchreturnDate) {
      retFormattedDate = request.searchreturnDate;
    }
  }

  const [formData, setFormData] = useState({
    departureDate: depFormattedDate,
    returnDate: retFormattedDate,
    flightOrigin: inputOrigin,
    flightDestination: inputDestination,
    bookingType: request.bookingtype,
    adult: adultCount,
    child: childCount,
    infant: infantCount,
    classType: cabinclass,
  });

  useEffect(() => {
    Cookies.set('cookiesData', JSON.stringify(formData), { expires: 7 });
  }, []);

  const [isReturnEnabled, setReturnEnabled] = useState(true);

  const handleReturnDateInitialization = (bookingType) => {
    if (bookingType === 'oneway') {
      setReturnEnabled(false);
      setFormData({ ...formData, bookingType, returnDate: null });
    } else {
      setReturnEnabled(true);
      const nextDay = new Date();
      if (formData.departureDate) {
        setFormData({ ...formData, bookingType, returnDate: formData.departureDate });
      } else {
        nextDay.setDate(nextDay.getDate() + 1);
        setFormData({ ...formData, bookingType, returnDate: nextDay });
      }
    }
  };

  const getLabelStyle = (labelValue) => {
    if (labelValue === formData.bookingType) {
      return { color: '#fff', backgroundColor: '#785eff' };
    } else {
      return {};
    }
  };

  const handleRadioChange = (e) => {
    setismodify(false);
    const value = e.target.value;
    setFormData({ ...formData, bookingType: value });
    handleReturnDateInitialization(value);
  };

  const handleDepartureDateChange = (date) => {
    setismodify(false);

    // Set a small delay to close the date picker after selecting the date
    setTimeout(() => {
      setdepIsOpen(false); // Close the departure date picker
    }, 0); // 200ms delay for smooth behavior

    if (formData.returnDate) {
      setFormData({ ...formData, departureDate: date, returnDate: date });
    } else {
      setFormData({ ...formData, departureDate: date });
    }
  };

  const handleReturnDateChange = (date) => {
    setismodify(false);

    // Set a small delay to close the return date picker after selecting the date
    setTimeout(() => {
      setretIsOpen(false); // Close the return date picker
    }, 0); // 200ms delay for smooth behavior

    setFormData({
      ...formData,
      returnDate: date,
      bookingType: 'Return',
    });
  };

  const handleOriginChange = (inputValue) => {
    setismodify(false);
    setInputOrigin(inputValue);
    setFormData({ ...formData, flightOrigin: inputValue });
    const filteredOptions = allAirportsOrigin
      .filter((airport) =>
        airport.$.Name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((airport) => {
        const matchedAirport = apiairports.find(
          (apiAirport) => apiAirport.airport_iata_code === airport.$.Code
        );
        return {
          value: airport.$.Code,
          label: airport.$.Name,
          airportName: matchedAirport ? matchedAirport.airport_name : '' // Add airport name from apiairports
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    setOrigin(filteredOptions);
    setShowOriginDropdown(true);
  };

  const handleOrigin = (value, airportName) => {
    setInputOrigin(`${airportOriginCodes[value]} (${value}) ${airportName}`);
    setShowOriginDropdown(false);
  };

  const handleDestinationChange = (inputValue) => {
    setismodify(false);
    setInputDestination(inputValue);
    setFormData({ ...formData, flightDestination: inputValue });
    const filteredOptions = allAirportsDestination
      .filter((airport) =>
        airport.$.Name.toLowerCase().includes(inputValue.toLowerCase())
      )
      .map((airport) => {
        const matchedAirport = apiairports.find(
          (apiAirport) => apiAirport.airport_iata_code === airport.$.Code
        );
        return {
          value: airport.$.Code,
          label: airport.$.Name,
          airportName: matchedAirport ? matchedAirport.airport_name : '' // Add airport name from apiairports
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    setDestination(filteredOptions);
    setShowDestinationDropdown(true);
  };

  const handleDestination = (value, airportName) => {
    setInputDestination(`${airportDestinationCodes[value]} (${value}) ${airportName}`);
    setShowDestinationDropdown(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setismodify(true);
    let searchfrom = event.target.searchfrom.value.trim();
    let searchto = event.target.searchto.value.trim();
    let searchdeparture = event.target.searchdeparture.value.trim();
    let searchreturnDate = event.target.searchreturnDate.value.trim();
    const originerror = document.querySelector('.redorigin');
    const originerror1 = document.querySelector('.redorigin1');
    const destinationerror = document.querySelector('.redestination');
    const destinationerror1 = document.querySelector('.redestination1');
    const searchdepartureerror = document.querySelector('.redsearchdeparture');
    const searchreturnerror = document.querySelector('.redsearchreturn');
    const searchdepartureerror1 = document.querySelector('.redsearchdeparture1');
    const searchreturnerror1 = document.querySelector('.redsearchreturn1');
    const passengererror = document.querySelector('.redpassenger');
    const infanterror = document.querySelector('.infantmore');

    let totalpassenger = parseInt(adultCount) + parseInt(childCount) + parseInt(infantCount);
    let isValidPassenger = true;

    localStorage.setItem('lastorigin', searchfrom);
    localStorage.setItem('lastDestination', searchto);
    if (infantCount > adultCount) {

      isValidPassenger = false;
      infanterror.style.display = 'block';
    } else {
      infanterror.style.display = 'none';
    }
    const formatPattern = /\((.*?)\)/;
    const dateFormatPattern = /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!searchfrom) {
      isValidPassenger = false;
      originerror.style.display = 'block';
    } else if (!formatPattern.test(searchfrom)) {
      isValidPassenger = false;
      originerror1.style.display = 'block';
    } else {
      originerror1.style.display = 'none';
      originerror.style.display = 'none';
    }
    if (!searchto) {
      isValidPassenger = false;
      destinationerror.style.display = 'block';
    } else if (!formatPattern.test(searchto)) {
      isValidPassenger = false;
      destinationerror1.style.display = 'block';
    } else {
      destinationerror.style.display = 'none';
      destinationerror1.style.display = 'none';
    }
    if (!searchdeparture) {
      isValidPassenger = false;
      searchdepartureerror.style.display = 'block';
    } else if (!dateFormatPattern.test(searchdeparture)) {
      isValidPassenger = false;
      searchdepartureerror1.style.display = 'block';
    } else {
      searchdepartureerror.style.display = 'none';
      searchdepartureerror1.style.display = 'none';
    }
    if (formData.bookingType === 'Return') {
      if (!searchreturnDate) {
        isValidPassenger = false;
        searchreturnerror.style.display = 'block';
      } else {
        searchreturnerror.style.display = 'none';
      }
    } else {
      searchreturnerror.style.display = 'none';
    }
    if (searchreturnDate && !dateFormatPattern.test(searchreturnDate)) {
      isValidPassenger = false;
      searchreturnerror1.style.display = 'block';
    } else {
      searchreturnerror1.style.display = 'none';
    }
    if (totalpassenger > 9) {
      isValidPassenger = false;
      passengererror.style.display = 'block';
    } else {
      passengererror.style.display = 'none';
    }
    if (isValidPassenger) {
      setLoadingg(true);
      const formatDate = (inputDate) => {
        const parsedDate = parse(inputDate, 'dd/MM/yyyy', new Date());
        if (!isValid(parsedDate)) {
          return null;
        } else {
          const formattedDate = format(parsedDate, 'yyyy-MM-dd');
          return formattedDate;
        }

      };

      const searchfrom = event.target.searchfrom.value;
      const searchfromMatch = searchfrom.match(/\((\w+)\)/);
      const searchfromCode = searchfromMatch[1];
      const searchto = event.target.searchto.value;
      const searchtoMatch = searchto.match(/\((\w+)\)/);
      const searchtoCode = searchtoMatch[1];
      const searchdeparture = event.target.searchdeparture.value;
      const searchreturnDate = event.target.searchreturnDate.value;
      const formattedsearchdeparture = formatDate(searchdeparture);
      const formattedsearchreturnDate = formatDate(searchreturnDate);
      const adult = event.target.adult.value;
      const child = event.target.child.value;
      const infant = event.target.infant.value;
      const classtype = event.target.classtype.value;
      let cabinclass = classtype;
      let bookingtype = "";
      if (searchreturnDate) {
        bookingtype = "Return";
      } else {
        bookingtype = "oneway";
      }
      if (classtype === "Economy/Premium Economy") {
        cabinclass = "Economy";
      } else {
        cabinclass = classtype;
      }
      try {

        const dynamicCityCode = searchfromCode;
        const dynamicDestinationCode = searchtoCode;
        const dynamicDepTime = formattedsearchdeparture;
        const returndynamicDepTime = formattedsearchreturnDate;
        const dynamicCabinType = cabinclass;
        const PassengerCodeADT = adult;
        const PassengerCodeCNN = child;
        const PassengerCodeINF = infant;

        const createSoapEnvelope = (
          cityCode,
          destinationCode,
          depTime,
          returnDepTime,
          cabinType,
          passengerCodeADT,
          passengerCodeCNN,
          passengerCodeINF
        ) => {

          const generatePassengerElements = (age, count, type) => {
            return Array.from({ length: count }, (_, index) => `<com:SearchPassenger Code="${type}"${age ? ` Age="${age}"` : ''}/>`).join('');
          };
          const searchPassengerADT = generatePassengerElements('', passengerCodeADT, 'ADT');
          const searchPassengerCNN = generatePassengerElements('10', passengerCodeCNN, 'CNN');
          const searchPassengerINF = generatePassengerElements('01', passengerCodeINF, 'INF');

          const returnLegSection = returnDepTime
            ? `<air:SearchAirLeg>
                <air:SearchOrigin>
                  <com:CityOrAirport Code="${destinationCode}"/>
                </air:SearchOrigin>
                <air:SearchDestination>
                  <com:CityOrAirport Code="${cityCode}"/>
                </air:SearchDestination>
                <air:SearchDepTime PreferredTime="${returnDepTime}"/>
              </air:SearchAirLeg>`
            : '';

          return `<soap:Envelope xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
                  <soap:Body>
                <air:LowFareSearchReq TargetBranch="P7206253" TraceId="TVSBP001" SolutionResult="false" DistanceUnits="Km" AuthorizedBy="TAXIVAXI" xmlns:air="http://www.travelport.com/schema/air_v52_0" xmlns:com="http://www.travelport.com/schema/common_v52_0">
                    <com:BillingPointOfSaleInfo OriginApplication="UAPI"/>
                    <air:SearchAirLeg>
                        <air:SearchOrigin>
                            <com:CityOrAirport Code="${cityCode}"/>
                        </air:SearchOrigin>
                        <air:SearchDestination>
                            <com:CityOrAirport Code="${destinationCode}"/>
                        </air:SearchDestination>
                        <air:SearchDepTime PreferredTime="${depTime}"/>
                    </air:SearchAirLeg>
                    ${returnLegSection}
                    <air:AirSearchModifiers ETicketability="Yes" FaresIndicator="AllFares">
                        <air:PreferredProviders>
                            <com:Provider Code="1G"/>
                            <com:Provider Code="ACH"/>
                        </air:PreferredProviders>
                        <air:PermittedCabins>
                            <com:CabinClass Type="${cabinType}"/>
                        </air:PermittedCabins>
                    </air:AirSearchModifiers>
                    ${searchPassengerADT}
                    ${searchPassengerCNN}
                    ${searchPassengerINF}
                </air:LowFareSearchReq>
            </soap:Body>
            </soap:Envelope>`;
        };

        var soapEnvelope = createSoapEnvelope(
          dynamicCityCode,
          dynamicDestinationCode,
          dynamicDepTime,
          returndynamicDepTime,
          dynamicCabinType,
          PassengerCodeADT,
          PassengerCodeCNN,
          PassengerCodeINF,
        );
        sessionStorage.setItem('searchdata', soapEnvelope);
        const username = 'Universal API/uAPI6514598558-21259b0c';
        const password = 'tN=54gT+%Y';
        const authHeader = `Basic ${btoa(`${username}:${password}`)}`;

        const eresponse = await axios.post(
          'https://devapi.taxivaxi.com/reactSelfBookingApi/v1/makeFlightAirServiceRequest',
          soapEnvelope, { headers: { 'Content-Type': 'text/xml' } }
        );
        const eResponse = eresponse.data;
        setSearchFinalResponse(eResponse);

        parseString(eResponse, { explicitArray: false }, (err, result) => {
          if (err) {
            console.error('Error parsing XML:', err);
            return;
          }
          const lowFareSearchRsp = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp'];
          if (lowFareSearchRsp !== null && lowFareSearchRsp !== undefined) {
            const pricepointlist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:AirPricePointList']['air:AirPricePoint'];
            console.log("pricepointlist", pricepointlist)
            const Segmentlist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:AirSegmentList']['air:AirSegment'];
            const flightdetailist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:FlightDetailsList']['air:FlightDetails'];
            const hosttokenlist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:HostTokenList']['common_v52_0:HostToken'];
            const fareinfolist = result['SOAP:Envelope']['SOAP:Body']['air:LowFareSearchRsp']['air:FareInfoList']['air:FareInfo'];
            setFlightOptions(Array.isArray(pricepointlist) ? pricepointlist : [pricepointlist]);
            setFlightDetails(Array.isArray(flightdetailist) ? flightdetailist : [flightdetailist]);
            setSegment(Array.isArray(Segmentlist) ? Segmentlist : [Segmentlist]);
            setHostlist(Array.isArray(hosttokenlist) ? hosttokenlist : [hosttokenlist]);
            setFarelist(Array.isArray(fareinfolist) ? fareinfolist : [fareinfolist]);
          } else {
            const error = result['SOAP:Envelope']['SOAP:Body']['SOAP:Fault']['faultstring'];
            // ErrorLogger.logError('modify_search_api',soapEnvelope,error);
            setFlighterrors(error);
            setFlightOptions([]);
          }
        });


      } catch (error) {
        // ErrorLogger.logError('modify_search_api','Error',error);
        navigate('/tryagainlater');
      }
      finally {
        setLoadingg(false);
      }
    }

  };

  useEffect(() => {
    let timeoutId;
    const timeoutDuration = 5 * 60 * 1000;
    const handleInactive = () => {
      Swal.fire({
        title: 'Something went Wrong !',
        text: 'Your session has expired. You will be redirected to the homepage.',
        confirmButtonText: 'OK'
      });
      navigate('/');
    };
    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleInactive, timeoutDuration);
    };
    const resetOnActivity = () => {
      resetTimer();
      window.addEventListener('mousemove', resetTimer);
      window.addEventListener('keydown', resetTimer);
    };
    resetOnActivity();
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
    };
  }, [navigate]);

  const [selectedFlights, setSelectedFlights] = useState([]);
  // console.log('selectedFlights',selectedFlights);
  const [isMinimized, setIsMinimized] = useState(false);

  const handleClose = () => {
    setIsMinimized(true); // Minimize the popup
  };

  const handleExpand = () => {
    setIsMinimized(false); // Expand the popup
  };

  // const handleRemoveFare = (flightIndex, fareIndex) => {
  //   const updatedFlights = selectedFlightss.map((flight, index) => {
  //     if (index === flightIndex) {
  //       // Create a copy of the fare_details array to avoid direct mutation
  //       const updatedFareDetails = [...flight.fares];
  //       updatedFareDetails.splice(fareIndex, 1);
  //       return updatedFareDetails.length > 0
  //         ? { ...flight, fares: updatedFareDetails }
  //         : null; // Remove the entire flight if no fares remain
  //     }
  //     return flight;
  //   }).filter(flight => flight !== null); // Filter out null values (flights with no fares)
  //   setSelectedFlightss(updatedFlights);
  //   setSelectedPriceParseIndices([]);
  // };

  const handleRemoveFare = (flightIndex, fareIndex) => {
    const updatedFlights = [...selectedFlights]; // Copy the current state

    // Remove the specific fare
    updatedFlights[flightIndex].fare_details.splice(fareIndex, 1);

    // If no fares remain, remove the entire flight
    if (updatedFlights[flightIndex].fare_details.length === 0) {
      updatedFlights.splice(flightIndex, 1);
    }

    setSelectedFlights(updatedFlights);
  };

  const handleCheckboxChange = (airPricingInfo, farePrice, fareName, isReturn = 0) => {
    airPricingInfo.isReturn = isReturn;

    const flightOptionsList = airPricingInfo["air:FlightOptionsList"];
    const flightOption = flightOptionsList?.["air:FlightOption"];
    const flightOptionArray = Array.isArray(flightOption) ? flightOption : [flightOption];

    const flightDetails = flightOptionArray.flatMap((option) => {
      const options = option?.["air:Option"];
      const optionsArray = Array.isArray(options) ? options : [options];

      const selectedOptions = optionsArray.slice(0, 1);

      return selectedOptions.map((singleOption) => {
        const bookingInfo = singleOption?.["air:BookingInfo"];
        const segmentRefArray = Array.isArray(bookingInfo) ? bookingInfo : [bookingInfo];

        return segmentRefArray.map((info) => {
          const segmentRef = info?.["$"]?.["SegmentRef"];

          const matchingSegment = SegmentList.find(
            (segment) => segment["$"]["Key"] === segmentRef
          );

          if (!matchingSegment) {
            console.warn("No matching segment found for SegmentRef:", segmentRef);
            return null;
          }

          const carrier = matchingSegment["$"]["Carrier"];
          const flightNumber = matchingSegment["$"]["FlightNumber"];
          const totalPrice = airPricingInfo["$"]["TotalPrice"].replace("INR", "");
          const departureTime = matchingSegment["$"]["DepartureTime"] || "Unknown";
          const arrivalTime = matchingSegment["$"]["ArrivalTime"] || "Unknown";

          return {
            carrier: carrier || "Unknown",
            flightNumber: flightNumber || "Unknown",
            total_price: totalPrice || "Unknown",
            departure_time: departureTime,
            arrival_time: arrivalTime,
          };
        });
      });
    });

    const selectedFlightDetails = flightDetails.flat().filter(Boolean);

    const fareDetails = [
      {
        fare_type: fareName || "Default Fare Name",
        price: farePrice || "Unknown",
      },
    ];

    setSelectedFlights((prev) => {
      const flightIndex = prev.findIndex(
        (flight) =>
          flight["$"].Key === airPricingInfo["$"].Key &&
          flight.isReturn === airPricingInfo.isReturn
      );

      if (flightIndex !== -1) {
        const existingFlight = prev[flightIndex];
        const isFareAlreadyPresent = existingFlight.fare_details.some(
          (fare) => fare.fare_type === fareName && fare.price === farePrice
        );

        if (isFareAlreadyPresent) {
          // If the fare is already present, remove it
          const updatedFareDetails = existingFlight.fare_details.filter(
            (fare) => !(fare.fare_type === fareName && fare.price === farePrice)
          );

          if (updatedFareDetails.length > 0) {
            // Update the flight's fare_details
            const updatedFlight = {
              ...existingFlight,
              fare_details: updatedFareDetails,
            };
            return [
              ...prev.slice(0, flightIndex),
              updatedFlight,
              ...prev.slice(flightIndex + 1),
            ];
          } else {
            // Remove the flight entirely if no fare_details remain
            return prev.filter((_, index) => index !== flightIndex);
          }
        } else {
          // Append the new fare to the existing flight
          const updatedFlight = {
            ...existingFlight,
            fare_details: [
              ...existingFlight.fare_details,
              { fare_type: fareName, price: farePrice },
            ],
          };
          return [
            ...prev.slice(0, flightIndex),
            updatedFlight,
            ...prev.slice(flightIndex + 1),
          ];
        }
      } else {
        // Add a new flight with the fare details
        return [
          ...prev,
          {
            ...airPricingInfo,
            flightDetails: selectedFlightDetails,
            fare_details: fareDetails,
          },
        ];
      }
    });

    setIsMinimized(false);
  };

  const extractFareName = (priceParseData) => {
    try {
      const airPricingInfo = Array.isArray(priceParseData['air:AirPricingInfo'])
        ? priceParseData['air:AirPricingInfo']
        : [priceParseData['air:AirPricingInfo']];

      for (const pricingInfo of airPricingInfo) {
        const fareInfo = Array.isArray(pricingInfo['air:FareInfo'])
          ? pricingInfo['air:FareInfo']
          : [pricingInfo['air:FareInfo']];

        for (const fare of fareInfo) {
          const brand = fare?.['air:Brand'];
          const name = brand?.['$']?.['Name'];

          if (name) {
            return name;
          }
        }
      }
    } catch (error) {
      console.error('Error extracting Fare Name:', error);
    }

    return null;
  };

  // const handleCheckboxChange = (airPricingInfo, isReturn = 0) => {
  //   setIsDropdownVisible(false);
  //   airPricingInfo.isReturn = isReturn;
  //   const flightOptionsList = airPricingInfo["air:FlightOptionsList"];
  //   const flightOption = flightOptionsList?.["air:FlightOption"];
  //   const flightOptionArray = Array.isArray(flightOption) ? flightOption : [flightOption];

  //   const flightDetailss = flightOptionArray.flatMap((option) => {
  //     const options = option?.["air:Option"];
  //     const optionsArray = Array.isArray(options) ? options : [options];

  //     const selectedOptions = optionsArray.slice(0, 1);

  //     return selectedOptions.map((singleOption) => {
  //       const bookingInfo = singleOption?.["air:BookingInfo"];
  //       const segmentRefArray = Array.isArray(bookingInfo) ? bookingInfo : [bookingInfo];

  //       return segmentRefArray.map((info) => {
  //         const segmentRef = info?.["$"]?.["SegmentRef"];

  //         const matchingSegment = SegmentList.find(
  //           (segment) => segment["$"]["Key"] === segmentRef
  //         );

  //         if (!matchingSegment) {
  //           console.warn("No matching segment found for SegmentRef:", segmentRef);
  //           return null;
  //         }

  //         const carrier =  matchingSegment["$"]["Carrier"];
  //         const flightNumber = matchingSegment["$"]["FlightNumber"];
  //         const totalPrice = airPricingInfo["$"]["TotalPrice"].replace("INR", "");
  //         const departureTime = matchingSegment["$"]["DepartureTime"] || "Unknown";
  //         const arrivalTime = matchingSegment["$"]["ArrivalTime"] || "Unknown";

  //         return {
  //           carrier: carrier || "Unknown",
  //           flightNumber: flightNumber || "Unknown",
  //           total_price: totalPrice || "Unknown",
  //           departure_time: departureTime,
  //           arrival_time: arrivalTime,
  //         };
  //       });
  //     });
  //   });

  //   const selectedFlightDetails = flightDetailss.flat().filter(Boolean);
  //   const fareDetails = selectedPriceParseIndices.length === 0
  // ? [
  //     {
  //       fare_type: "Base Fare",
  //       price: airPricingInfo["$"]["TotalPrice"].replace("INR", ""),
  //     },
  //   ]
  // : selectedPriceParseIndices.map((index) => {
  //     const fareName = priceParse[index]['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] || "Default Fare Name";
  //     const farePricee = (priceParse[index]['$']['TotalPrice'] || "").replace("INR", "").trim();
  //     const farePrice = calculateFinalPrice(farePricee, markupdata, cabinClass, fareName);
  //     return {
  //       fare_type: fareName,
  //       price: farePrice,
  //     };
  //   });

  //   setSelectedFlights((prev) => {
  //     const isSelected = prev.some(
  //       (flight) =>
  //         flight["$"].Key === airPricingInfo["$"].Key &&
  //         flight.isReturn === airPricingInfo.isReturn
  //     );

  //     if (isSelected) {
  //       return prev.filter(
  //         (flight) =>
  //           flight["$"].Key !== airPricingInfo["$"].Key ||
  //           flight.isReturn !== airPricingInfo.isReturn
  //       );
  //     } else {
  //       // Add the new data with flight details to the selection
  //       return [
  //         ...prev,
  //         {
  //           ...airPricingInfo,
  //           flightDetails: selectedFlightDetails, 
  //           fare_details: fareDetails,
  //         },
  //       ];
  //     }
  //   });
  //   setIsMinimized(false);
  //   setSelectedPriceParseIndices([])
  // };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientName, setClientName] = useState(clientname);
  const [spocName, setSpocName] = useState(spocname);
  // const [spocEmail, setSpocEmail] = useState(spocemail.split(',')); // Prefill and allow editing
  const normalizedAdditionalEmails = Array.isArray(additionalemail) ? additionalemail : [additionalemail];
  const normalizedCCEmails = Array.isArray(ccmail) 
  ? ccmail.flatMap(email => email.split(',').map(e => e.trim())) 
  : ccmail ? ccmail.split(',').map(e => e.trim()) 
  : [];

  const [ccEmails, setCCEmails] = useState(normalizedCCEmails);
  const [ccEmailInput, setCCEmailInput] = useState("");
  const normalizedSpocEmails = Array.isArray(spocemail)
  ? spocemail.flatMap(email => email.split(',').map(e => e.trim()))
  : spocemail ? spocemail.split(',').map(e => e.trim()) 
  : [];

const [spocEmails, setSpocEmails] = useState(normalizedSpocEmails);
const [spocEmailInput, setSpocEmailInput] = useState("");


  // Initialize state with the normalized array
  const [additionalEmails, setAdditionalEmails] = useState(normalizedAdditionalEmails);
  // console.log('ademail', additionalEmails);
  const [additionalEmailInput, setAdditionalEmailInput] = useState(""); // Manage current input for additional email
  const [remark, setRemark] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true); // State to check email validity

  const handleSend = () => {
    if (!spocEmails) {
      setIsEmailValid(false); // Mark eman
    } else {
      setIsEmailValid(true); // Reset if email is valid
      approverButtonClick(); // Call the function to send
    }
  };

  const modalopen = () => {
    setIsModalOpen(true);
  };

  // Add Additional Email
  const handleAddEmail = () => {
    if (additionalEmailInput.trim() !== "" && !additionalEmails.includes(additionalEmailInput.trim())) {
      setAdditionalEmails([...additionalEmails, additionalEmailInput.trim()]);
      setAdditionalEmailInput(""); // Clear input
    }
  };

  // Handle adding the email when input loses focus (onBlur event)
  const handleAddEmailOnBlur = () => {
    if (
      typeof additionalEmailInput === "string" &&
      additionalEmailInput.trim() !== "" &&
      !additionalEmails.includes(additionalEmailInput.trim())
    ) {
      setAdditionalEmails((prev) => [...prev, additionalEmailInput.trim()]);
      setAdditionalEmailInput(""); // Clear input
    }
  };

  // Remove Additional Email
  const handleRemoveEmail = (email) => {
    setAdditionalEmails(additionalEmails.filter((e) => e !== email));
  };

  const handleAddCCEmail = () => {
    if (ccEmailInput.trim() !== "" && !ccEmails.includes(ccEmailInput.trim())) {
      setCCEmails([...ccEmails, ccEmailInput.trim()]);
      setCCEmailInput(""); // Clear input
    }
  };

  // Handle adding the CC email when the input loses focus (onBlur event)
  // const handleAddCCEmailOnBlur = () => {
  //   if (
  //     typeof ccEmailInput === "string" &&
  //     ccEmailInput.trim() !== "" &&
  //     !ccEmails.includes(ccEmailInput.trim())
  //   ) {
  //     setCCEmails((prev) => [...prev, ccEmailInput.trim()]);
  //     setCCEmailInput(""); // Clear input
  //   }
  // };

  const handleAddCCEmailOnBlur = () => {
    if (ccEmailInput.trim() !== "") {
      const newEmails = ccEmailInput.split(',').map(email => email.trim());
      setCCEmails((prev) => [...new Set([...prev, ...newEmails])]); // Remove duplicates
      setCCEmailInput(""); // Clear input
    }
  };
  const handleAddSpocEmailOnBlur = () => {
    if (spocEmailInput.trim() !== "") {
      const newEmails = spocEmailInput.split(',').map(email => email.trim());
      setSpocEmails((prev) => [...new Set([...prev, ...newEmails])]); // Remove duplicates
      setSpocEmailInput(""); // Clear input
    }
  };
  

  const handleRemoveCCEmail = (emailToRemove) => {
    setCCEmails(ccEmails.filter((email) => email !== emailToRemove));
  };

  const approverButtonClick = () => {
    console.log('hi');
    const segregateFlights = (flight) => {
      console.log('flight', flight);
      const flightOptionsList = flight["air:FlightOptionsList"];
      const flightOption = flightOptionsList?.["air:FlightOption"];
      const flightOptionArray = Array.isArray(flightOption) ? flightOption : [flightOption];

      return flightOptionArray.flatMap((option) => {
        const options = option?.["air:Option"];
        const optionsArray = Array.isArray(options) ? options : [options];
        const selectedOptions = optionsArray.slice(0, 1); // Only consider the first selected option

        return selectedOptions.map((singleOption) => {
          const bookingInfo = singleOption?.["air:BookingInfo"];
          const segmentRefArray = Array.isArray(bookingInfo) ? bookingInfo : [bookingInfo];

          const flightDetails = segmentRefArray.map((info) => {
            const segmentRef = info?.["$"]?.["SegmentRef"];
            const matchingSegment = SegmentList.find(
              (segment) => segment["$"]["Key"] === segmentRef
            );

            if (!segmentRef || !matchingSegment) {
              console.warn("Invalid SegmentRef or missing matching segment:", { segmentRef, info });
              return null; // Skip invalid entries
            }

            return {
              flight_no: `${matchingSegment["$"]["Carrier"]}${matchingSegment["$"]["FlightNumber"] || "Unknown"}`,
              airline_name: handleAirline(matchingSegment["$"]["Carrier"] || "Unknown"),
              from_city: handleApiAirport(matchingSegment["$"]["Origin"] || "Unknown"),
              to_city: handleApiAirport(matchingSegment["$"]["Destination"] || "Unknown"),
              departure_datetime: matchingSegment["$"]["DepartureTime"] || "Unknown",
              arrival_datetime: matchingSegment["$"]["ArrivalTime"] || "Unknown",
              origin_airline_city: handleAirport(matchingSegment['$']['Origin']) || "Unknown",
              destination_airline_city: handleAirport(matchingSegment['$']['Destination']) || "Unknown",
            };
          }).filter(Boolean); // Remove null entries

          const bookinfo = singleOption?.["air:BookingInfo"];
          const no_of_stops = Array.isArray(bookinfo) ? bookinfo.length - 1 : 0;
          // const fareDetails = flight.fare_details;
          const fareDetails = flight?.fare_details || null;


          return {
            flight_no: flightDetails.map((detail) => detail.flight_no).join(", "),
            airline_name: flightDetails.map((detail) => detail.airline_name).join(", "),
            from_city: flightDetails[0]?.from_city || "Unknown",
            to_city: flightDetails[flightDetails.length - 1]?.to_city || "Unknown",
            departure_datetime: flightDetails[0]?.departure_datetime || "Unknown",
            arrival_datetime: flightDetails[flightDetails.length - 1]?.arrival_datetime || "Unknown",
            price: parseInt(flight["$"].TotalPrice.replace("INR", "").trim(), 10),
            is_return: flight?.isReturn ? 1 : 0,
            no_of_stops: no_of_stops,
            carrier: flightDetails.map((detail) => detail.flight_no.slice(0, 2)).join(", "),
            duration: formatISODuration(
              singleOption?.["$"]?.["TravelTime"] || "00:00:00"
            ),
            is_refundable: flight["$"].Refundable ? 1 : 0,
            fare_details: fareDetails,
            flight_details: flightDetails,
          };
        });
      });
    };

    const allFlights = selectedFlights.flatMap(segregateFlights).filter(Boolean);
    const onwardFlights = [];
    const returnFlights = [];

    // Segregate flights based on inputorigin
    allFlights.forEach((flight) => {
      // Normalize both strings by ensuring they are strings first
      const normalize = (str) => {
        if (typeof str !== 'string') return ''; // Handle non-string values
        return str.replace(/\(.*?\)\s*/g, "").trim();
      };
      // console.log('Normalized:', normalize(inputOrigin));
      const normalizedInputOrigin = normalize(inputOrigin);
      const normalizedFromCity = normalize(flight.from_city);

      // Check if one string is part of the other
      if (
        normalizedFromCity.includes(normalizedInputOrigin) ||
        normalizedInputOrigin.includes(normalizedFromCity)
      ) {
        onwardFlights.push(flight);
      } else {
        returnFlights.push(flight);
      }
    });

    const payload = {

      booking_id: bookingid,
      email: spocEmails,
      seat_type: cabinclass,
      departure_date: searchdeparturedate,
      return_date: searchreturnd,
      no_of_seats: no_of_seats,
      request_id: request_id,
      flights: {
        ...(onwardFlights.length > 0 && { onward: { flight_options: onwardFlights } }),
        ...(returnFlights.length > 0 && { return: { flight_options: returnFlights } }),
      },
      additional_emails: additionalEmails,
      cc_email: ccEmails,
      remark: remark,
      client_name: clientName,
      spoc_name: spocName,
    };
    console.log('payload', payload);
    const apiLink = 'https://demo.taxivaxi.com/api/flights/addCotravFlightOptionBooking';

    axios.post(apiLink, JSON.stringify(payload), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => {
        // Check if the response contains success = "1"
        if (response.data.success === "1") {
          Swal.fire({
            title: "Success!",
            text: "Flight options have been sent successfully.",
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          // If success is "0", show the error message from the response
          Swal.fire({
            title: "Error!",
            text: response.data.error || "Something went wrong.",
            icon: "error",
            confirmButtonText: "Retry",
          });
        }
      })
      .catch((error) => {
        // Handle any error in the request itself
        Swal.fire({
          title: "Error!",
          text: "Something went wrong while creating the booking.",
          icon: "error",
          confirmButtonText: "Retry",
        });
      });
  };

  const [selectedPriceIndex, setSelectedPriceIndex] = useState(null);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [visibleDetailsByName, setVisibleDetailsByName] = useState({}); // For name-specific toggle

  const toggleDetails = async (name) => {
    if (visibleDetails) {
      setVisibleDetails(false);
      setVisibleDetailsByName(null);
    } else {
      setVisibleDetails(true);
      setVisibleDetailsByName(name);
    }

  };

  // const renderedSegmentRefs = new Set();

  // const [dataFound, setDataFound] = useState(false);
  const renderedSegmentRefs = useRef(new Set()); // Track already rendered segments
  const [filteredFlights, setFilteredFlights] = useState([]);
  // console.log('filteredFlights',filteredFlights);

  useEffect(() => {
    setFilteredFlights([]); // Clear previous flights to avoid duplicates

    flightOptions.forEach((pricepoint, priceindex) => {
      setTimeout(() => { // Introduce a delay for incremental rendering
        // const totalPrice = parseFloat(pricepoint.$.TotalPrice.replace(/[^\d.]/g, ''));

        const collectedSegmentRefs = [];
        const options = pricepoint['air:AirPricingInfo']?.['air:FlightOptionsList']?.['air:FlightOption']?.['air:Option'];

        const optionsArray = Array.isArray(options) ? options : options ? [options] : [];
        optionsArray.forEach((airOption) => {
          const bookingInfo = airOption?.['air:BookingInfo'];
          const bookingInfoArray = Array.isArray(bookingInfo) ? bookingInfo : bookingInfo ? [bookingInfo] : [];

          bookingInfoArray.forEach((info) => {
            if (info?.['$']?.['SegmentRef']) {
              const segmentRef = info['$']['SegmentRef'];
              collectedSegmentRefs.push(segmentRef);
            }
          });
        });

        const shouldSkipForm = collectedSegmentRefs.some((ref) => renderedSegmentRefs.current.has(ref));

        if (!shouldSkipForm) {
          collectedSegmentRefs.forEach((ref) => renderedSegmentRefs.current.add(ref));

          setFilteredFlights((prevFlights) => [...prevFlights, pricepoint]); // Add one by one
        }

      }, priceindex * 300); // Delay each flight render
    });
  }, [flightOptions]);




  return (
    <div className="yield-content" style={{ background: '#e8e4ff' }}>
      <header className="search-bar" id="widgetHeader">
        <form id="submit-form" onSubmit={(e) => handleSubmit(e)} action="" method="POST" autoComplete="off">
          <div id="search-widget" className="hsw v2">
            <div className="hsw_inner" style={{ marginLeft: '7%' }}>

              <div className="hsw_inputBox tripTypeWrapper">
                <label htmlFor="tripType" className="lbl_input latoBold font12 blueText appendBottom5">
                  TRIP TYPE
                </label>
                <div className="selectDropdown">
                  <select
                    id="tripType"
                    className="tripTypeSelect"
                    name="bookingtype"
                    value={formData.bookingType}
                    onChange={handleRadioChange}
                    disabled={bookingid}
                    style={{ backgroundColor: bookingid ? "#e0e0e0" : "white", fontSize: '14px', fontWeight: '600' }}
                  >
                    <option value="oneway">One Way</option>
                    <option value="Return">Return</option>
                  </select>
                  <div className="dropdownIcon">
                    {/* SVG Icon */}
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 10l5 5 5-5"
                        stroke="#666"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </div>


              <div className="hsw_inputBox">
                <label htmlFor="fromCity" className="lbl_input latoBold font12 blueText appendBottom5">
                  FROM
                </label>
                <div className="input-a" style={{ backgroundColor: bookingid ? "#e0e0e0" : "white" }}>
                  <input
                    type="text"
                    placeholder="Search..."
                    id="searchfrom"
                    className="text_input"
                    name="searchfrom"
                    value={inputOrigin}
                    onChange={(e) => handleOriginChange(e.target.value)}
                    disabled={bookingid}
                    style={{ fontSize: '14px', fontWeight: '600' }}

                  />

                  {showOriginDropdown && (
                    <ul style={{
                      position: 'absolute',
                      top: '100%',
                      marginLeft: '-8px',
                      borderRadius: '3px',
                      backgroundColor: '#fff',
                      paddingLeft: '6px',
                      width: '100%',
                      border: '1px solid #e3e3e3',
                      listStyle: 'none',
                      width: '100%',
                      zIndex: '9999',
                      maxHeight: '150px',
                      minHeight: 'auto',
                      overflow: 'auto'
                    }}>
                      {origin.map((option) => (
                        <li style={{
                          cursor: 'pointer',
                          fontFamily: 'Montserrat',
                          color: '#4c4c4c',
                          fontSize: '10px',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          paddingRight: '5px'
                        }} key={option.value} onClick={() => handleOrigin(option.value, option.airportName)}>
                          {option.label} ({option.value}) <br />
                          {option.airportName}
                        </li>
                      ))}
                    </ul>
                  )}


                </div>
                <div className="redorigin" style={{
                  color: 'red',
                  fontsize: '10px',
                  fontfamily: 'Raleway', display: 'none'
                }}>Please select Origin</div>
                <div className="redorigin1" style={{
                  color: 'red',
                  fontsize: '10px',
                  fontfamily: 'Raleway', display: 'none'
                }}>Please select valid Origin</div>

              </div>


              <div>

                <button type="button" className='swapbutton' onClick={swapOriginAndDestination}><img src='/img/Swap-01.png' width={'17px'} /></button>
              </div>


              <div className="hsw_inputBox">
                <label htmlFor="toCity" className="lbl_input latoBold font12 blueText appendBottom5">
                  TO
                </label>
                <div className="input-a" style={{ backgroundColor: bookingid ? "#e0e0e0" : "white" }}>
                  <input
                    type="text"
                    placeholder="Search..."
                    id="searchto" className="text_input" name="searchto"
                    value={inputDestination}
                    onChange={(e) => handleDestinationChange(e.target.value)}
                    disabled={bookingid}
                    style={{ fontSize: '14px', fontWeight: '600' }}
                  />

                  {showDestinationDropdown && (
                    <ul style={{
                      position: 'absolute',
                      top: '100%',
                      marginLeft: '-8px',
                      borderRadius: '3px',
                      backgroundColor: '#fff',
                      paddingLeft: '6px',
                      width: '100%',
                      border: '1px solid #e3e3e3',
                      listStyle: 'none',
                      width: '100%',
                      zIndex: '9999',
                      maxHeight: '150px',
                      minHeight: 'auto',
                      overflow: 'auto'
                    }}>
                      {destination.map((option) => (
                        <li style={{
                          cursor: 'pointer',
                          fontFamily: 'Montserrat',
                          color: '#4c4c4c',
                          fontSize: '10px',
                          paddingTop: '5px',
                          paddingBottom: '5px',
                          paddingRight: '5px'
                        }} key={option.value} onClick={() => handleDestination(option.value, option.airportName)}>
                          {option.label} ({option.value})<br />
                          {option.airportName}
                        </li>
                      ))}
                    </ul>
                  )}

                </div>

                <div className="redestination" style={{
                  color: 'red',
                  fontsize: '10px',
                  fontfamily: 'Raleway', display: 'none'
                }}>Please select Destination</div>
                <div className="redestination1" style={{
                  color: 'red',
                  fontsize: '10px',
                  fontfamily: 'Raleway', display: 'none'
                }}>Please select valid Destination</div>
              </div>


              <div className="hsw_inputBox">
                <label className="lbl_input latoBold font12 blueText appendBottom5">
                  DEPART
                </label>
                <div className="input-a" onClick={() => setdepIsOpen(true)} style={{ width: '120px', height: '39px', backgroundColor: bookingid ? "#e0e0e0" : "white" }} >
                  <div style={{ fontSize: '14px', fontWeight: '600' }}>
                    <DatePicker className="custom-datepicker"
                      name="searchdeparture"
                      selected={formData.departureDate}
                      onChange={handleDepartureDateChange}
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      value={ddmmyyyyformatDate(formData.departureDate)}
                      open={isdepOpen}
                      onClickOutside={() => setdepIsOpen(false)}
                      disabled={bookingid}
                      style={{ fontSize: '5px' }}
                    />
                  </div>


                  <span className="date-icon" onClick={(e) => { e.stopPropagation(); setdepIsOpen(true) }}></span></div>
                <span id="errorDate" style={{
                  color: 'red',
                  fontsize: '12px',
                  fontfamily: 'Raleway'
                }} className="error-message"></span>
                <div className="redsearchdeparture" style={{
                  color: 'red',
                  fontsize: '12px',
                  fontfamily: 'Raleway'
                }}>Please select Depart Date</div>
                <div className="redsearchdeparture1" style={{
                  display: 'none',
                  color: 'red',
                  fontsize: '12px',
                  fontfamily: 'Raleway'
                }}>Please select valid Depart Date</div>
              </div>


              <div className="hsw_inputBox" id="departurereturn">
                <label htmlFor="return" className="lbl_input latoBold font12 blueText appendBottom5">
                  RETURN
                </label>
                <div className="input-a" onClick={formData.bookingType === "Return" ? () => setretIsOpen(true) : () => () => setretIsOpen(false)} style={{ width: '120px', backgroundColor: bookingid ? "#e0e0e0" : "white" }}>
                  <DatePicker
                    name="searchreturnDate"
                    selected={formData.returnDate}
                    onChange={handleReturnDateChange}
                    dateFormat="dd/MM/yyyy"
                    minDate={formData.departureDate || new Date()}
                    placeholderText="Add Return Date"
                    value={formData.returnDate && ddmmyyyyformatDate(formData.returnDate)}
                    // disabled={!isReturnEnabled}
                    open={isretOpen}
                    onClickOutside={() => setretIsOpen(false)}
                    // disabled={bookingid}
                    disabled={!isReturnEnabled || bookingid}
                    className="custom-datepicker"
                    style={{ fontSize: '5px',paddingBottom:"4px" }}
                  />
                  <span
                    className="date-icon"
                    onClick={(e) => {
                      if (formData.bookingType === "Return") {
                        e.stopPropagation();
                        setretIsOpen(true);
                      }
                    }}
                  ></span>
                </div>
                <span id="errorDate1" style={{
                  color: 'red',
                  fontsize: '12px',
                  fontfamily: 'Raleway'
                }} className="error-message"></span>
                <div className="redsearchreturn" style={{
                  display: 'none',
                  color: 'red',
                  fontsize: '12px',
                  fontfamily: 'Raleway'
                }}>Please select Return Date</div>
                <div className="redsearchreturn1" style={{
                  display: 'none',
                  color: 'red',
                  fontsize: '12px',
                  fontfamily: 'Raleway'
                }}>Please select valid Return Date</div>
              </div>


              <div className="hsw_inputBox">
                <label htmlFor="travellerAndClass" className="lbl_input latoBold font12 blueText appendBottom5">
                  PASSENGERS &amp; CLASS
                </label>
                <div className="input-a" style={{ width: '250px', height: '39px', backgroundColor: bookingid ? "#e0e0e0" : "white" }}>
                  <input
                    type="text"
                    id="openpassengermodal"
                    name="openpassengermodal"
                    className="openpassengermodal srch-lbl"
                    placeholder="Select all"
                    value={`Adult: ${adultCount}, Child: ${childCount}, Infant: ${infantCount}, Cabinclass: ${cabinClass} class`}
                    onClick={handleToggle}
                    disabled={bookingid}
                    readOnly
                    style={{ fontSize: '14px', fontWeight: '600' }}
                  />

                </div>
                <div className="redpassenger" style={{
                  color: 'red',
                  fontsize: '12px',
                  fontfamily: 'Raleway'
                }}>Please select maximum 9 passenger</div>
                <div className="infantmore" style={{
                  color: 'red',
                  fontsize: '12px',
                  fontfamily: 'Raleway'
                }}>Number of infants cannot be more than adults</div>

                <div className="search-asvanced" style={{ display: isOpen ? 'block' : 'none' }}>
                  <div className="search-large-i">
                    <div className="srch-tab-line no-margin-bottom">
                      <div className="srch-tab-line no-margin-bottom">
                        <label>Adults (12y + : on the day of travel)</label>
                        <div className="select-wrapper1">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
                            <React.Fragment key={value}>
                              <input
                                type="radio"
                                name="adult"
                                id={`adult${value}`}
                                value={value}
                                onChange={(e) => handleAdult(e.target.value)}
                                checked={Cookies.get('cookiesData') ? value.toString() === adultCount.toString() : value === 1}
                              />
                              <label htmlFor={`adult${value}`}>{value}</label>
                            </React.Fragment>
                          ))}
                          <input
                            type="radio"
                            name="adult"
                            id="adultgreater9"
                            value={10}
                            onChange={(e) => handleAdult(e.target.value)}
                          />
                          <label htmlFor="adultgreater9">&gt;9</label>
                        </div>
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                  <div className="search-large-i">
                    <div className="srch-tab-line no-margin-bottom">
                      <label>Children (2y - 12y : on the day of travel)</label>
                      <div className="select-wrapper1">
                        {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                          <React.Fragment key={value}>
                            <input
                              type="radio"
                              name="child"
                              id={`child${value}`}
                              value={value}
                              onChange={(e) => handleChild(e.target.value)}
                              checked={Cookies.get('cookiesData') ? value.toString() === childCount.toString() : value === 0}
                            />
                            <label htmlFor={`child${value}`}>{value}</label>
                          </React.Fragment>
                        ))}
                        <input
                          type="radio"
                          name="child"
                          id="childgreater6"
                          value={7}
                          onChange={(e) => handleChild(e.target.value)}
                        />
                        <label htmlFor="childgreater6">&gt;6</label>
                      </div>

                    </div>
                  </div>
                  <div className="search-large-i">
                    <div className="srch-tab-line no-margin-bottom">
                      <label>Infants (below 2y : on the day of travel)</label>
                      <div className="select-wrapper1">
                        {[0, 1, 2, 3, 4, 5, 6].map((value) => (
                          <React.Fragment key={value}>
                            <input
                              type="radio"
                              name="infant"
                              id={`infant${value}`}
                              value={value}
                              onChange={(e) => handleInfant(e.target.value)}
                              checked={Cookies.get('cookiesData') ? value.toString() === infantCount.toString() : value === 0}
                            />
                            <label htmlFor={`infant${value}`}>{value}</label>
                          </React.Fragment>
                        ))}
                        <input
                          type="radio"
                          name="infant"
                          id="infantgreater6"
                          value={7}
                          onChange={(e) => handleInfant(e.target.value)}
                        />
                        <label htmlFor="infantgreater6">&gt;6</label>
                      </div>

                    </div>
                  </div>
                  <div className="clear" />
                </div>
                <div className="search-asvanced" style={{ display: isOpen ? 'block' : 'none' }}>
                  <div className="search-large-i1">
                    <div className="srch-tab-line no-margin-bottom">
                      <label>Choose Travel Class</label>
                      <div className="select-wrapper1 select-wrapper2">
                        {['Economy/Premium Economy', 'Business', 'First'].map((value) => (
                          <React.Fragment key={value}>
                            <input
                              type="radio"
                              name="classtype"
                              id={`classtype${value}`}
                              value={value}
                              onChange={(e) => handleClasstype(e.target.value)}
                              checked={cabinClass?.toString() === "Economy" && value === "Economy/Premium Economy" ? true : cabinClass?.toString() === value}
                            />
                            <label style={{ lineHeight: '1.8' }} htmlFor={`classtype${value}`}>{value === "Economy/Premium Economy" ? value : `${value} class`}</label>
                          </React.Fragment>
                        ))}
                      </div>
                      <div className="clear" />
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
              <button type="submit" className="srch-btn" style={{ width: '98px', marginBottom: '-5px', height: '39px' ,fontSize:"14px"}} id="btnSearch">Search</button>

            </div>
          </div>
        </form>
      </header>
      {/* {loadingg &&  <div className="loader" style={{display:"block"}}>
            <img
              src="/img/flight-loader-material-gif.gif"
              alt="Loader"
            />
            <h2>Hold on, we’re fetching packages for you</h2>
          </div>
        } */}
      {loadingg &&
        <div className="page-center-loader flex items-center justify-center">
          <div className="big-loader flex items-center justify-center">
            <img className="loader-gif" src="/img/cotravloader.gif" alt="Loader" />
            <p className="text-center ml-4 text-gray-600 text-lg">
              Retrieving flight details. Please wait a moment.
            </p>
          </div>
        </div>
      }
      <div className="main-cont" id="main_cont">
        <div className="body-wrapper">
          <div className="wrapper-padding">
            <div className="two-colls">
              {/* sidebar start */}
              <div className="two-colls-left">

                <div className='side-block fly-in headingofflights'>
                  Flights from {inputOrigin && (
                    inputOrigin.includes(" - ")
                      ? inputOrigin.split(" - ")[0].trim()
                      : inputOrigin.includes("(")
                        ? inputOrigin.split("(")[0].trim()
                        : inputOrigin.trim()
                  )} {" "}
                  to {inputDestination && (
                    inputDestination.includes(" - ")
                      ? inputDestination.split(" - ")[0].trim()
                      : inputDestination.includes("(")
                        ? inputDestination.split("(")[0].trim()
                        : inputDestination.trim()
                  )}
                </div>

                {flightOptions && flightOptions.length > 0 &&
                  <>

                    <div className="side-block fly-in">
                      <div className="side-stars">
                        <div className="side-padding">
                          <div className="side-lbl">Stops From {inputOrigin && (
                            inputOrigin.includes(" - ")
                              ? inputOrigin.split(" - ")[0].trim()
                              : inputOrigin.includes("(")
                                ? inputOrigin.split("(")[0].trim()
                                : inputOrigin.trim()
                          )} {" "}</div>
                          {(() => {
                            let uniqueStops = new Set();

                            flightOptions && flightOptions.map((pricepoint, priceindex) => (
                              pricepoint['air:AirPricingInfo'] && (
                                Array.isArray(pricepoint['air:AirPricingInfo'])
                                  ? (
                                    <>
                                      {
                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0] ? (
                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                            Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                              ? (
                                                <>
                                                  {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                    ? (

                                                      uniqueStops.add(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].length - 1)

                                                    ) : (
                                                      uniqueStops.add(0)
                                                    )
                                                  }
                                                </>
                                              ) : (
                                                <>
                                                  {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                    ? (
                                                      uniqueStops.add(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].length - 1)

                                                    ) : (
                                                      uniqueStops.add(0)
                                                    )
                                                  }
                                                </>
                                              )
                                          )
                                        ) : (
                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                            Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                              ? (
                                                <>
                                                  {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                    ? (

                                                      uniqueStops.add(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].length - 1)

                                                    ) : (
                                                      uniqueStops.add(0)
                                                    )
                                                  }
                                                </>
                                              ) : (
                                                <>
                                                  {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                    ? (
                                                      uniqueStops.add(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1)

                                                    ) : (
                                                      uniqueStops.add(0)
                                                    )
                                                  }
                                                </>
                                              )
                                          )
                                        )
                                      }

                                    </>
                                  ) : (
                                    <>
                                      {
                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0] ? (
                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                            Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                              ? (
                                                <>
                                                  {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                    ? (
                                                      uniqueStops.add(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].length - 1)

                                                    ) : (
                                                      uniqueStops.add(0)
                                                    )
                                                  }
                                                </>
                                              ) : (
                                                <>
                                                  {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                    ? (
                                                      uniqueStops.add(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].length - 1)
                                                    ) : (
                                                      uniqueStops.add(0)
                                                    )
                                                  }
                                                </>
                                              )
                                          )
                                        ) :
                                          (
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                ? (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                      ? (
                                                        uniqueStops.add(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].length - 1)

                                                      ) : (
                                                        uniqueStops.add(0)
                                                      )
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                      ? (
                                                        uniqueStops.add(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1)
                                                      ) : (
                                                        uniqueStops.add(0)
                                                      )
                                                    }
                                                  </>
                                                )
                                            )
                                          )
                                      }

                                    </>
                                  )
                              )
                            ));
                            let uniqueStopsArray = Array.from(uniqueStops);

                            return (
                              <>
                                {uniqueStopsArray.map((stop, index) => (
                                  <div className="checkbox" key={index}>
                                    <input
                                      type="checkbox"
                                      id={`stop${stop}`}
                                      name={stop === 0 ? 'nonstop' : `${stop}stop`}
                                      onChange={() => handleStopCheckboxChange(stop)}
                                      checked={selectedStops.includes(stop)}
                                    />
                                    <label>{stop === 0 ? 'non' : stop} stop</label>
                                  </div>

                                ))}
                              </>
                            );
                          })()}


                        </div>
                      </div>
                    </div>

                    {formData.bookingType === "Return" && (
                      <div className="side-block fly-in">
                        <div className="side-stars">
                          <div className="side-padding">
                            <div className="side-lbl">Stops From {inputDestination && (
                              inputDestination.includes(" - ")
                                ? inputDestination.split(" - ")[0].trim()
                                : inputDestination.includes("(")
                                  ? inputDestination.split("(")[0].trim()
                                  : inputDestination.trim()
                            )}</div>
                            {(() => {
                              let uniquereturnStops = new Set();

                              flightOptions && flightOptions.map((pricepoint, priceindex) => (
                                pricepoint['air:AirPricingInfo'] && (
                                  Array.isArray(pricepoint['air:AirPricingInfo'])
                                    ? (
                                      <>
                                        {
                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1] &&
                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                            Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                              ? (
                                                <>
                                                  {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                    ? (

                                                      uniquereturnStops.add(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].length - 1)

                                                    ) : (
                                                      uniquereturnStops.add(0)
                                                    )
                                                  }
                                                </>
                                              ) : (
                                                <>
                                                  {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                    ? (
                                                      uniquereturnStops.add(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].length - 1)

                                                    ) : (
                                                      uniquereturnStops.add(0)
                                                    )
                                                  }
                                                </>
                                              )
                                          )

                                        }

                                      </>
                                    ) : (
                                      <>
                                        {
                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1] &&
                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                            Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                              ? (
                                                <>
                                                  {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                    ? (
                                                      uniquereturnStops.add(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].length - 1)

                                                    ) : (
                                                      uniquereturnStops.add(0)
                                                    )
                                                  }
                                                </>
                                              ) : (
                                                <>
                                                  {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                    Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                    ? (
                                                      uniquereturnStops.add(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].length - 1)
                                                    ) : (
                                                      uniquereturnStops.add(0)
                                                    )
                                                  }
                                                </>
                                              )
                                          )

                                        }

                                      </>
                                    )
                                )
                              ));
                              let uniquereturnStopsArray = Array.from(uniquereturnStops);

                              return (
                                <>
                                  {uniquereturnStopsArray.map((returnstop, returnindex) => (
                                    <div className="checkbox" key={returnindex}>
                                      <input
                                        type="checkbox"
                                        id={`stop${returnstop}`}
                                        name={returnstop === 0 ? 'nonstop' : `${returnstop}stop`}
                                        onChange={() => handlereturnStopCheckboxChange(returnstop)}
                                        checked={selectedreturnStops.includes(returnstop)}
                                      />
                                      <label>{returnstop === 0 ? 'non' : returnstop} stop</label>
                                    </div>

                                  ))}
                                </>
                              );
                            })()}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="side-block fly-in">
                      <div className="side-padding">
                        <div className="side-lbl">Departure From {inputOrigin && (
                          inputOrigin.includes(" - ")
                            ? inputOrigin.split(" - ")[0].trim()
                            : inputOrigin.includes("(")
                              ? inputOrigin.split("(")[0].trim()
                              : inputOrigin.trim()
                        )} {" "}</div>
                        <div className="timeSlotsOuter">
                          <div
                            className={`appendBottom12 filterTimeSlots ${selectedTimeRange.includes('before6AM') ? 'selected' : ''}`}
                            onClick={() => handleTimeRangeClick('before6AM')}
                          >
                            <span
                              className="appendBottom2 checkBlockIcon"
                              style={{ backgroundImage: 'url(https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/morning_inactive.png?v=1)' }}
                            ></span>
                            <div className="boldFont">Before 6 AM</div>
                          </div>

                          <div
                            className={`appendBottom12 filterTimeSlots ${selectedTimeRange.includes('6AMto12PM') ? 'selected' : ''}`}
                            onClick={() => handleTimeRangeClick('6AMto12PM')}
                          >
                            <span
                              className="appendBottom2 checkBlockIcon"
                              style={{ backgroundImage: 'url(https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/noon_inactive.png?v=1)' }}
                            ></span>
                            <div className="boldFont">6 AM - 12 PM</div>
                          </div>

                          <div
                            className={`appendBottom12 filterTimeSlots ${selectedTimeRange.includes('12PMto6PM') ? 'selected' : ''}`}
                            onClick={() => handleTimeRangeClick('12PMto6PM')}
                          >
                            <span
                              className="appendBottom2 checkBlockIcon"
                              style={{ backgroundImage: 'url(https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/evening_inactive.png?v=1)' }}
                            ></span>
                            <div className="boldFont">12 PM - 6 PM</div>
                          </div>

                          <div
                            className={`appendBottom12 filterTimeSlots ${selectedTimeRange.includes('after6PM') ? 'selected' : ''}`}
                            onClick={() => handleTimeRangeClick('after6PM')}
                          >
                            <span
                              className="appendBottom2 checkBlockIcon"
                              style={{ backgroundImage: 'url(https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/night_inactive.png?v=1)' }}
                            ></span>
                            <div className="boldFont">After 6 PM</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="side-block fly-in">
                      <div className="side-padding">
                        <div className="side-lbl">Arrival at {inputDestination && (
                          inputDestination.includes(" - ")
                            ? inputDestination.split(" - ")[0].trim()
                            : inputDestination.includes("(")
                              ? inputDestination.split("(")[0].trim()
                              : inputDestination.trim()
                        )}</div>
                        <div className="timeSlotsOuter">
                          <div
                            className={`appendBottom12 filterTimeSlots ${selectedTimeRanges.includes('before6AM') ? 'selected' : ''}`}
                            onClick={() => handleTimeRangeClicks('before6AM')}
                          >
                            <span
                              className="appendBottom2 checkBlockIcon"
                              style={{ backgroundImage: 'url(https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/morning_inactive.png?v=1)' }}
                            ></span>
                            <div className="boldFont">Before 6 AM</div>
                          </div>

                          <div
                            className={`appendBottom12 filterTimeSlots ${selectedTimeRanges.includes('6AMto12PM') ? 'selected' : ''}`}
                            onClick={() => handleTimeRangeClicks('6AMto12PM')}
                          >
                            <span
                              className="appendBottom2 checkBlockIcon"
                              style={{ backgroundImage: 'url(https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/noon_inactive.png?v=1)' }}
                            ></span>
                            <div className="boldFont">6 AM - 12 PM</div>
                          </div>

                          <div
                            className={`appendBottom12 filterTimeSlots ${selectedTimeRanges.includes('12PMto6PM') ? 'selected' : ''}`}
                            onClick={() => handleTimeRangeClicks('12PMto6PM')}
                          >
                            <span
                              className="appendBottom2 checkBlockIcon"
                              style={{ backgroundImage: 'url(https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/evening_inactive.png?v=1)' }}
                            ></span>
                            <div className="boldFont">12 PM - 6 PM</div>
                          </div>

                          <div
                            className={`appendBottom12 filterTimeSlots ${selectedTimeRanges.includes('after6PM') ? 'selected' : ''}`}
                            onClick={() => handleTimeRangeClicks('after6PM')}
                          >
                            <span
                              className="appendBottom2 checkBlockIcon"
                              style={{ backgroundImage: 'url(https://imgak.mmtcdn.com/flights/assets/media/dt/listing/left-filters/night_inactive.png?v=1)' }}
                            ></span>
                            <div className="boldFont">After 6 PM</div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="side-block fly-in">
                      <div className="side-stars">
                      <div className="side-padding">
                        <div className="side-lbl">Airlines</div>

                        {SegmentList &&
                          SegmentList.length > 0 &&
                          [...new Set(SegmentList.map((SegmentListinfo) => SegmentListinfo['$']['Carrier']))].map(
                            (carrier, index) => (
                              <div className="checkbox" key={index}>
                                <input
                                  type="checkbox"
                                  id={`myCheck_${index}`}
                                  name="airline"
                                  value={carrier}
                                  style ={{width:'17px', height:'30px'}}
                                  onChange={() => handleAirlineCheckboxChange(carrier)}
                                  checked={selectedAirlines.includes(carrier)}
                                />
                                <label htmlFor={`myCheck_${index}`} className="airline-label">
                                  <img
                                    src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                    alt={`${carrier} logo`}
                                    className="airline-logo"
                                    onError={(e) => e.target.style.display = 'none'} // Hide broken images
                                  />
                                  {handleAirline(carrier)}
                                </label>
                              </div>
                            )
                          )}
                      </div>
                      </div>
                    </div>
                    {formData.bookingType === "Return" && (
                      <div className="side-block fly-in">
                        <div className="side-stars">
                          <div className="side-padding">
                            <div className="side-lbl">Airlines </div>

                            {SegmentList &&
                              SegmentList.length > 0 &&
                              [...new Set(SegmentList.map((SegmentListinfo) => SegmentListinfo['$']['Carrier']))].map(
                                (carrier, index) => (
                                  <React.Fragment key={index}>
                                    <div className="checkbox">
                                      <input
                                        type="checkbox"
                                        id={`myCheck_${index}`}
                                        name="airline"
                                        value={carrier}
                                        onChange={() => handlereturnAirlineCheckboxChange(carrier)}
                                        checked={selectedreturnAirlines.includes(carrier)}
                                      />
                                      <label htmlFor={`myCheck_${index}`} className="airline-label">
                                  <img
                                    src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                    alt={`${carrier} logo`}
                                    className="airline-logo"
                                    onError={(e) => e.target.style.display = 'none'} // Hide broken images
                                  />
                                  {handleAirline(carrier)}
                                </label>
                                      {/* <label htmlFor={`myCheck_${index}`}>{handleAirline(carrier)}</label> */}
                                    </div>
                                  </React.Fragment>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="side-block fly-in">
                      <div className="side-price">
                        <div className="price-ammounts">
                          <p className="price-ammountsp">
                            <label htmlFor="amount" className="side-lbl">
                              Price range:
                            </label>

                          </p>
                        </div>
                        <div className="price-ranger">
                          <Slider
                            min={minvalue}
                            max={maxvalue}
                            range
                            value={priceRange}
                            onChange={handlePriceChange}
                          // marks={{ [minvalue]: `${minvalue}`, [maxvalue]: `${maxvalue}` }}
                          />
                        </div>
                        <div className="price-ammounts">
                          <input type="text" id="ammount-from" value={`${priceRange[0]}`} readOnly />
                          <input type="text" id="ammount-to" value={`${priceRange[1]}`} readOnly />
                          <div className="clear" />
                        </div>
                      </div>
                    </div>
                    <div className="side-block fly-in">
                      <button className="scrolltotop" type="button" onClick={handleScrollToTop}>Scroll To Top</button>
                    </div>

                  </>
                }
              </div>

              {/* sidebar end */}
              <div className="two-colls-right">
                <div className="two-colls-right-b">
                  <div className="padding">
                    <div className="catalog-row" id="catalog">

                      {flightOptions && flightOptions.length > 0 ? (
                        <>
                          <div className='row catalog_filters'>

                            <div className='col-md-2 departurefilter' onClick={() => handleSortingCriterionClick('3')}
                              style={{ opacity: sortingCriterion === '3' ? '1' : '0.5' }}>
                              Departure {sortingCriterion === '3' && (sortDirection === 'asc' ? '↓' : '↑')}
                            </div>
                            <div className='col-md-3 travelfilter' onClick={() => handleSortingCriterionClick('2')}
                              style={{ opacity: sortingCriterion === '2' ? '1' : '0.5' }}>
                              Travel Time {sortingCriterion === '2' && (sortDirection === 'asc' ? '↓' : '↑')}
                            </div>
                            <div className='col-md-2 arriavelfilter' onClick={() => handleSortingCriterionClick('4')}
                              style={{ opacity: sortingCriterion === '4' ? '1' : '0.5' }}>
                              Arrival {sortingCriterion === '4' && (sortDirection === 'asc' ? '↓' : '↑')}
                            </div>

                            <div className='col-md-2 stopsfilter' onClick={() => handleSortingCriterionClick('5')}
                              style={{ opacity: sortingCriterion === '5' ? '1' : '0.5' }}>
                              Stops {sortingCriterion === '5' && (sortDirection === 'asc' ? '↓' : '↑')}
                            </div>
                            <div
                              className="col-md-3 pricefilter"
                              onClick={() => handleSortingCriterionClick('1')}
                              style={{ opacity: sortingCriterion === '1' ? '1' : '0.5' }}
                            >
                              Price {sortingCriterion === '1' && (sortDirection === 'asc' ? '↓' : '↑')}
                            </div>
                          </div>

                          {filteredFlights.length > 0 ? (
                            filteredFlights.map(pricepoint => {
                              pricepoint.price = parseFloat(pricepoint.$.TotalPrice.replace(/[^\d.]/g, ''));
                              let result = {};
                              pricepoint['air:AirPricingInfo'] && (
                                Array.isArray(pricepoint['air:AirPricingInfo'])
                                  ? (
                                    <>
                                      {
                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0] &&
                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                            ? (
                                              <>
                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                  ? (

                                                    <>
                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                        <div key={bookingindex}>
                                                          {bookingInfo['$'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                              ) {
                                                                result.travelTime = segmentinfo['$']['DepartureTime'];
                                                                result.return = segmentinfo['$']['ArrivalTime'];
                                                                result.departure = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['$']['TravelTime'];
                                                                result.stops = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].length - 1;
                                                              }
                                                              return result;

                                                            })
                                                          }


                                                        </div>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                          if (
                                                            segmentinfo['$'] &&
                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                          ) {
                                                            result.travelTime = segmentinfo['$']['DepartureTime'];
                                                            result.return = segmentinfo['$']['ArrivalTime'];
                                                            result.departure = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['$']['TravelTime'];
                                                            result.stops = 0;
                                                          }
                                                          return result;



                                                        })
                                                      }


                                                    </>
                                                  )
                                                }
                                              </>
                                            ) : (
                                              <>
                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                  ? (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                        <div key={bookingindex}>
                                                          {bookingInfo['$'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                result.travelTime = segmentinfo['$']['DepartureTime'];
                                                                result.return = segmentinfo['$']['ArrivalTime'];
                                                                result.departure = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['$']['TravelTime'];
                                                                result.stops = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].length - 1;
                                                              }
                                                              return result;


                                                            })
                                                          }


                                                        </div>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                          if (
                                                            segmentinfo['$'] &&
                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                            result.travelTime = segmentinfo['$']['DepartureTime'];
                                                            result.return = segmentinfo['$']['ArrivalTime'];
                                                            result.departure = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['$']['TravelTime'];
                                                            result.stops = 0;
                                                          }
                                                          return result;


                                                        })
                                                      }
                                                    </>
                                                  )
                                                }
                                              </>
                                            )
                                        )
                                      }

                                    </>
                                  ) : (
                                    <>
                                      {
                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0] &&
                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                            ? (
                                              <>
                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                  ? (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                        <div key={bookingindex}>
                                                          {bookingInfo['$'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                result.travelTime = segmentinfo['$']['DepartureTime'];
                                                                result.return = segmentinfo['$']['ArrivalTime'];
                                                                result.departure = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['$']['TravelTime'];
                                                                result.stops = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].length - 1;
                                                              }
                                                              return result;

                                                            })
                                                          }


                                                        </div>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                          if (
                                                            segmentinfo['$'] &&
                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']) {
                                                            result.travelTime = segmentinfo['$']['DepartureTime'];
                                                            result.return = segmentinfo['$']['ArrivalTime'];
                                                            result.departure = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['$']['TravelTime'];
                                                            result.stops = 0;
                                                          }
                                                          return result;



                                                        })
                                                      }


                                                    </>
                                                  )
                                                }
                                              </>
                                            ) : (
                                              <>
                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                  ? (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                        <div key={bookingindex}>
                                                          {bookingInfo['$'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                result.travelTime = segmentinfo['$']['DepartureTime'];
                                                                result.return = segmentinfo['$']['ArrivalTime'];
                                                                result.departure = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['$']['TravelTime'];
                                                                result.stops = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].length - 1;
                                                              }
                                                              return result;

                                                            })
                                                          }


                                                        </div>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                          if (
                                                            segmentinfo['$'] &&
                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                            result.travelTime = segmentinfo['$']['DepartureTime'];
                                                            result.return = segmentinfo['$']['ArrivalTime'];
                                                            result.departure = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['$']['TravelTime'];
                                                            result.stops = 0;
                                                          }
                                                          return result;



                                                        })
                                                      }
                                                    </>
                                                  )
                                                }
                                              </>
                                            )
                                        )
                                      }

                                    </>
                                  )
                              )

                              pricepoint['air:AirPricingInfo'] && (
                                Array.isArray(pricepoint['air:AirPricingInfo'])
                                  ? (
                                    <>
                                      {

                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                            ? (
                                              <>

                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                  ? (

                                                    <>
                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                        <div key={bookingindex}>
                                                          {bookingInfo['$'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                              ) {
                                                                result.travelTime = segmentinfo['$']['DepartureTime'];
                                                                result.return = segmentinfo['$']['ArrivalTime'];
                                                                result.departure = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['$']['TravelTime'];
                                                                result.stops = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].length - 1;
                                                              }
                                                              return result;

                                                            })
                                                          }


                                                        </div>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                          if (
                                                            segmentinfo['$'] &&
                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                          ) {
                                                            result.travelTime = segmentinfo['$']['DepartureTime'];
                                                            result.return = segmentinfo['$']['ArrivalTime'];
                                                            result.departure = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['$']['TravelTime'];
                                                            result.stops = 0;
                                                          }
                                                          return result;



                                                        })
                                                      }


                                                    </>
                                                  )
                                                }
                                              </>
                                            ) : (
                                              <>
                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                  ? (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                        <div key={bookingindex}>
                                                          {bookingInfo['$'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                result.travelTime = segmentinfo['$']['DepartureTime'];
                                                                result.return = segmentinfo['$']['ArrivalTime'];
                                                                result.departure = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['$']['TravelTime'];
                                                                result.stops = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1;
                                                              }
                                                              return result;


                                                            })
                                                          }


                                                        </div>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                          if (
                                                            segmentinfo['$'] &&
                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                            result.travelTime = segmentinfo['$']['DepartureTime'];
                                                            result.return = segmentinfo['$']['ArrivalTime'];
                                                            result.departure = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['$']['TravelTime'];
                                                            result.stops = 0;
                                                          }
                                                          return result;


                                                        })
                                                      }
                                                    </>
                                                  )
                                                }
                                              </>
                                            )
                                        )
                                      }

                                    </>
                                  ) : (
                                    <>
                                      {
                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                            ? (
                                              <>
                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                  ? (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                        <div key={bookingindex}>
                                                          {bookingInfo['$'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                result.travelTime = segmentinfo['$']['DepartureTime'];
                                                                result.return = segmentinfo['$']['ArrivalTime'];
                                                                result.departure = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['$']['TravelTime'];
                                                                result.stops = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].length - 1;
                                                              }
                                                              return result;

                                                            })
                                                          }


                                                        </div>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                          if (
                                                            segmentinfo['$'] &&
                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']) {
                                                            result.travelTime = segmentinfo['$']['DepartureTime'];
                                                            result.return = segmentinfo['$']['ArrivalTime'];
                                                            result.departure = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['$']['TravelTime'];
                                                            result.stops = 0;
                                                          }
                                                          return result;



                                                        })
                                                      }


                                                    </>
                                                  )
                                                }
                                              </>
                                            ) : (
                                              <>
                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                  ? (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                        <div key={bookingindex}>
                                                          {bookingInfo['$'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                result.travelTime = segmentinfo['$']['DepartureTime'];
                                                                result.return = segmentinfo['$']['ArrivalTime'];
                                                                result.departure = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['$']['TravelTime'];
                                                                result.stops = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1;

                                                              }
                                                              return result;

                                                            })
                                                          }


                                                        </div>
                                                      ))}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                          if (
                                                            segmentinfo['$'] &&
                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                            result.travelTime = segmentinfo['$']['DepartureTime'];
                                                            result.return = segmentinfo['$']['ArrivalTime'];
                                                            result.departure = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['$']['TravelTime'];
                                                            result.stops = 0;
                                                          }
                                                          return result;



                                                        })
                                                      }
                                                    </>
                                                  )
                                                }
                                              </>
                                            )
                                        )
                                      }

                                    </>
                                  )
                              )

                              pricepoint.travelTime = calculateTravelTime(result.departure);
                              pricepoint.departure = calculateDepartureTime(result.travelTime);
                              pricepoint.return = calculateDepartureTime(result.return);
                              pricepoint.stops = result.stops;
                              {/* console.log('test', pricepoint); */ }
                              return pricepoint;

                            })
                              .sort((a, b) => {
                                switch (sortingCriterion) {
                                  case '1': // Sorting by price
                                    return sortDirection === 'asc' ? a.price - b.price : b.price - a.price;

                                    {/* case '1':
                                  return a.price - b.price; */}
                                  case '2':
                                    {/* return a.travelTime - b.travelTime; */ }
                                    return sortDirection === 'asc' ? a.travelTime - b.travelTime : b.travelTime - a.travelTime;
                                  case '3':
                                    const [aHours] = a.departure.split(':').map(Number);
                                    const [bHours] = b.departure.split(':').map(Number);


                                    return sortDirection === 'asc' ? aHours - bHours : bHours - aHours;
                                  case '4':
                                    const [arHours] = a.return.split(':').map(Number);
                                    const [brHours] = b.return.split(':').map(Number);

                                    return sortDirection === 'asc' ? arHours - brHours : brHours - arHours;
                                  case '5':
                                    return sortDirection === 'asc' ? a.stops - b.stops : b.stops - a.stops;
                                  default:
                                    {/* return 0; */ }
                                }
                              }).map((pricepoint, priceindex) => {
                                {/* console.log('price', pricepoint); */ }
                                const totalPrice = parseFloat(pricepoint.$.TotalPrice.replace(/[^\d.]/g, ''));
                                {/* console.log('totalPrice',totalPrice); */ }
                                let result = {};
                                {
                                  pricepoint['air:AirPricingInfo'] && (
                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                      ? (
                                        <>
                                          {
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0] &&
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                ? (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                  ) {
                                                                    result.carrier = segmentinfo['$']['Carrier'];
                                                                    result.stop = bookingindex;
                                                                  }
                                                                  return result;
                                                                })
                                                              }
                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                              ) {
                                                                result.carrier = segmentinfo['$']['Carrier'];
                                                                result.stop = 0;
                                                              }
                                                              return result;
                                                            })
                                                          }
                                                        </>
                                                      )
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.carrier = segmentinfo['$']['Carrier'];
                                                                    result.stop = bookingindex;
                                                                  }
                                                                  return result;


                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.carrier = segmentinfo['$']['Carrier'];
                                                                result.stop = 0;
                                                              }
                                                              return result;


                                                            })
                                                          }
                                                        </>
                                                      )
                                                    }
                                                  </>
                                                )
                                            )
                                          }

                                        </>
                                      ) : (
                                        <>
                                          {
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0] &&
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                ? (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.carrier = segmentinfo['$']['Carrier'];
                                                                    result.stop = bookingindex;
                                                                  }
                                                                  return result;

                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.carrier = segmentinfo['$']['Carrier'];
                                                                result.stop = 0;
                                                              }
                                                              return result;



                                                            })
                                                          }


                                                        </>
                                                      )
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.carrier = segmentinfo['$']['Carrier'];
                                                                    result.stop = bookingindex;
                                                                  }
                                                                  return result;

                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.carrier = segmentinfo['$']['Carrier'];
                                                                result.stop = 0;
                                                              }
                                                              return result;



                                                            })
                                                          }
                                                        </>
                                                      )
                                                    }
                                                  </>
                                                )
                                            )
                                          }

                                        </>
                                      )
                                  )
                                }
                                {
                                  pricepoint['air:AirPricingInfo'] && (
                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                      ? (
                                        <>
                                          {
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1] &&
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                ? (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                      ? (

                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                  ) {
                                                                    result.returncarrier = segmentinfo['$']['Carrier'];
                                                                    result.returnstop = bookingindex;
                                                                  }
                                                                  return result;

                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                              ) {
                                                                result.returncarrier = segmentinfo['$']['Carrier'];
                                                                result.returnstop = 0;
                                                              }
                                                              return result;



                                                            })
                                                          }


                                                        </>
                                                      )
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.returncarrier = segmentinfo['$']['Carrier'];
                                                                    result.returnstop = bookingindex;
                                                                  }
                                                                  return result;


                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.returncarrier = segmentinfo['$']['Carrier'];
                                                                result.returnstop = 0;
                                                              }
                                                              return result;


                                                            })
                                                          }
                                                        </>
                                                      )
                                                    }
                                                  </>
                                                )
                                            )
                                          }

                                        </>
                                      ) : (
                                        <>
                                          {
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1] &&
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                ? (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.returncarrier = segmentinfo['$']['Carrier'];
                                                                    result.returnstop = bookingindex;
                                                                  }
                                                                  return result;

                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.returncarrier = segmentinfo['$']['Carrier'];
                                                                result.returnstop = 0;
                                                              }
                                                              return result;



                                                            })
                                                          }


                                                        </>
                                                      )
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.returncarrier = segmentinfo['$']['Carrier'];
                                                                    result.returnstop = bookingindex;
                                                                  }
                                                                  return result;

                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.returncarrier = segmentinfo['$']['Carrier'];
                                                                result.returnstop = 0;
                                                              }
                                                              return result;



                                                            })
                                                          }
                                                        </>
                                                      )
                                                    }
                                                  </>
                                                )
                                            )
                                          }

                                        </>
                                      )
                                  )
                                }
                                {
                                  pricepoint['air:AirPricingInfo'] && (
                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                      ? (
                                        <>
                                          {
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                ? (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                      ? (

                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                  ) {
                                                                    result.carrier = segmentinfo['$']['Carrier'];
                                                                    result.stop = bookingindex;
                                                                  }
                                                                  return result;

                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                              ) {
                                                                result.carrier = segmentinfo['$']['Carrier'];
                                                                result.stop = 0;
                                                              }
                                                              return result;



                                                            })
                                                          }


                                                        </>
                                                      )
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.carrier = segmentinfo['$']['Carrier'];
                                                                    result.stop = bookingindex;
                                                                  }
                                                                  return result;


                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.carrier = segmentinfo['$']['Carrier'];
                                                                result.stop = 0;
                                                              }
                                                              return result;


                                                            })
                                                          }
                                                        </>
                                                      )
                                                    }
                                                  </>
                                                )
                                            )
                                          }

                                        </>
                                      ) : (
                                        <>
                                          {
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                ? (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.carrier = segmentinfo['$']['Carrier'];
                                                                    result.stop = bookingindex;
                                                                  }
                                                                  return result;

                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.carrier = segmentinfo['$']['Carrier'];
                                                                result.stop = 0;
                                                              }
                                                              return result;



                                                            })
                                                          }


                                                        </>
                                                      )
                                                    }
                                                  </>
                                                ) : (
                                                  <>
                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                      ? (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                            <div key={bookingindex}>
                                                              {bookingInfo['$'] &&
                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                  if (
                                                                    segmentinfo['$'] &&
                                                                    segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                    result.carrier = segmentinfo['$']['Carrier'];
                                                                    result.stop = bookingindex;
                                                                  }
                                                                  return result;

                                                                })
                                                              }


                                                            </div>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                              if (
                                                                segmentinfo['$'] &&
                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']) {
                                                                result.carrier = segmentinfo['$']['Carrier'];
                                                                result.stop = 0;
                                                              }
                                                              return result;



                                                            })
                                                          }
                                                        </>
                                                      )
                                                    }
                                                  </>
                                                )
                                            )
                                          }

                                        </>
                                      )
                                  )
                                }

                                const departuretimeCheck = selectedTimeRange.length === 0 || (() => {
                                  const bookingInfo = pricepoint['air:AirPricingInfo']
                                    ?.['air:FlightOptionsList']
                                    ?.['air:FlightOption']
                                    ?.['air:Option']
                                    ?.['air:BookingInfo'];

                                  if (!bookingInfo) return false;

                                  // Get the SegmentRef (handle both array and single object cases)
                                  const firstSegmentRef = Array.isArray(bookingInfo)
                                    ? bookingInfo[0]?.['$']?.['SegmentRef'] // First segment for connecting flights
                                    : bookingInfo?.['$']?.['SegmentRef']; // Direct flight case

                                  if (!firstSegmentRef) return false;

                                  // Find the matching segment in SegmentList
                                  const firstSegment = SegmentList.find(segment => segment['$']?.['Key'] === firstSegmentRef);

                                  if (!firstSegment) return false;

                                  // Extract departure time in ISO format
                                  const departureTimeISO = firstSegment['$']['DepartureTime'];
                                  if (!departureTimeISO) return false;

                                  // Convert ISO string to Date object
                                  const departureDate = new Date(departureTimeISO);

                                  if (isNaN(departureDate.getTime())) {
                                    console.error("Invalid date extracted from DepartureTime:", departureTimeISO);
                                    return false;
                                  }

                                  // Extract hour in local time
                                  const hour = departureDate.getHours();

                                  // Check if the departure time matches any selected range
                                  return selectedTimeRange.some((range) => {
                                    switch (range) {
                                      case 'before6AM': return hour < 6;
                                      case '6AMto12PM': return hour >= 6 && hour < 12;
                                      case '12PMto6PM': return hour >= 12 && hour < 18;
                                      case 'after6PM': return hour >= 18;
                                      default: return false;
                                    }
                                  });
                                })();


                                const arrivaltimeCheck = selectedTimeRanges.length === 0 || (() => {
                                  const [hour] = pricepoint['return'].split(':').map(Number); // Extract hour as a number

                                  return selectedTimeRanges.some((range) => {
                                    switch (range) {
                                      case 'before6AM':
                                        return hour < 6;
                                      case '6AMto12PM':
                                        return hour >= 6 && hour < 12;
                                      case '12PMto6PM':
                                        return hour >= 12 && hour < 18;
                                      case 'after6PM':
                                        return hour >= 18;
                                      default:
                                        return false;
                                    }
                                  });
                                })();

                                const airlineCheck = selectedAirlines.length === 0 || selectedAirlines.includes(result['carrier']);
                                const airlinereturnCheck = selectedreturnAirlines.length === 0 || selectedreturnAirlines.includes(result['returncarrier']);
                                const stopsCheck = selectedStops.length === 0 || selectedStops.includes(result['stop']);
                                const stopsreturnCheck = selectedreturnStops.length === 0 || selectedreturnStops.includes(result['returnstop']);
                                if (airlineCheck && airlinereturnCheck && stopsCheck && stopsreturnCheck && arrivaltimeCheck && departuretimeCheck) {
                                  if (totalPrice >= priceRange[0] && totalPrice <= priceRange[1]) {
                                    return (
                                      <React.Fragment key={priceindex}>
                                        <form onSubmit={(e) => handlePriceSubmit(e, priceindex)}>
                                          <div key={priceindex}>
                                            {pricepoint && pricepoint['air:AirPricingInfo'] && (
                                              (pricepoint['air:AirPricingInfo'][0]?.['air:FlightOptionsList']?.['air:FlightOption'] && Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'])) ||
                                              (pricepoint['air:AirPricingInfo']?.['air:FlightOptionsList']?.['air:FlightOption'] && Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']))
                                            ) ? (
                                              <div>

                                                <div
                                                  className={`flight-item fly-in ${selectedFlights.some(
                                                    (selectedFlight) =>
                                                      selectedFlight["$"].Key === pricepoint["air:AirPricingInfo"]["$"].Key &&
                                                      selectedFlight.isReturn === pricepoint["air:AirPricingInfo"].isReturn
                                                  )
                                                    ? "selected-flight"
                                                    : ""
                                                    }`}
                                                  data-price={6521}
                                                  data-departure={1}
                                                  data-duration={1}
                                                  data-category="myCheckAI checkclassEconomy"
                                                  id="textAI"
                                                  data-category1="stop0"
                                                  style={{ display: "block" }}

                                                >
                                                  <div className="flt-i-a">
                                                    <div className="flt-i-b">
                                                      <div className="flt-i-bb">
                                                        <div className="flt-l-a">
                                                          <div className="flt-l-b">
                                                            <div className="way-lbl">departure</div>
                                                            <div className="company">
                                                              {pricepoint['air:AirPricingInfo'] && (
                                                                Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                  ? (
                                                                    <div style={{ width: '95%' }}>
                                                                      {
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                                            ? (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                                                  ? (

                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers1 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex}>
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers1.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}
                                                                                            </div>

                                                                                          )),
                                                                                          Array.from(uniqueCarriers1).map((carrier, index) => (
                                                                                            <div key={index}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br />
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}

                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']

                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"
                                                                                                /><br />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );
                                                                                          }
                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            ) : (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers2 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex}>
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers2.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}
                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers2).map((carrier, index) => (
                                                                                            <div key={index}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br />
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"
                                                                                                /><br />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  ,{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );
                                                                                          }
                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            )
                                                                        )
                                                                      }
                                                                    </div>
                                                                  ) : (
                                                                    <div style={{ width: '95%' }}>
                                                                      {
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                                            ? (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers3 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex} >

                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers3.add(segmentinfo['$']['Carrier']);
                                                                                                }
                                                                                              })}

                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers3).map((carrier, index) => (
                                                                                            <div key={index}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br />
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"
                                                                                                /><br />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );
                                                                                          }
                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            ) : (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers4 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex}>
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers4.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}
                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers4).map((carrier, index) => (
                                                                                            <div key={index}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br/>
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>
                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"
                                                                                                /><br />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );

                                                                                          }
                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            )
                                                                        )
                                                                      }

                                                                    </div>
                                                                  )
                                                              )
                                                              }

                                                              {/* <button
                                                                      type="button"
                                                                      style={{
                                                                        fontSize: '12px',
                                                                        color: 'blue',
                                                                        marginTop: '10px',
                                                                        marginLeft: '10%',
                                                                      }}
                                                                      className={`add-share-btn ${isFlightSelected ? "added" : ""}`}
                                                                      onClick={() => handleCheckboxChange(pricepoint["air:AirPricingInfo"])}
                                                                    >
                                                                      {isFlightSelected ? "Added - " : "Add to Share + "}
                                                                    </button> */}

                                                            </div>
                                                          </div>
                                                          <div className="flt-l-c">
                                                            <div className="flt-l-cb">
                                                              <div className="flt-l-c-padding">
                                                                <div className="flyght-info-head">
                                                                  <input type="hidden" name="SearchPriceTotalPrice" value={pricepoint['$']['TotalPrice']} />
                                                                  {pricepoint['air:AirPricingInfo'] && (
                                                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                      ? (
                                                                        <div>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                                                ? (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['0'] &&


                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {
                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })

                                                                                          }


                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }


                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                ) : (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>


                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['0'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                )
                                                                            )
                                                                          }
                                                                        </div>
                                                                      ) : (
                                                                        <div>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                                                ? (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['0'] &&


                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {
                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })

                                                                                          }
                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }


                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                ) : (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['0'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])

                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                )
                                                                            )
                                                                          }
                                                                        </div>
                                                                      )
                                                                  )
                                                                  }
                                                                </div>
                                                                {/* // */}
                                                                <div className="flight-line">
                                                                  {pricepoint['air:AirPricingInfo'] && (
                                                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                      ? (
                                                                        <>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                                                ? (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Origin'])} <br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                width="45px"
                                                                                              >
                                                                                                <span>
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['$']['TravelTime'])}
                                                                                                </span>
                                                                                              </div>
                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Destination'])} <br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          </span>
                                                                                          <div className="flight-line-a" style={{ marginLeft: 10 }}>
                                                                                            {(() => {
                                                                                              let totalIterations = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].length - 1;
                                                                                              let backgroundColor = totalIterations === 1 ? "#785eff" : "red";


                                                                                              return (
                                                                                                <p className="iteration0" style={{ color: "#fff", fontWeight: '600', textAlign: "center", border: "1px solid #cbfac8", width: 70, backgroundColor: backgroundColor, padding: 4, borderRadius: 15, fontSize: 8 }}>
                                                                                                  {totalIterations} STOP
                                                                                                </p>
                                                                                              );
                                                                                            })()}
                                                                                          </div>
                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"
                                                                                                          width="45px"
                                                                                                        >
                                                                                                          <span>
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                        </div>
                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Destination'])}  <br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                             </span>
                                                                                                      </div>
                                                                                                    </span>
                                                                                                    <div className="flight-line-a" style={{ marginLeft: 10 }}>
                                                                                                      {(() => {
                                                                                                        let totalIterations = 0;

                                                                                                        return (
                                                                                                          <p className="iteration0" style={{ textAlign: "center", border: "1px solid #cbfac8", width: 70, backgroundColor: "#cbfac8", color: "black", padding: 4, borderRadius: 15, fontSize: 8 }}>
                                                                                                            NON STOP
                                                                                                          </p>
                                                                                                        );
                                                                                                      })()}
                                                                                                    </div>
                                                                                                  </span>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span></span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                width="45px"
                                                                                              >
                                                                                                <span>
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['$']['TravelTime'])}
                                                                                                </span>
                                                                                              </div>
                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          </span>
                                                                                          <div className="flight-line-a" style={{ marginLeft: 10 }}>
                                                                                            {(() => {
                                                                                              let totalIterations = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].length - 1;
                                                                                              let backgroundColor = totalIterations === 1 ? "#785eff" : "red";
                                                                                              return (
                                                                                                <p className="iteration0" style={{ color: "#fff", fontWeight: '600', textAlign: "center", border: "1px solid #cbfac8", width: 70, backgroundColor: backgroundColor, padding: 4, borderRadius: 15, fontSize: 8 }}>
                                                                                                  {totalIterations} STOP
                                                                                                </p>
                                                                                              );
                                                                                            })()}
                                                                                          </div>
                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"
                                                                                                          width="45px"
                                                                                                        >
                                                                                                          <span>
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                        </div>
                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Destination'])}     <br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                          </span>
                                                                                                      </div>
                                                                                                    </span>
                                                                                                    <div className="flight-line-a" style={{ marginLeft: 10 }}>
                                                                                                      {(() => {
                                                                                                        let totalIterations = 0;

                                                                                                        return (
                                                                                                          <p className="iteration0" style={{ textAlign: "center", border: "1px solid #cbfac8", width: 70, backgroundColor: "#cbfac8", color: "black", padding: 4, borderRadius: 15, fontSize: 8 }}>
                                                                                                            NON STOP
                                                                                                          </p>
                                                                                                        );
                                                                                                      })()}
                                                                                                    </div>
                                                                                                  </span>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                )
                                                                            )
                                                                          }

                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                                                ? (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                width="45px"
                                                                                              >
                                                                                                <span>
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['$']['TravelTime'])}
                                                                                                </span>
                                                                                              </div>
                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          </span>
                                                                                          <div className="flight-line-a" style={{ marginLeft: 10 }}>
                                                                                            {(() => {
                                                                                              let totalIterations = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].length - 1;
                                                                                              let backgroundColor = totalIterations === 1 ? "#785eff" : "red";
                                                                                              return (
                                                                                                <p className="iteration0" style={{ color: "#fff", fontWeight: '600', textAlign: "center", border: "1px solid #cbfac8", width: 70, backgroundColor: backgroundColor, padding: 4, borderRadius: 15, fontSize: 8 }}>
                                                                                                  {totalIterations} STOP
                                                                                                </p>
                                                                                              );
                                                                                            })()}
                                                                                          </div>
                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"
                                                                                                          width="45px"
                                                                                                        >
                                                                                                          <span>

                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                        </div>
                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Destination'])}        <br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                       </span>
                                                                                                      </div>
                                                                                                    </span>
                                                                                                    <div className="flight-line-a" style={{ marginLeft: 10 }}>
                                                                                                      {(() => {
                                                                                                        let totalIterations = 0;
                                                                                                        return (
                                                                                                          <p className="iteration0" style={{ textAlign: "center", border: "1px solid #cbfac8", width: 70, backgroundColor: "#cbfac8", color: "black", padding: 4, borderRadius: 15, fontSize: 8 }}>
                                                                                                            NON STOP
                                                                                                          </p>
                                                                                                        );
                                                                                                      })()}
                                                                                                    </div>
                                                                                                  </span>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                width="45px"
                                                                                              >
                                                                                                <span>
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['$']['TravelTime'])}
                                                                                                </span>
                                                                                              </div>
                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                          </span>
                                                                                          <div className="flight-line-a" style={{ marginLeft: 10 }}>
                                                                                            {(() => {
                                                                                              let totalIterations = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].length - 1;
                                                                                              let backgroundColor = totalIterations === 1 ? "#785eff" : "red";
                                                                                              return (
                                                                                                <p className="iteration0" style={{ color: "#fff", fontWeight: '600', textAlign: "center", border: "1px solid #cbfac8", width: 70, backgroundColor: backgroundColor, padding: 4, borderRadius: 15, fontSize: 8 }}>
                                                                                                  {totalIterations} STOP
                                                                                                </p>
                                                                                              );
                                                                                            })()}
                                                                                          </div>
                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"
                                                                                                          width="45px"
                                                                                                        >
                                                                                                          <span>
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                        </div>
                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                               </span>
                                                                                                      </div>
                                                                                                    </span>
                                                                                                    <div className="flight-line-a" style={{ marginLeft: 10 }}>
                                                                                                      {(() => {
                                                                                                        let totalIterations = 0;

                                                                                                        return (
                                                                                                          <p className="iteration0" style={{ textAlign: "center", border: "1px solid #cbfac8", width: 70, backgroundColor: "#cbfac8", color: "black", padding: 4, borderRadius: 15, fontSize: 8 }}>
                                                                                                            NON STOP
                                                                                                          </p>
                                                                                                        );
                                                                                                      })()}
                                                                                                    </div>

                                                                                                  </span>
                                                                                                );

                                                                                              }


                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                )
                                                                            )
                                                                          }

                                                                        </>
                                                                      )
                                                                  )
                                                                  }

                                                                  <div className="flight-line-b">
                                                                    <b onClick={() => handleFlightDetails(priceindex)}>flight details3</b>
                                                                  </div>
                                                                  <div className="clear" />
                                                                  {/* // details // */}




                                                                  <div className="flight-details" style={{ display: visibleFlightIndex === priceindex ? 'block' : 'none' }}>
                                                                    <Nav justify variant="tabs" className="flight_nav" defaultActiveKey={`Flight_Details${priceindex}`} activeKey={activeTab} onSelect={setActiveTab} style={{ border: 'none' }}>

                                                                    </Nav>
                                                                    {pricepoint['air:AirPricingInfo'] && (
                                                                      Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                        ? (
                                                                          <div>
                                                                            <div id={`Flight_Detail${priceindex}`} className="tabcontent" style={activeTab.includes(`Flight_Detail${priceindex}`) ? { display: 'block' } : { display: 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                                                    ? (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d">
                                                                                                                <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp; {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;
                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                                }

                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>



                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}
                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])

                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])

                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}


                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp; {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }

                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}
                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );
                                                                                                  }


                                                                                                })
                                                                                              }


                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d"><input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} /></div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp; {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                                }
                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d"><input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} /></div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }
                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );

                                                                                                  }


                                                                                                })
                                                                                              }
                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    )
                                                                                )
                                                                              }
                                                                            </div>
                                                                            <div id={`Fare_Summary${priceindex}`} className="tabcontent" style={{ display: activeTab === `Fare_Summary${priceindex}` ? 'block' : 'none' }}>

                                                                              <div className="flight-details-a">
                                                                                Fare Breakup (For All Passengers)
                                                                              </div>

                                                                              <div className="flight-details-l">
                                                                                <div className="flight-details-b">
                                                                                  Total Fare
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  Base Price
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  Surcharge
                                                                                </div>
                                                                              </div>
                                                                              <div className="flight-details-r">
                                                                                <div className="flight-details-b">
                                                                                  {pricepoint.$.TotalPrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.TotalPrice.replace('INR', '')}
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.BasePrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.BasePrice.replace('INR', '')}
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.Taxes.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.Taxes.replace('INR', '')}
                                                                                </div>
                                                                              </div>
                                                                              <div className="clear" />
                                                                            </div>
                                                                            <div id={`Date_Change${priceindex}`} className="tabcontent" style={{ display: activeTab === `Date_Change${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    || {inputOrigin && inputOrigin.split('(')[0]} to {inputDestination && inputDestination.split('(')[0]} , &nbsp;
                                                                                    {
                                                                                      handledatemonthsplit(request.searchdeparture.split('/'))
                                                                                    }
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee+Fare diff.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Amount'].replace('INR', '')} Fare diff.
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline
                                                                                      fee provided is indicative.
                                                                                      CoTrav does not guarantee the
                                                                                      exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                            <div id={`Cancellation${priceindex}`} className="tabcontent" style={{ display: activeTab === `Cancellation${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    || {inputOrigin && inputOrigin.split('(')[0]} to {inputDestination && inputDestination.split('(')[0]} , &nbsp;
                                                                                    {
                                                                                      handledatemonthsplit(request.searchdeparture.split('/'))
                                                                                    }
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee+Fare diff.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Amount'].replace('INR', '')} Fare diff.
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline
                                                                                      fee provided is indicative.
                                                                                      CoTrav does not guarantee the
                                                                                      exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        ) : (
                                                                          <div>
                                                                            <div id={`Flight_Detail${priceindex}`} className="tabcontent" style={activeTab.includes(`Flight_Detail${priceindex}`) ? { display: 'block' } : { display: 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'] && (
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'])
                                                                                    ? (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d"><input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} /></div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])

                                                                                                                }

                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}


                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option'][0]['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d"><input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} /></div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }
                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );
                                                                                                  }


                                                                                                })
                                                                                              }


                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d"><input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} /></div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                                }

                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>

                                                                                              {

                                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][0]['air:Option']['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d"><input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} /></div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }

                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );

                                                                                                  }


                                                                                                })
                                                                                              }
                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    )
                                                                                )
                                                                              }
                                                                            </div>
                                                                            <div id={`Fare_Summary${priceindex}`} className="tabcontent" style={{ display: activeTab === `Fare_Summary${priceindex}` ? 'block' : 'none' }}>

                                                                              <div className="flight-details-a">
                                                                                Fare Breakup (For All Passengers)
                                                                              </div>

                                                                              <div className="flight-details-l">
                                                                                <div className="flight-details-b">
                                                                                  Total Fare
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  Base Price
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  Surcharge
                                                                                </div>
                                                                              </div>
                                                                              <div className="flight-details-r">
                                                                                <div className="flight-details-b">
                                                                                  {pricepoint.$.TotalPrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.TotalPrice.replace('INR', '')}
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.BasePrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.BasePrice.replace('INR', '')}
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.Taxes.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.Taxes.replace('INR', '')}
                                                                                </div>
                                                                              </div>
                                                                              <div className="clear" />
                                                                            </div>
                                                                            <div id={`Date_Change${priceindex}`} className="tabcontent" style={{ display: activeTab === `Date_Change${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:ChangePenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    || {inputOrigin && inputOrigin.split('(')[0]} to {inputDestination && inputDestination.split('(')[0]} , &nbsp;
                                                                                    {
                                                                                      handledatemonthsplit(request.searchdeparture.split('/'))

                                                                                    }
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee+Fare diff.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                          {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Amount'].replace('INR', '')} Fare diff.
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline
                                                                                      fee provided is indicative.
                                                                                      CoTrav does not guarantee the
                                                                                      exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                            <div id={`Cancellation${priceindex}`} className="tabcontent" style={{ display: activeTab === `Cancellation${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:CancelPenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    || {inputOrigin && inputOrigin.split('(')[0]} to {inputDestination && inputDestination.split('(')[0]} , &nbsp;
                                                                                    {
                                                                                      handledatemonthsplit(request.searchdeparture.split('/'))
                                                                                    }
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee+Fare diff.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                          {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Amount'].replace('INR', '')} Fare diff.
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline
                                                                                      fee provided is indicative.
                                                                                      CoTrav does not guarantee the
                                                                                      exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        )
                                                                    )

                                                                    }
                                                                  </div>



                                                                </div>
                                                              </div>
                                                            </div>
                                                            <br className="clear" />
                                                          </div>
                                                        </div>
                                                        <div className="clear" />
                                                      </div>
                                                      <div className="flt-i-bb flight-return">
                                                        <div className="flt-l-a">
                                                          <div className="flt-l-b">
                                                            <div className="way-lbl">return</div>
                                                            <div className="company">
                                                              {pricepoint['air:AirPricingInfo'] && (
                                                                Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                  ? (
                                                                    <div style={{ width: '95%' }}>
                                                                      {
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                                            ? (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                                                  ? (

                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers5 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex} >
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers5.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}

                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers5).map((carrier, index) => (
                                                                                            <div key={index}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br/>
                                                                                              <br/>
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"
                                                                                                /><br />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );
                                                                                          }


                                                                                        })
                                                                                      }


                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            ) : (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers6 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex}>
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers6.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}


                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers6).map((carrier, index) => (
                                                                                            <div key={index}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br/>
                                                                                              <br/>
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}

                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"
                                                                                                /><br />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );

                                                                                          }


                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            )
                                                                        )
                                                                      }

                                                                    </div>
                                                                  ) : (
                                                                    <div style={{ width: '95%' }}>
                                                                      {
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                                            ? (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers7 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex} >
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers7.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}


                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers7).map((carrier, index) => (
                                                                                            <div key={index}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br/>
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"
                                                                                                /><br />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );
                                                                                          }


                                                                                        })
                                                                                      }


                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            ) : (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers8 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex}>
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers8.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}

                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers8).map((carrier, index) => (
                                                                                            <div key={index}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br/>
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"
                                                                                                /><br />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );

                                                                                          }


                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            )
                                                                        )
                                                                      }

                                                                    </div>
                                                                  )
                                                              )
                                                              }




                                                            </div>
                                                          </div>
                                                          <div className="flt-l-c">
                                                            <div className="flt-l-cb">
                                                              <div className="flt-l-c-padding">
                                                                <div className="flyght-info-head">
                                                                  <input type="hidden" name="SearchPriceTotalPrice" value={pricepoint['$']['TotalPrice']} />
                                                                  {pricepoint['air:AirPricingInfo'] && (
                                                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                      ? (
                                                                        <div>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                                                ? (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['0'] &&


                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {
                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })

                                                                                          }


                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }


                                                                                            })
                                                                                          }


                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                ) : (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>


                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['0'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }




                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );


                                                                                              }


                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                )
                                                                            )
                                                                          }

                                                                        </div>

                                                                      ) : (
                                                                        <div>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                                                ? (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['0'] &&


                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {
                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })

                                                                                          }


                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }


                                                                                            })
                                                                                          }


                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                ) : (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>


                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['0'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }




                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    {handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])}
                                                                                                  </div>
                                                                                                );


                                                                                              }


                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                )
                                                                            )
                                                                          }

                                                                        </div>

                                                                      )
                                                                  )
                                                                  }


                                                                </div>
                                                                {/* // */}
                                                                <div className="flight-line">
                                                                  {pricepoint['air:AirPricingInfo'] && (
                                                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                      ? (
                                                                        <>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                                                ? (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                width="45px"
                                                                                                style={{ textAlign: 'center', marginTop: '7%' }}
                                                                                              >
                                                                                                <span >
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['$']['TravelTime'])}
                                                                                                </span>
                                                                                                <span className="stop-badge-container">
                                                                                                  <div className="flight-line-a">
                                                                                                    {(() => {
                                                                                                      let totalIterations = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].length - 1;
                                                                                                      let stopoverCity = "";
                                                                                                      let flightTimeInMinutes = 0;
                                                                                                      if (totalIterations > 0) {
                                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].forEach((bookingInfo) => {
                                                                                                          SegmentList.map((segmentinfo) => {
                                                                                                            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                              flightTimeInMinutes = flightTimeInMinutes + parseInt(segmentinfo['$']['FlightTime']);
                                                                                                              stopoverCity = handleAirport(segmentinfo['$']['Origin']);
                                                                                                            }
                                                                                                          });
                                                                                                        });
                                                                                                      }
                                                                                                      {/* <p className="stop-badge" >
                                                                                                                      {totalIterations} stop via {stopoverCity}
                                                                                                                          <div className="tooltip-content">
                                                                                                                            {totalIterations} stop from {stopoverCity} 
                                                                                                                          </div>
                                                                                                                        </p> */}
                                                                                                      return (
                                                                                                        <p className="stop-badge" >
                                                                                                          {totalIterations} stop via {stopoverCity}
                                                                                                          <div className="tooltip-content">
                                                                                                            <p style={{ marginBottom: '0px' }}>Plane Change</p>
                                                                                                            {stopoverCity} | {convertMinutesToHours(pricepoint['travelTime'] - flightTimeInMinutes)} Layover
                                                                                                          </div>
                                                                                                        </p>

                                                                                                      );
                                                                                                    })()}
                                                                                                  </div>
                                                                                                </span>
                                                                                              </div>

                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                          </span>

                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"
                                                                                                          width="45px"
                                                                                                          style={{ textAlign: 'center', marginTop: '7%' }}
                                                                                                        >
                                                                                                          <span style={{ marginLeft: '5px', marginRight: '5px' }} >
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                          <span className="stop-badge-container">
                                                                                                            <p className="stop-badge">
                                                                                                              Non-Stop
                                                                                                              <div className="tooltip-content">
                                                                                                                This is a direct flight with no stops.
                                                                                                              </div>
                                                                                                            </p>
                                                                                                          </span>
                                                                                                        </div>

                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                               </span>
                                                                                                      </div>
                                                                                                    </span>


                                                                                                  </span>
                                                                                                );
                                                                                              }


                                                                                            })
                                                                                          }


                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                width="45px"
                                                                                                style={{ textAlign: 'center', marginTop: '7%' }}
                                                                                              >
                                                                                                <span >
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['$']['TravelTime'])}
                                                                                                </span>
                                                                                                <span className="stop-badge-container">
                                                                                                  <div className="flight-line-a">
                                                                                                    {(() => {
                                                                                                      let totalIterations = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].length - 1;
                                                                                                      let stopoverCity = "";
                                                                                                      let flightTimeInMinutes = 0;
                                                                                                      if (totalIterations > 0) {
                                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].forEach((bookingInfo) => {
                                                                                                          SegmentList.map((segmentinfo) => {
                                                                                                            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                              flightTimeInMinutes = flightTimeInMinutes + parseInt(segmentinfo['$']['FlightTime']);
                                                                                                              stopoverCity = handleAirport(segmentinfo['$']['Origin']);
                                                                                                            }
                                                                                                          });
                                                                                                        });
                                                                                                      }
                                                                                                      return (
                                                                                                        <p className="stop-badge" >
                                                                                                          {totalIterations} stop via {stopoverCity}
                                                                                                          <div className="tooltip-content">
                                                                                                            <p style={{ marginBottom: '0px' }}>Plane Change</p>
                                                                                                            {stopoverCity} | {convertMinutesToHours(pricepoint['travelTime'] - flightTimeInMinutes)} Layover
                                                                                                          </div>
                                                                                                        </p>
                                                                                                      );
                                                                                                    })()}
                                                                                                  </div>
                                                                                                </span>
                                                                                              </div>

                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                          </span>

                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"
                                                                                                          width="45px"
                                                                                                          style={{ textAlign: 'center', marginTop: '7%' }}
                                                                                                        >
                                                                                                          <span style={{ marginLeft: '5px', marginRight: '5px' }} >
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                          <span className="stop-badge-container">
                                                                                                            <p className="stop-badge">
                                                                                                              Non-Stop
                                                                                                              <div className="tooltip-content">
                                                                                                                This is a direct flight with no stops.
                                                                                                              </div>
                                                                                                            </p>
                                                                                                          </span>
                                                                                                        </div>

                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Destination'])} <br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                              </span>
                                                                                                      </div>
                                                                                                    </span>


                                                                                                  </span>
                                                                                                );

                                                                                              }


                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                )
                                                                            )
                                                                          }

                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                                                ? (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                width="45px"
                                                                                                style={{ textAlign: 'center', marginTop: '7%' }}
                                                                                              >
                                                                                                <span >
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['$']['TravelTime'])}
                                                                                                </span>
                                                                                                <span className="stop-badge-container">
                                                                                                  <div className="flight-line-a">
                                                                                                    {(() => {
                                                                                                      let totalIterations = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].length - 1;
                                                                                                      let stopoverCity = "";
                                                                                                      let flightTimeInMinutes = 0;
                                                                                                      if (totalIterations > 0) {
                                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].forEach((bookingInfo) => {
                                                                                                          SegmentList.map((segmentinfo) => {
                                                                                                            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                              flightTimeInMinutes = flightTimeInMinutes + parseInt(segmentinfo['$']['FlightTime']);
                                                                                                              stopoverCity = handleAirport(segmentinfo['$']['Origin']);
                                                                                                            }
                                                                                                          });
                                                                                                        });
                                                                                                      }
                                                                                                      return (
                                                                                                        <p className="stop-badge" >
                                                                                                          {totalIterations} stop via {stopoverCity}
                                                                                                          <div className="tooltip-content">
                                                                                                            <p style={{ marginBottom: '0px' }}>Plane Change</p>
                                                                                                            {stopoverCity} | {convertMinutesToHours(pricepoint['travelTime'] - flightTimeInMinutes)} Layover
                                                                                                          </div>
                                                                                                        </p>
                                                                                                      );
                                                                                                    })()}
                                                                                                  </div>
                                                                                                </span>
                                                                                              </div>

                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                          </span>

                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"
                                                                                                          width="45px"
                                                                                                          style={{ textAlign: 'center', marginTop: '7%' }}
                                                                                                        >
                                                                                                          <span style={{ marginLeft: '5px', marginRight: '5px' }} >
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                          <span className="stop-badge-container">
                                                                                                            <p className="stop-badge">
                                                                                                              Non-Stop
                                                                                                              <div className="tooltip-content">
                                                                                                                This is a direct flight with no stops.
                                                                                                              </div>
                                                                                                            </p>
                                                                                                          </span>
                                                                                                        </div>

                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Destination'])} <br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                              </span>
                                                                                                      </div>
                                                                                                    </span>


                                                                                                  </span>
                                                                                                );
                                                                                              }


                                                                                            })
                                                                                          }


                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span></span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                width="45px"
                                                                                                style={{ textAlign: 'center', marginTop: '7%' }}
                                                                                              >
                                                                                                <span >
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['$']['TravelTime'])}
                                                                                                </span>
                                                                                                <span className="stop-badge-container">
                                                                                                  <div className="flight-line-a">
                                                                                                    {(() => {
                                                                                                      let totalIterations = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].length - 1;
                                                                                                      let stopoverCity = "";
                                                                                                      let flightTimeInMinutes = 0;
                                                                                                      if (totalIterations > 0) {
                                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].forEach((bookingInfo) => {
                                                                                                          SegmentList.map((segmentinfo) => {
                                                                                                            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                              flightTimeInMinutes = flightTimeInMinutes + parseInt(segmentinfo['$']['FlightTime']);
                                                                                                              stopoverCity = handleAirport(segmentinfo['$']['Origin']);
                                                                                                            }
                                                                                                          });
                                                                                                        });
                                                                                                      }
                                                                                                      return (
                                                                                                        <p className="stop-badge" >
                                                                                                          {totalIterations} stop via {stopoverCity}
                                                                                                          <div className="tooltip-content">
                                                                                                            <p style={{ marginBottom: '0px' }}>Plane Change</p>
                                                                                                            {stopoverCity} | {convertMinutesToHours(pricepoint['travelTime'] - flightTimeInMinutes)} Layover
                                                                                                          </div>
                                                                                                        </p>
                                                                                                      );
                                                                                                    })()}
                                                                                                  </div>
                                                                                                </span>
                                                                                              </div>

                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                          </span>

                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Origin'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"
                                                                                                          width="45px"
                                                                                                          style={{ textAlign: 'center', marginTop: '7%' }}
                                                                                                        >
                                                                                                          <span style={{ marginLeft: '5px', marginRight: '5px' }} >
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                          <span className="stop-badge-container">
                                                                                                            <p className="stop-badge">
                                                                                                              Non-Stop
                                                                                                              <div className="tooltip-content">
                                                                                                                This is a direct flight with no stops.
                                                                                                              </div>
                                                                                                            </p>
                                                                                                          </span>
                                                                                                        </div>

                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          {handleAirport(segmentinfo['$']['Destination'])}<br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                               </span>
                                                                                                      </div>
                                                                                                    </span>


                                                                                                  </span>
                                                                                                );

                                                                                              }


                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                )
                                                                            )
                                                                          }

                                                                        </>
                                                                      )
                                                                  )
                                                                  }

                                                                  {/* <div className="flight-line-b">
                                                                      <b onClick={() => handleFlightDetails(priceindex)}>flight details2</b>
                                                                    </div> */}

                                                                  <div className="flt-i-price">
                                                                    {pricepoint['air:AirPricingInfo'] && (
                                                                      Array.isArray(pricepoint['air:AirPricingInfo']) ? (
                                                                        <>
                                                                          {(() => {
                                                                            const totalPrice =
                                                                              pricepoint['air:AirPricingInfo'][0]['$']['TotalPrice'];
                                                                            const seatType = cabinClass; // Set the seat type dynamically as needed
                                                                            const fareName = "Base Fare";
                                                                            const airline = inputOrigin;

                                                                            const finalPrice = calculateFinalPrice(totalPrice, markupdata, seatType, fareName, airline, flight_type);

                                                                            return (
                                                                              <>
                                                                                {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                {finalPrice.toFixed(2)} {/* Show final price with markup applied */}
                                                                              </>
                                                                            );
                                                                          })()}
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {(() => {
                                                                            const totalPrice =
                                                                              pricepoint['air:AirPricingInfo']['$']['TotalPrice'];
                                                                            const seatType = cabinClass; // Set the seat type dynamically as needed
                                                                            const fareName = "Base Fare";
                                                                            const airline = inputOrigin;

                                                                            const finalPrice = calculateFinalPrice(totalPrice, markupdata, seatType, fareName, airline, flight_type);

                                                                            return (
                                                                              <>
                                                                                {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                {finalPrice.toFixed(2)} {/* Show final price with markup applied */}
                                                                              </>
                                                                            );
                                                                          })()}
                                                                        </>
                                                                      )
                                                                    )}


                                                                  </div>




                                                                  <div className="flight-details" style={{ display: visibleFlightIndex === priceindex ? 'block' : 'none' }}>
                                                                    <Nav justify variant="tabs" className="flight_nav" defaultActiveKey={`Flight_Details${priceindex}`} activeKey={activeTab} onSelect={setActiveTab} style={{ border: 'none' }}>
                                                                      {/* <Nav.Item>
                                                                            <Nav.Link eventKey={`Flight_Detail${priceindex}`} className={activeTab.includes(`Flight_Detail${priceindex}`) ? 'active' : ''}>Flight Detail</Nav.Link>
                                                                          </Nav.Item> */}
                                                                      {/* <Nav.Item>
                                                                            <Nav.Link eventKey={`Fare_Summary${priceindex}`}>Fare Summary</Nav.Link>
                                                                          </Nav.Item>
                                                                          <Nav.Item>
                                                                            <Nav.Link eventKey={`Date_Change${priceindex}`}>Date Change</Nav.Link>
                                                                          </Nav.Item>
                                                                          <Nav.Item>
                                                                            <Nav.Link eventKey={`Cancellation${priceindex}`}>Cancellation</Nav.Link>
                                                                          </Nav.Item> */}
                                                                    </Nav>
                                                                    {pricepoint['air:AirPricingInfo'] && (
                                                                      Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                        ? (
                                                                          <div>
                                                                            <div id={`Flight_Detail${priceindex}`} className="tabcontent" style={activeTab.includes(`Flight_Detail${priceindex}`) ? { display: 'block' } : { display: 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                                                    ? (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d"><input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])

                                                                                                                }
                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}


                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }

                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );
                                                                                                  }


                                                                                                })
                                                                                              }


                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d">
                                                                                                                <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                                }
                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }
                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );

                                                                                                  }


                                                                                                })
                                                                                              }
                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    )
                                                                                )
                                                                              }
                                                                            </div>
                                                                            <div id={`Fare_Summary${priceindex}`} className="tabcontent" style={{ display: activeTab === `Fare_Summary${priceindex}` ? 'block' : 'none' }}>

                                                                              <div className="flight-details-a">
                                                                                Fare Breakup (For All Passengers)
                                                                              </div>

                                                                              <div className="flight-details-l">
                                                                                <div className="flight-details-b">
                                                                                  Total Fare
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  Base Price
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  Surcharge
                                                                                </div>
                                                                              </div>
                                                                              <div className="flight-details-r">
                                                                                <div className="flight-details-b">
                                                                                  {pricepoint.$.TotalPrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.TotalPrice.replace('INR', '')}
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.BasePrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.BasePrice.replace('INR', '')}
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.Taxes.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.Taxes.replace('INR', '')}
                                                                                </div>
                                                                              </div>
                                                                              <div className="clear" />
                                                                            </div>
                                                                            <div id={`Date_Change${priceindex}`} className="tabcontent" style={{ display: activeTab === `Date_Change${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    || {inputOrigin && inputOrigin.split('(')[0]} to {inputDestination && inputDestination.split('(')[0]} , &nbsp;
                                                                                    {
                                                                                      handledatemonthsplit(request.searchreturnDate.split('/'))

                                                                                    }
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee+Fare diff.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Amount'].replace('INR', '')} Fare diff.
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline
                                                                                      fee provided is indicative.
                                                                                      CoTrav does not guarantee the
                                                                                      exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                            <div id={`Cancellation${priceindex}`} className="tabcontent" style={{ display: activeTab === `Cancellation${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    || {inputOrigin && inputOrigin.split('(')[0]} to {inputDestination && inputDestination.split('(')[0]} , &nbsp;
                                                                                    {
                                                                                      handledatemonthsplit(request.searchreturnDate.split('/'))
                                                                                    }
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee+Fare diff.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Amount'].replace('INR', '')} Fare diff.
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline
                                                                                      fee provided is indicative.
                                                                                      CoTrav does not guarantee the
                                                                                      exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        ) : (
                                                                          <div>
                                                                            <div id={`Flight_Detail${priceindex}`} className="tabcontent" style={activeTab.includes(`Flight_Detail${priceindex}`) ? { display: 'block' } : { display: 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'] && (
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'])
                                                                                    ? (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d">
                                                                                                                <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])

                                                                                                                }

                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}


                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option'][0]['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }

                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );
                                                                                                  }


                                                                                                })
                                                                                              }


                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d">
                                                                                                                <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                                }
                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'][1]['air:Option']['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }
                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );

                                                                                                  }


                                                                                                })
                                                                                              }
                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    )
                                                                                )
                                                                              }
                                                                            </div>
                                                                            <div id={`Fare_Summary${priceindex}`} className="tabcontent" style={{ display: activeTab === `Fare_Summary${priceindex}` ? 'block' : 'none' }}>

                                                                              <div className="flight-details-a">
                                                                                Fare Breakup (For All Passengers)
                                                                              </div>

                                                                              <div className="flight-details-l">
                                                                                <div className="flight-details-b">
                                                                                  Total Fare
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  Base Price
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  Surcharge
                                                                                </div>
                                                                              </div>
                                                                              <div className="flight-details-r">
                                                                                <div className="flight-details-b">
                                                                                  {pricepoint.$.TotalPrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.TotalPrice.replace('INR', '')}
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.BasePrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.BasePrice.replace('INR', '')}
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.Taxes.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.Taxes.replace('INR', '')}
                                                                                </div>
                                                                              </div>
                                                                              <div className="clear" />
                                                                            </div>
                                                                            <div id={`Date_Change${priceindex}`} className="tabcontent" style={{ display: activeTab === `Date_Change${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:ChangePenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    || {inputOrigin && inputOrigin.split('(')[0]} to {inputDestination && inputDestination.split('(')[0]} , &nbsp;
                                                                                    {
                                                                                      handledatemonthsplit(request.searchreturnDate.split('/'))

                                                                                    }
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee+Fare diff.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                          {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Amount'].replace('INR', '')} Fare diff.
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline
                                                                                      fee provided is indicative.
                                                                                      CoTrav does not guarantee the
                                                                                      exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                            <div id={`Cancellation${priceindex}`} className="tabcontent" style={{ display: activeTab === `Cancellation${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:CancelPenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    || {inputOrigin && inputOrigin.split('(')[0]} to {inputDestination && inputDestination.split('(')[0]} , &nbsp;
                                                                                    {
                                                                                      handledatemonthsplit(request.searchreturnDate.split('/'))
                                                                                    }
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee+Fare diff.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                          {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Amount'].replace('INR', '')} Fare diff.
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline
                                                                                      fee provided is indicative.
                                                                                      CoTrav does not guarantee the
                                                                                      exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        )
                                                                    )

                                                                    }
                                                                  </div>



                                                                </div>
                                                              </div>
                                                            </div>
                                                            <br className="clear" />
                                                          </div>
                                                        </div>
                                                        <div className="clear" />
                                                      </div>
                                                      <br className="clear" />
                                                    </div>
                                                    {isDropdownVisible && selectedPriceIndex === priceindex && (
                                                      <div className="row selectcontainer">
                                                        {/* Indigo price loader */}
                                                        {loadingIndex === priceindex ? (
                                                          <div className="loader custom-loader item-center justify-center flex items-center">
                                                            <img className="loader-giff" style={{ width: '5rem', height: '5rem' }} src="/img/cotravloader.gif" alt="Loader" />
                                                            <p className="text-center ml-4 text-gray-600">
                                                            </p>
                                                          </div>
                                                        ) : (
                                                          /* Actual Content */
                                                          priceParse && priceParse.map((priceParseData, priceParseindex) => (
                                                            <div key={priceParseindex} className='col-md-3 optionsflights' style={{ display: "flex" }}>
                                                              <div className='optionsflight' style={{ width: "63%" }}>
                                                                {priceParseData['air:AirPricingInfo'] && (
                                                                  Array.isArray(priceParseData['air:AirPricingInfo'])
                                                                    ? (
                                                                      priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] && (
                                                                        Array.isArray(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'])
                                                                          ? (
                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['air:Text'] ? (
                                                                              Array.isArray(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['air:Text'])
                                                                                ? (
                                                                                  <div className="modal-data">
                                                                                    <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                      <button
                                                                                        type="button"
                                                                                        onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                        style={{
                                                                                          border: "none",
                                                                                          background: "none",
                                                                                          cursor: "pointer",
                                                                                          fontSize: "16px",
                                                                                          color: "#785eff",
                                                                                        }}
                                                                                        aria-label="Toggle Details"
                                                                                      >
                                                                                        <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                      </button></div>
                                                                                    <div className="selectprice">
                                                                                      {(() => {
                                                                                        const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                        const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                        const airline = inputOrigin;
                                                                                        const calculatedPrice = calculateFinalPrice(
                                                                                          numericTotalPrice,
                                                                                          markupdata,
                                                                                          cabinClass,
                                                                                          priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'],// Pass the fare name (e.g., "ECO VALUE")
                                                                                          airline, flight_type
                                                                                        );

                                                                                        return (
                                                                                          <>
                                                                                            {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                            {calculatedPrice}
                                                                                          </>
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                    {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                      priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['air:Text'].map((textinfor, textindex) => {
                                                                                        if (
                                                                                          textinfor['$'] &&
                                                                                          textinfor['$']['Type'] === "MarketingConsumer"
                                                                                        ) {

                                                                                          const infoArray = textinfor['_'].split('\n').filter(item => item.trim() !== '');

                                                                                          return (
                                                                                            <>
                                                                                              <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                              <div key={textindex} className="selectdetail">
                                                                                                <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                                <ul>
                                                                                                  {infoArray.map((item, index) => (
                                                                                                    <li key={index}>{item.trim()}</li>
                                                                                                  ))}
                                                                                                </ul>
                                                                                              </div>
                                                                                            </>
                                                                                          );
                                                                                        }

                                                                                      })
                                                                                    )}
                                                                                  </div>
                                                                                ) : (
                                                                                  <>
                                                                                    <div>
                                                                                      <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                        <button
                                                                                          type="button"
                                                                                          onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                          style={{
                                                                                            border: "none",
                                                                                            background: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            color: "#785eff",
                                                                                          }}
                                                                                          aria-label="Toggle Details"
                                                                                        >
                                                                                          <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                        </button></div>
                                                                                      <div className="selectprice">
                                                                                        {(() => {
                                                                                          const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                          const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                          const airline = inputOrigin;
                                                                                          const calculatedPrice = calculateFinalPrice(
                                                                                            numericTotalPrice,
                                                                                            markupdata,
                                                                                            cabinClass,
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                            , airline, flight_type
                                                                                          );

                                                                                          return (
                                                                                            <>
                                                                                              {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                              {calculatedPrice}
                                                                                            </>
                                                                                          );
                                                                                        })()}
                                                                                      </div>
                                                                                      {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                        <>
                                                                                          <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                          <div className="selectdetail">
                                                                                            <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                            <ul><li>
                                                                                              {priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] && priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0] &&
                                                                                                priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement'] &&
                                                                                                priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement']['$'] &&
                                                                                                priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement']['$']['Value']}
                                                                                            </li></ul>
                                                                                          </div>
                                                                                        </>
                                                                                      )}
                                                                                    </div>
                                                                                  </>
                                                                                )
                                                                            ) : (
                                                                              <>
                                                                                <div>
                                                                                  <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                    <button
                                                                                      type="button"
                                                                                      onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                      style={{
                                                                                        border: "none",
                                                                                        background: "none",
                                                                                        cursor: "pointer",
                                                                                        fontSize: "16px",
                                                                                        color: "#785eff",
                                                                                      }}
                                                                                      aria-label="Toggle Details"
                                                                                    >
                                                                                      <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                    </button></div>
                                                                                  <div className="selectprice">
                                                                                    {(() => {
                                                                                      const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                      const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                      const airline = inputOrigin;
                                                                                      const calculatedPrice = calculateFinalPrice(
                                                                                        numericTotalPrice,
                                                                                        markupdata,
                                                                                        cabinClass,
                                                                                        priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                        , airline, flight_type
                                                                                      );

                                                                                      return (
                                                                                        <>
                                                                                          {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                          {calculatedPrice}
                                                                                        </>
                                                                                      );
                                                                                    })()}
                                                                                  </div>
                                                                                  {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                    <>
                                                                                      <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                      <div className="selectdetail">
                                                                                        <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                        <ul><li>
                                                                                          {priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] && priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0] &&
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement'] &&
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement']['$'] &&
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement']['$']['Value']}
                                                                                        </li></ul>
                                                                                      </div>
                                                                                    </>
                                                                                  )}
                                                                                </div>
                                                                              </>
                                                                            )

                                                                          ) : (
                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['air:Text'] ? (
                                                                              Array.isArray(priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['air:Text'])
                                                                                ? (
                                                                                  <div>
                                                                                    <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                      <button
                                                                                        type="button"
                                                                                        onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                        style={{
                                                                                          border: "none",
                                                                                          background: "none",
                                                                                          cursor: "pointer",
                                                                                          fontSize: "16px",
                                                                                          color: "#785eff",
                                                                                        }}
                                                                                        aria-label="Toggle Details"
                                                                                      >
                                                                                        <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                      </button></div>
                                                                                    <div className="selectprice">
                                                                                      {(() => {
                                                                                        const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                        const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                        const airline = inputOrigin;
                                                                                        const calculatedPrice = calculateFinalPrice(
                                                                                          numericTotalPrice,
                                                                                          markupdata,
                                                                                          cabinClass,
                                                                                          priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                          , airline, flight_type
                                                                                        );

                                                                                        return (
                                                                                          <>
                                                                                            {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                            {calculatedPrice}
                                                                                          </>
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                    {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                      priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['air:Text'].map((textinfor, textindex) => {
                                                                                        if (
                                                                                          textinfor['$'] &&
                                                                                          textinfor['$']['Type'] === "MarketingConsumer"
                                                                                        ) {

                                                                                          const infoArray = textinfor['_'].split('\n').filter(item => item.trim() !== '');

                                                                                          return (
                                                                                            <>
                                                                                              <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                              <div key={textindex} className="selectdetail">
                                                                                                <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                                <ul>
                                                                                                  {infoArray.map((item, index) => (
                                                                                                    <li key={index}>{item.trim()}</li>
                                                                                                  ))}
                                                                                                </ul>
                                                                                              </div>
                                                                                            </>
                                                                                          );
                                                                                        }

                                                                                      })
                                                                                    )}
                                                                                  </div>
                                                                                ) : (
                                                                                  <>
                                                                                    <div>
                                                                                      <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                        <button
                                                                                          type="button"
                                                                                          onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                          style={{
                                                                                            border: "none",
                                                                                            background: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            color: "#785eff",
                                                                                          }}
                                                                                          aria-label="Toggle Details"
                                                                                        >
                                                                                          <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                        </button></div>
                                                                                      <div className="selectprice">
                                                                                        {(() => {
                                                                                          const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                          const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                          const airline = inputOrigin;
                                                                                          const calculatedPrice = calculateFinalPrice(
                                                                                            numericTotalPrice,
                                                                                            markupdata,
                                                                                            cabinClass,
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                            , airline, flight_type
                                                                                          );

                                                                                          return (
                                                                                            <>
                                                                                              {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                              {calculatedPrice}
                                                                                            </>
                                                                                          );
                                                                                        })()}
                                                                                      </div>
                                                                                      {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                        <>
                                                                                          <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                          <div className="selectdetail">
                                                                                            <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                            <ul><li>
                                                                                              {priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] &&
                                                                                                priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement'] &&
                                                                                                priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement']['$'] &&
                                                                                                priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement']['$']['Value']}
                                                                                            </li></ul>
                                                                                          </div>
                                                                                        </>
                                                                                      )}
                                                                                    </div>
                                                                                  </>
                                                                                )
                                                                            ) : (
                                                                              <>
                                                                                <div>
                                                                                  <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                    <button
                                                                                      type="button"
                                                                                      onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                      style={{
                                                                                        border: "none",
                                                                                        background: "none",
                                                                                        cursor: "pointer",
                                                                                        fontSize: "16px",
                                                                                        color: "#785eff",
                                                                                      }}
                                                                                      aria-label="Toggle Details"
                                                                                    >
                                                                                      <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                    </button></div>
                                                                                  <div className="selectprice">
                                                                                    {(() => {
                                                                                      const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                      const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                      const airline = inputOrigin;

                                                                                      const calculatedPrice = calculateFinalPrice(
                                                                                        numericTotalPrice,
                                                                                        markupdata,
                                                                                        cabinClass,
                                                                                        priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                        , airline, flight_type
                                                                                      );

                                                                                      return (
                                                                                        <>
                                                                                          {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                          {calculatedPrice}
                                                                                        </>
                                                                                      );
                                                                                    })()}
                                                                                  </div>
                                                                                  {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                    <>
                                                                                      <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                      <div className="selectdetail">
                                                                                        <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                        <ul><li>
                                                                                          {priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] &&
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement'] &&
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement']['$'] &&
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement']['$']['Value']}
                                                                                        </li></ul>
                                                                                      </div>
                                                                                    </>
                                                                                  )}
                                                                                </div>
                                                                              </>
                                                                            )
                                                                          )

                                                                      )

                                                                    ) : (
                                                                      priceParseData['air:AirPricingInfo']['air:FareInfo'] && (
                                                                        Array.isArray(priceParseData['air:AirPricingInfo']['air:FareInfo'])
                                                                          ? (
                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['air:Text'] ? (
                                                                              Array.isArray(priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['air:Text'])
                                                                                ? (
                                                                                  <div>
                                                                                    <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                      <button
                                                                                        type="button"
                                                                                        onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                        style={{
                                                                                          border: "none",
                                                                                          background: "none",
                                                                                          cursor: "pointer",
                                                                                          fontSize: "16px",
                                                                                          color: "#785eff",
                                                                                        }}
                                                                                        aria-label="Toggle Details"
                                                                                      >
                                                                                        <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                      </button></div>
                                                                                    <div className="selectprice">
                                                                                      {(() => {
                                                                                        const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                        const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                        const airline = inputOrigin;
                                                                                        const calculatedPrice = calculateFinalPrice(
                                                                                          numericTotalPrice,
                                                                                          markupdata,
                                                                                          cabinClass,
                                                                                          priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                          , airline, flight_type
                                                                                        );

                                                                                        return (
                                                                                          <>
                                                                                            {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                            {calculatedPrice}
                                                                                          </>
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                    {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                      priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['air:Text'].map((textinfor, textindex) => {
                                                                                        if (
                                                                                          textinfor['$'] &&
                                                                                          textinfor['$']['Type'] === "MarketingConsumer"
                                                                                        ) {

                                                                                          const infoArray = textinfor['_'].split('\n').filter(item => item.trim() !== '');

                                                                                          return (
                                                                                            <>
                                                                                              <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                              <div key={textindex} className="selectdetail">
                                                                                                <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                                <ul>
                                                                                                  {infoArray.map((item, index) => (
                                                                                                    <li key={index}>{item.trim()}</li>
                                                                                                  ))}
                                                                                                </ul>
                                                                                              </div>
                                                                                            </>
                                                                                          );
                                                                                        }

                                                                                      })
                                                                                    )}
                                                                                  </div>
                                                                                ) : (
                                                                                  <>
                                                                                    <div>
                                                                                      <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                        <button
                                                                                          type="button"
                                                                                          onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                          style={{
                                                                                            border: "none",
                                                                                            background: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            color: "#785eff",
                                                                                          }}
                                                                                          aria-label="Toggle Details"
                                                                                        >
                                                                                          <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                        </button></div>
                                                                                      <div className="selectprice">
                                                                                        {(() => {
                                                                                          const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                          const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                          const airline = inputOrigin;
                                                                                          const calculatedPrice = calculateFinalPrice(
                                                                                            numericTotalPrice,
                                                                                            markupdata,
                                                                                            cabinClass,
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                            , airline, flight_type
                                                                                          );

                                                                                          return (
                                                                                            <>
                                                                                              {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                              {calculatedPrice}
                                                                                            </>
                                                                                          );
                                                                                        })()}
                                                                                      </div>
                                                                                      {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                        <>
                                                                                          <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                          <div className="selectdetail">
                                                                                            <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                            <ul><li>
                                                                                              {priceParseData['air:AirPricingInfo']['air:FareInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'][0] &&
                                                                                                priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement'] &&
                                                                                                priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement']['$'] &&
                                                                                                priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement']['$']['Value']}
                                                                                            </li></ul>
                                                                                          </div>
                                                                                        </>
                                                                                      )}
                                                                                    </div>
                                                                                  </>
                                                                                )
                                                                            ) : (
                                                                              <>
                                                                                <div>
                                                                                  <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                    <button
                                                                                      type="button"
                                                                                      onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                      style={{
                                                                                        border: "none",
                                                                                        background: "none",
                                                                                        cursor: "pointer",
                                                                                        fontSize: "16px",
                                                                                        color: "#785eff",
                                                                                      }}
                                                                                      aria-label="Toggle Details"
                                                                                    >
                                                                                      <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                    </button></div>
                                                                                  <div className="selectprice">
                                                                                    {(() => {
                                                                                      const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                      const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                      const airline = inputOrigin;

                                                                                      const calculatedPrice = calculateFinalPrice(
                                                                                        numericTotalPrice,
                                                                                        markupdata,
                                                                                        cabinClass,
                                                                                        priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                        , airline, flight_type
                                                                                      );

                                                                                      return (
                                                                                        <>
                                                                                          {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                          {calculatedPrice}
                                                                                        </>
                                                                                      );
                                                                                    })()}
                                                                                  </div>
                                                                                  {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                    <>
                                                                                      <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                      <div className="selectdetail">
                                                                                        <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                        <ul><li>
                                                                                          {priceParseData && priceParseData['air:AirPricingInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'][0] &&
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement'] &&
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement']['$'] &&
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement']['$']['Value']}
                                                                                        </li></ul>
                                                                                      </div>
                                                                                    </>
                                                                                  )}
                                                                                </div>
                                                                              </>
                                                                            )

                                                                          ) : (

                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['air:Text'] &&
                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['air:Text'] ? (
                                                                              Array.isArray(priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['air:Text'])

                                                                                ? (
                                                                                  <div>
                                                                                    <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                      <button
                                                                                        type="button"
                                                                                        onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                        style={{
                                                                                          border: "none",
                                                                                          background: "none",
                                                                                          cursor: "pointer",
                                                                                          fontSize: "16px",
                                                                                          color: "#785eff",
                                                                                        }}
                                                                                        aria-label="Toggle Details"
                                                                                      >
                                                                                        <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                      </button>
                                                                                    </div>

                                                                                    <div className="selectprice">
                                                                                      {(() => {
                                                                                        const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                        const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                        const airline = inputOrigin;
                                                                                        const calculatedPrice = calculateFinalPrice(
                                                                                          numericTotalPrice,
                                                                                          markupdata,
                                                                                          cabinClass,
                                                                                          priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                          , airline, flight_type
                                                                                        );

                                                                                        return (
                                                                                          <>
                                                                                            {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                            {calculatedPrice}
                                                                                          </>
                                                                                        );
                                                                                      })()}
                                                                                    </div>

                                                                                    {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] && (() => {
                                                                                      const validDetails = priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['air:Text'].filter(
                                                                                        (textinfor) => textinfor['$']?.Type === "MarketingConsumer"
                                                                                      );

                                                                                      const closeButton = (
                                                                                        <button className="selectdetail-close" style={{ marginTop: "-3%", marginRight: "-3%" }} onClick={() => setVisibleDetails(false)} >
                                                                                          &times;
                                                                                        </button>
                                                                                      );

                                                                                      return validDetails.length > 0 ? (
                                                                                        validDetails.map((textinfor, textindex) => {
                                                                                          const infoArray = textinfor['_']
                                                                                            .split('\n')
                                                                                            .filter((item) => item.trim() !== '');

                                                                                          return (
                                                                                            <div key={textindex}>
                                                                                              <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                              <div className="selectdetail">
                                                                                                {closeButton}
                                                                                                <ul>
                                                                                                  {infoArray.map((item, index) => (
                                                                                                    <li key={index}>{item.trim()}</li>
                                                                                                  ))}
                                                                                                </ul>
                                                                                              </div>
                                                                                            </div>
                                                                                          );
                                                                                        })
                                                                                      ) : (
                                                                                        <div>
                                                                                          <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                          <div className="selectdetail">
                                                                                            {closeButton}
                                                                                            <p>No details are available at present. Please check back later.</p>
                                                                                          </div>
                                                                                        </div>
                                                                                      );
                                                                                    })()}
                                                                                  </div>
                                                                                ) : (
                                                                                  <>
                                                                                    <div>
                                                                                      <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                        <button
                                                                                          type="button"
                                                                                          onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                          style={{
                                                                                            border: "none",
                                                                                            background: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            color: "#785eff",
                                                                                          }}
                                                                                          aria-label="Toggle Details"
                                                                                        >
                                                                                          <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                        </button></div>
                                                                                      <div className="selectprice">
                                                                                        {(() => {
                                                                                          const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                          const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                          const airline = inputOrigin;
                                                                                          const calculatedPrice = calculateFinalPrice(
                                                                                            numericTotalPrice,
                                                                                            markupdata,
                                                                                            cabinClass,
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                            , airline, flight_type
                                                                                          );

                                                                                          return (
                                                                                            <>
                                                                                              {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                              {calculatedPrice}
                                                                                            </>
                                                                                          );
                                                                                        })()}
                                                                                      </div>
                                                                                      {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                        <>
                                                                                          <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                          <div className="selectdetail">
                                                                                            <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                            <ul><li>
                                                                                              {priceParseData['air:AirPricingInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'] &&
                                                                                                priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement'] &&
                                                                                                priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement']['$'] &&
                                                                                                priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement']['$']['Value']}
                                                                                            </li></ul>
                                                                                          </div>
                                                                                        </>
                                                                                      )}
                                                                                    </div>
                                                                                  </>
                                                                                )
                                                                            ) : (
                                                                              <>
                                                                                <div>
                                                                                  <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                    <button
                                                                                      type="button"
                                                                                      onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                      style={{
                                                                                        border: "none",
                                                                                        background: "none",
                                                                                        cursor: "pointer",
                                                                                        fontSize: "16px",
                                                                                        color: "#785eff",
                                                                                      }}
                                                                                      aria-label="Toggle Details"
                                                                                    >
                                                                                      <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                    </button></div>
                                                                                  <div className="selectprice">
                                                                                    {(() => {
                                                                                      const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                      const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                      const airline = inputOrigin;
                                                                                      const calculatedPrice = calculateFinalPrice(
                                                                                        numericTotalPrice,
                                                                                        markupdata,
                                                                                        cabinClass,
                                                                                        priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                        , airline, flight_type
                                                                                      );

                                                                                      return (
                                                                                        <>
                                                                                          {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                          {calculatedPrice}
                                                                                        </>
                                                                                      );
                                                                                    })()}
                                                                                  </div>
                                                                                  {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                    <>
                                                                                      <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                      <div className="selectdetail">
                                                                                        <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                        <ul><li>
                                                                                          {priceParseData['air:AirPricingInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'] &&
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement'] &&
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement']['$'] &&
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement']['$']['Value']}
                                                                                        </li></ul>
                                                                                      </div>
                                                                                    </>
                                                                                  )}
                                                                                </div>
                                                                              </>
                                                                            )
                                                                          )

                                                                      )
                                                                    )
                                                                )}
                                                              </div>
                                                              {is_approved === '1' && (
                                                                <div className='buttonbook' style={{ width: "37%" }}><button type='button' className="continuebutton" style={{ marginTop: "11px", color: "white", backgroundColor: "#785eff", border: "none", padding: "3%", borderRadius: "3px" }} onClick={() => handleselectedContinue(priceParseindex)}>Book Now</button></div>
                                                              )}
                                                            </div>
                                                          )
                                                          ))}
                                                      </div>

                                                    )}

                                                  </div>
                                                  <div className="flt-i-c">
                                                    <div className="flt-i-padding">
                                                      <div className="flt-i-price-i">
                                                        <div className="flt-i-price">
                                                          {pricepoint['air:AirPricingInfo'] && (
                                                            Array.isArray(pricepoint['air:AirPricingInfo']) ? (
                                                              <>
                                                                {(() => {
                                                                  const totalPrice =
                                                                    pricepoint['air:AirPricingInfo'][0]['$']['TotalPrice'];
                                                                  const seatType = cabinClass; // Set the seat type dynamically as needed
                                                                  const fareName = "Base Fare";
                                                                  const airline = inputOrigin;
                                                                  const finalPrice = calculateFinalPrice(totalPrice, markupdata, seatType, fareName, airline, flight_type);

                                                                  return (
                                                                    <>
                                                                      {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                      {finalPrice.toFixed(2)} {/* Show final price with markup applied */}
                                                                    </>
                                                                  );
                                                                })()}
                                                              </>
                                                            ) : (
                                                              <>
                                                                {(() => {
                                                                  const totalPrice =
                                                                    pricepoint['air:AirPricingInfo']['$']['TotalPrice'];
                                                                  const seatType = cabinClass; // Set the seat type dynamically as needed
                                                                  const fareName = "Base Fare";
                                                                  const airline = inputOrigin;
                                                                  const finalPrice = calculateFinalPrice(totalPrice, markupdata, seatType, fareName, airline, flight_type);


                                                                  return (
                                                                    <>
                                                                      {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                      {finalPrice.toFixed(2)} {/* Show final price with markup applied */}
                                                                    </>
                                                                  );
                                                                })()}
                                                              </>
                                                            )
                                                          )}

                                                        </div>
                                                      </div>
                                                      {/* <div className="flt-i-price-b">per adult</div>
                                                        <button type="submit" 
                                                                className="srch-btn" variant="primary">
                                                            <span style={{ fontSize: "12px" }}>View Prices</span>
                                                          </button> */}
                                                      <div className="flt-i-c">
                                                        <div className="flt-i-padding">

                                                          <button type="submit"
                                                            className="srch-btn" variant="primary" style={{ borderRadius: '18px' }}>
                                                            <span style={{ fontSize: "12px" }}>View Prices</span>
                                                          </button>
                                                          <div className="flight-line-b">
                                                            <b onClick={() => handleFlightDetails(priceindex)}>
                                                              {visibleFlightIndex === priceindex ? "Hide Flight Details" : "Show Flight Details"}
                                                            </b>
                                                          </div>
                                                          {visibleFlightIndex === priceindex && (
                                                            <div className="flight-details">
                                                              {/* Render your flight details here */}
                                                            </div>
                                                          )}
                                                          <div className="clear" />

                                                        </div>
                                                      </div>


                                                    </div>
                                                  </div>
                                                  <div className="clear" />
                                                </div>
                                              </div>
                                            ) : (

                                              <div >

                                                <div
                                                  // className={`flight-item fly-in ${selectedFlights.includes(pricepoint['air:AirPricingInfo']) ? "selected-flight" : ""}`}
                                                  className={`flight-item fly-in ${selectedFlights.some(
                                                    (selectedFlight) =>
                                                      selectedFlight["$"].Key === pricepoint["air:AirPricingInfo"]["$"].Key &&
                                                      selectedFlight.isReturn === pricepoint["air:AirPricingInfo"].isReturn
                                                  )
                                                    ? "selected-flight"
                                                    : ""
                                                    }`}
                                                  data-price={6521}
                                                  data-departure={1}
                                                  data-duration={1}
                                                  data-category="myCheckAI checkclassEconomy"
                                                  id="textAI"
                                                  data-category1="stop0"
                                                  style={{ display: "block" }}
                                                >

                                                  <div className="flt-i-a">
                                                    <div className="flt-i-b">
                                                      <div className="flt-i-bb">
                                                        <div className="flt-l-a">
                                                          <div className="flt-l-b">
                                                            <div className="way-lbl">departure</div>
                                                            <div className="company">
                                                              {pricepoint['air:AirPricingInfo'] && (
                                                                Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                  ? (
                                                                    <div style={{ width: '95%' }}>
                                                                      {
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                                            ? (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers9 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex} >
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers9.add(segmentinfo['$']['Carrier']);
                                                                                                }
                                                                                              })}
                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers9).map((carrier, index) => (
                                                                                            <div key={index} style={{ marginLeft: '10%', marginRight: '5%' }}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br/>
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>
                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex} style={{ marginLeft: '10%', marginRight: '5%' }}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"

                                                                                                />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}

                                                                                                </span>
                                                                                              </div>
                                                                                            );
                                                                                          }
                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }

                                                                              </div>
                                                                            ) : (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers10 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex}>
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers10.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}
                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers10).map((carrier, index) => (
                                                                                            <div key={index} style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br/>
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'> 
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex} style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"

                                                                                                />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );

                                                                                          }


                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            )
                                                                        )
                                                                      }

                                                                    </div>
                                                                  ) : (
                                                                    <div style={{ width: '95%' }}>
                                                                      {
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                                            ? (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
                                                                                      {(() => {
                                                                                        const uniqueCarriers11 = new Set();
                                                                                        return (
                                                                                          pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                            <div key={bookingindex} >
                                                                                              {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                  uniqueCarriers11.add(segmentinfo['$']['Carrier']);

                                                                                                }
                                                                                              })}

                                                                                            </div>
                                                                                          )),
                                                                                          Array.from(uniqueCarriers11).map((carrier, index) => (
                                                                                            <div key={index} style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                                                            <div className="airline-container">
                                                                                              <img
                                                                                                className={`airlineimg${index}`}
                                                                                                src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
                                                                                                alt="Airline logo"
                                                                                                width="40px"
                                                                                              />
                                                                                              <span className="flightnumber">{handleAirline(carrier)}</span>
                                                                                              </div><br/>
                                                                                              <div>
                                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                  <div key={bookingindex}>
                                                                                                    {bookingInfo['$'] && SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                                                                                                        return (
                                                                                                          <span key={segmentindex} className='flightnumber'>
                                                                                                            {bookingindex > 0 && ', '}
                                                                                                            {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                                                                                                          </span>
                                                                                                        );
                                                                                                      }
                                                                                                    })}
                                                                                                  </div>
                                                                                                ))}
                                                                                              </div>

                                                                                            </div>
                                                                                          ))
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex} style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"

                                                                                                />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );
                                                                                          }
                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            ) : (
                                                                              <div>
                                                                                {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                                                  ? (
                                                                                    <div>
  {(() => {
    const uniqueCarriers12 = new Set();
    
    // Collect all unique carriers from SegmentList
    pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].forEach(
      (bookingInfo) => {
        if (bookingInfo['$']) {
          SegmentList.forEach((segmentinfo) => {
            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
              uniqueCarriers12.add(segmentinfo['$']['Carrier']);
            }
          });
        }
      }
    );

    return Array.from(uniqueCarriers12).map((carrier, index) => (
      <div key={index} style={{ marginLeft: '5%', marginRight: '5%' }}>
        {/* Airline Logo */}
        <div className="airline-container">
        <img
          className={`airlineimg${index}`}
          src={`https://devapi.taxivaxi.com/airline_logo_images/${carrier}.png`}
          alt="Airline logo"
          width="40px"
        />
        <span className="flightnumber">{handleAirline(carrier)}</span>
        </div>
        <br/>

        {/* Flight Numbers */}
        <div>
          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map(
            (bookingInfo, bookingindex) => (
              <div key={bookingindex}>
                {bookingInfo['$'] &&
                  SegmentList.map((segmentinfo, segmentindex) => {
                    if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef'] && segmentinfo['$']['Carrier'] === carrier) {
                      return (
                        <span key={segmentindex} className="flightnumber">
                          {bookingindex > 0 && ', '}
                          {segmentinfo['$']['Carrier']} {segmentinfo['$']['FlightNumber']}
                        </span>
                      );
                    }
                  })}
              </div>
            )
          )}
        </div>
      </div>
    ));
  })()}
</div>

                                                                                  ) : (
                                                                                    <div>
                                                                                      {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                        SegmentList.map((segmentinfo, segmentindex) => {

                                                                                          if (
                                                                                            segmentinfo['$'] &&
                                                                                            segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                          ) {

                                                                                            return (
                                                                                              <div key={segmentindex} style={{ marginLeft: '5%', marginRight: '5%' }}>
                                                                                                <img
                                                                                                  src={`https://devapi.taxivaxi.com/airline_logo_images/${segmentinfo['$']['Carrier']}.png`}
                                                                                                  alt="Airline logo"
                                                                                                  width="40px"

                                                                                                />
                                                                                                <span className='flightnumber1'>
                                                                                                {handleAirline(segmentinfo['$']['Carrier'])}<br />
                                                                                                  {segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']}
                                                                                                </span>
                                                                                              </div>
                                                                                            );
                                                                                          }
                                                                                        })
                                                                                      }
                                                                                    </div>
                                                                                  )
                                                                                }
                                                                              </div>
                                                                            )
                                                                        )
                                                                      }
                                                                    </div>
                                                                  )
                                                              )
                                                              }

                                                              {/* <button
                                                                      type="button"
                                                                      style={{
                                                                        fontSize: '12px',
                                                                        color: 'blue',
                                                                        marginTop: '10px',
                                                                        marginLeft: '11%',
                                                                      }}
                                                                      className={`add-share-btn ${isFlightSelected ? "added" : ""}`}
                                                                      onClick={() => handleCheckboxChange(pricepoint["air:AirPricingInfo"])}
                                                                    >
                                                                      {isFlightSelected ? "Added - " : "Add to Share + "}
                                                                    </button> */}

                                                            </div>
                                                          </div>

                                                          <div className="flt-l-c">
                                                            <div className="flt-l-cb">
                                                              <div className="flt-l-c-padding">
                                                                <div className="flyght-info-head">
                                                                  <input type="hidden" name="SearchPriceTotalPrice" value={pricepoint['$']['TotalPrice']} />
                                                                  {pricepoint['air:AirPricingInfo'] && (
                                                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                      ? (
                                                                        <div>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                                                ? (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['0'] &&


                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {
                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    <FlightInfoDepartureDate DepartureDate={handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])} onFlightDepartureDateChange={handleFlightDepartureDateChange} />
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })

                                                                                          }


                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    <FlightInfoDepartureDate DepartureDate={handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])} onFlightDepartureDateChange={handleFlightDepartureDateChange} />
                                                                                                  </div>
                                                                                                );
                                                                                              }


                                                                                            })
                                                                                          }


                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                ) : (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>


                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['0'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    <FlightInfoDepartureDate DepartureDate={handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])} onFlightDepartureDateChange={handleFlightDepartureDateChange} />
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    <FlightInfoDepartureDate DepartureDate={handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])} onFlightDepartureDateChange={handleFlightDepartureDateChange} />
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                )
                                                                            )
                                                                          }
                                                                        </div>
                                                                      ) : (
                                                                        <div>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                                                ? (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['0'] &&


                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {
                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    <FlightInfoDepartureDate DepartureDate={handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])} onFlightDepartureDateChange={handleFlightDepartureDateChange} />
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    <FlightInfoDepartureDate DepartureDate={handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])} onFlightDepartureDateChange={handleFlightDepartureDateChange} />
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                ) : (
                                                                                  <div>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['0'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {
                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['0']['$']['SegmentRef']
                                                                                              ) {
                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    <FlightInfoDepartureDate DepartureDate={handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])} onFlightDepartureDateChange={handleFlightDepartureDateChange} />
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      ) : (
                                                                                        <div>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <div key={segmentindex}>
                                                                                                    {segmentinfo['$'] &&
                                                                                                      handleAirline(segmentinfo['$']['Carrier'])
                                                                                                    } &nbsp;
                                                                                                    <FlightInfoDepartureDate DepartureDate={handleflyghtHeadInfoDate(segmentinfo['$']['DepartureTime'])} onFlightDepartureDateChange={handleFlightDepartureDateChange} />
                                                                                                  </div>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </div>
                                                                                      )
                                                                                    }
                                                                                  </div>
                                                                                )
                                                                            )
                                                                          }

                                                                        </div>

                                                                      )
                                                                  )
                                                                  }


                                                                </div>
                                                                {/* // */}
                                                                <div className="flight-line">
                                                                  {pricepoint['air:AirPricingInfo'] && (
                                                                    Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                      ? (
                                                                        <>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                                                ? (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <FlightInfoOrigin
                                                                                                                    origin={handleAirport(segmentinfo['$']['Origin'])}
                                                                                                                    onFlightOriginChange={handleFlightOriginChange}

                                                                                                                  /><br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a" style={{ textAlign: 'center', marginTop: '7%', width: '100px' }}>
                                                                                                <span >
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['$']['TravelTime'])}
                                                                                                </span>
                                                                                                <span className="stop-badge-container">
                                                                                                  <div className="flight-line-a">
                                                                                                    {(() => {
                                                                                                      let totalIterations = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1;
                                                                                                      let stopoverCity = "";
                                                                                                      let flightTimeInMinutes = 0;
                                                                                                      if (totalIterations > 0) {
                                                                                                        pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].forEach((bookingInfo) => {
                                                                                                          SegmentList.map((segmentinfo) => {
                                                                                                            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                              flightTimeInMinutes = flightTimeInMinutes + parseInt(segmentinfo['$']['FlightTime']);
                                                                                                              stopoverCity = handleAirport(segmentinfo['$']['Origin']);
                                                                                                            }
                                                                                                          });
                                                                                                        });
                                                                                                      }
                                                                                                      return (
                                                                                                        <p className="stop-badge" >
                                                                                                          {totalIterations} stop via {stopoverCity}
                                                                                                          <div className="tooltip-content">
                                                                                                            <p style={{ marginBottom: '0px' }}>Plane Change</p>
                                                                                                            {stopoverCity} | {convertMinutesToHours(pricepoint['travelTime'] - flightTimeInMinutes)} Layover
                                                                                                          </div>
                                                                                                        </p>
                                                                                                      );
                                                                                                    })()}
                                                                                                  </div>
                                                                                                </span>
                                                                                              </div>

                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <FlightInfoDestination Destination={handleAirport(segmentinfo['$']['Destination'])} onFlightDestinationChange={handleFlightDestinationChange} /><br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}
                                                                                            </div>
                                                                                          </span>
                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }} >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          <FlightInfoOrigin origin={handleAirport(segmentinfo['$']['Origin'])} onFlightOriginChange={handleFlightOriginChange} /><br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div className="flight-line-a" style={{ textAlign: 'center', marginTop: '7%', width: '100px' }} >
                                                                                                          <span style={{ marginLeft: '5px', marginRight: '5px' }} >
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                          <span className="stop-badge-container">
                                                                                                            <p className="stop-badge">
                                                                                                              Non-Stop
                                                                                                              <div className="tooltip-content">
                                                                                                                This is a direct flight with no stops.
                                                                                                              </div>
                                                                                                            </p>
                                                                                                          </span>
                                                                                                        </div>

                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          <FlightInfoDestination Destination={handleAirport(segmentinfo['$']['Destination'])} onFlightDestinationChange={handleFlightDestinationChange} /><br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                          </span>
                                                                                                      </div>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <FlightInfoOrigin origin={handleAirport(segmentinfo['$']['Origin'])} onFlightOriginChange={handleFlightOriginChange} /><br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"

                                                                                                style={{ textAlign: 'center', marginTop: '7%', width: '100px' }}
                                                                                              >
                                                                                                <span >
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['$']['TravelTime'])}
                                                                                                </span>
                                                                                                <span className="stop-badge-container">
                                                                                                  <div className="flight-line-a">
                                                                                                    {(() => {
                                                                                                      let totalIterations = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1;
                                                                                                      let stopoverCity = "";
                                                                                                      let flightTimeInMinutes = 0;
                                                                                                      if (totalIterations > 0) {
                                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].forEach((bookingInfo) => {
                                                                                                          SegmentList.map((segmentinfo) => {
                                                                                                            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                              flightTimeInMinutes = flightTimeInMinutes + parseInt(segmentinfo['$']['FlightTime']);
                                                                                                              stopoverCity = handleAirport(segmentinfo['$']['Origin']);
                                                                                                            }
                                                                                                          });
                                                                                                        });
                                                                                                      }
                                                                                                      return (
                                                                                                        <p className="stop-badge" >
                                                                                                          {totalIterations} stop via {stopoverCity}
                                                                                                          <div className="tooltip-content">
                                                                                                            <p style={{ marginBottom: '0px' }}>Plane Change</p>
                                                                                                            {stopoverCity} | {convertMinutesToHours(pricepoint['travelTime'] - flightTimeInMinutes)} Layover
                                                                                                          </div>
                                                                                                        </p>
                                                                                                      );
                                                                                                    })()}
                                                                                                  </div>
                                                                                                </span>
                                                                                              </div>

                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <FlightInfoDestination Destination={handleAirport(segmentinfo['$']['Destination'])} onFlightDestinationChange={handleFlightDestinationChange} /><br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                          </span>

                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          <FlightInfoOrigin origin={handleAirport(segmentinfo['$']['Origin'])} onFlightOriginChange={handleFlightOriginChange} /><br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"

                                                                                                          style={{ textAlign: 'center', marginTop: '7%', width: '100px' }}
                                                                                                        >
                                                                                                          <span style={{ marginLeft: '5px', marginRight: '5px' }} >
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                          <span className="stop-badge-container">
                                                                                                            <p className="stop-badge">
                                                                                                              Non-Stop
                                                                                                              <div className="tooltip-content">
                                                                                                                This is a direct flight with no stops.
                                                                                                              </div>
                                                                                                            </p>
                                                                                                          </span>
                                                                                                        </div>

                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          <FlightInfoDestination Destination={handleAirport(segmentinfo['$']['Destination'])} onFlightDestinationChange={handleFlightDestinationChange} /><br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                               </span>
                                                                                                      </div>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                );
                                                                                              }
                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                )
                                                                            )
                                                                          }

                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                                                              Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                                                ? (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <FlightInfoOrigin origin={handleAirport(segmentinfo['$']['Origin'])} onFlightOriginChange={handleFlightOriginChange} /><br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}

                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a"
                                                                                                style={{ textAlign: 'center', marginTop: '7%', width: '100px' }}
                                                                                              >
                                                                                                <span >
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['$']['TravelTime'])}
                                                                                                </span>
                                                                                                <span className="stop-badge-container">
                                                                                                  <div className="flight-line-a">
                                                                                                    {(() => {
                                                                                                      let totalIterations = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].length - 1;
                                                                                                      let stopoverCity = "";
                                                                                                      let flightTimeInMinutes = 0;
                                                                                                      if (totalIterations > 0) {
                                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].forEach((bookingInfo) => {
                                                                                                          SegmentList.map((segmentinfo) => {
                                                                                                            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                              flightTimeInMinutes = flightTimeInMinutes + parseInt(segmentinfo['$']['FlightTime']);
                                                                                                              stopoverCity = handleAirport(segmentinfo['$']['Origin']);
                                                                                                            }
                                                                                                          });
                                                                                                        });
                                                                                                      }
                                                                                                      return (

                                                                                                        <p className="stop-badge" >
                                                                                                          {totalIterations} stop via {stopoverCity}
                                                                                                          <div className="tooltip-content">
                                                                                                            <p style={{ marginBottom: '0px' }}>Plane Change</p>
                                                                                                            {stopoverCity} | {convertMinutesToHours(pricepoint['travelTime'] - flightTimeInMinutes)} Layover
                                                                                                          </div>
                                                                                                        </p>
                                                                                                      );
                                                                                                    })()}
                                                                                                  </div>
                                                                                                </span>
                                                                                              </div>

                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <FlightInfoDestination Destination={handleAirport(segmentinfo['$']['Destination'])} onFlightDestinationChange={handleFlightDestinationChange} /><br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                          </span>

                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          <FlightInfoOrigin origin={handleAirport(segmentinfo['$']['Origin'])} onFlightOriginChange={handleFlightOriginChange} /><br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"

                                                                                                          style={{ textAlign: 'center', marginTop: '7%', width: '100px' }}
                                                                                                        >
                                                                                                          <span style={{ marginLeft: '5px', marginRight: '5px' }} >
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                          <span className="stop-badge-container">
                                                                                                            <p className="stop-badge">
                                                                                                              Non-Stop
                                                                                                              <div className="tooltip-content">
                                                                                                                This is a direct flight with no stops.
                                                                                                              </div>
                                                                                                            </p>
                                                                                                          </span>
                                                                                                        </div>

                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          <FlightInfoDestination Destination={handleAirport(segmentinfo['$']['Destination'])} onFlightDestinationChange={handleFlightDestinationChange} /><br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                             </span>
                                                                                                      </div>
                                                                                                    </span>


                                                                                                  </span>
                                                                                                );
                                                                                              }


                                                                                            })
                                                                                          }


                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                ) : (
                                                                                  <>
                                                                                    {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                                                      Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                                                      ? (
                                                                                        <>
                                                                                          <span className="lineflightss">
                                                                                            <div className="flight-line-a"
                                                                                              style={{ width: 58 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === 0 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <FlightInfoOrigin origin={handleAirport(segmentinfo['$']['Origin'])} onFlightOriginChange={handleFlightOriginChange} /><br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>

                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                            <span className="lineflights">
                                                                                              <div className="flight-line-d1"></div>
                                                                                              <div
                                                                                                className="flight-line-a" style={{ textAlign: 'center', marginTop: '7%', width: '100px' }}
                                                                                              >
                                                                                                <span >
                                                                                                  {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['$']['TravelTime'])}
                                                                                                </span>
                                                                                                <span className="stop-badge-container">
                                                                                                  <div className="flight-line-a">
                                                                                                    {(() => {
                                                                                                      let totalIterations = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1;
                                                                                                      let stopoverCity = "";
                                                                                                      let flightTimeInMinutes = 0;
                                                                                                      {/* let layover = pricepoint['travelTime'] - flightTimeInMinutes; */ }
                                                                                                      if (totalIterations > 0) {
                                                                                                        pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].forEach((bookingInfo) => {
                                                                                                          SegmentList.map((segmentinfo) => {

                                                                                                            if (segmentinfo['$'] && segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']) {
                                                                                                              flightTimeInMinutes = flightTimeInMinutes + parseInt(segmentinfo['$']['FlightTime']);

                                                                                                              stopoverCity = handleAirport(segmentinfo['$']['Origin']);
                                                                                                            }
                                                                                                          });
                                                                                                        });
                                                                                                      }

                                                                                                      return (
                                                                                                        <p className="stop-badge" >
                                                                                                          {totalIterations} stop via {stopoverCity}
                                                                                                          <div className="tooltip-content">
                                                                                                            <p style={{ marginBottom: '0px' }}>Plane Change</p>
                                                                                                            {stopoverCity} | {convertMinutesToHours(pricepoint['travelTime'] - flightTimeInMinutes)} Layover
                                                                                                          </div>
                                                                                                        </p>
                                                                                                      );
                                                                                                    })()}
                                                                                                  </div>
                                                                                                </span>
                                                                                              </div>
                                                                                              <div className="flight-line-d2"></div>
                                                                                            </span>
                                                                                            <div className="flight-line-a" style={{ width: 62 }}>

                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingindex === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].length - 1 &&
                                                                                                    <>
                                                                                                      {bookingInfo['$'] &&
                                                                                                        SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                          if (
                                                                                                            segmentinfo['$'] &&
                                                                                                            segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                          ) {

                                                                                                            return (
                                                                                                              <span key={segmentindex}>
                                                                                                                <b>
                                                                                                                  {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                    hour: 'numeric',
                                                                                                                    minute: 'numeric',
                                                                                                                    hour12: false,
                                                                                                                  })}
                                                                                                                </b>
                                                                                                                <span
                                                                                                                  style={{
                                                                                                                    display: "inline-block",
                                                                                                                    width: 55,
                                                                                                                    whiteSpace: "nowrap",
                                                                                                                    textOverflow: "ellipsis"
                                                                                                                  }}
                                                                                                                >
                                                                                                                  <FlightInfoDestination Destination={handleAirport(segmentinfo['$']['Destination'])} onFlightDestinationChange={handleFlightDestinationChange} /><br />
                                                                                                                  <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>
                                                                                                                </span>
                                                                                                              </span>
                                                                                                            );
                                                                                                          }
                                                                                                        })
                                                                                                      }
                                                                                                    </>
                                                                                                  }
                                                                                                </div>

                                                                                              ))}


                                                                                            </div>
                                                                                          </span>

                                                                                        </>
                                                                                      ) : (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                                                            pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                            SegmentList.map((segmentinfo, segmentindex) => {

                                                                                              if (
                                                                                                segmentinfo['$'] &&
                                                                                                segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                              ) {

                                                                                                return (
                                                                                                  <span key={segmentindex}>
                                                                                                    <span className="lineflightss">
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 58 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          <FlightInfoOrigin origin={handleAirport(segmentinfo['$']['Origin'])} onFlightOriginChange={handleFlightOriginChange} /><br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Origin'])}</span>
                                                                                                        </span>
                                                                                                      </div>
                                                                                                      <span className="lineflights">
                                                                                                        <div className="flight-line-d1"></div>
                                                                                                        <div
                                                                                                          className="flight-line-a"

                                                                                                          style={{ textAlign: 'center', marginTop: '7%', width: '100px' }}
                                                                                                        >
                                                                                                          <span style={{ marginLeft: '5px', marginRight: '5px' }} >
                                                                                                            {calculateTravellingTime(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['$']['TravelTime'])}
                                                                                                          </span>
                                                                                                          <span className="stop-badge-container">
                                                                                                            <p className="stop-badge">
                                                                                                              Non-Stop
                                                                                                              <div className="tooltip-content">
                                                                                                                This is a direct flight with no stops.
                                                                                                              </div>
                                                                                                            </p>
                                                                                                          </span>
                                                                                                        </div>
                                                                                                        <div className="flight-line-d2"></div>
                                                                                                      </span>
                                                                                                      <div
                                                                                                        className="flight-line-a"
                                                                                                        style={{ width: 62 }}
                                                                                                      >
                                                                                                        <b>
                                                                                                          {new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                            hour: 'numeric',
                                                                                                            minute: 'numeric',
                                                                                                            hour12: false,
                                                                                                          })}
                                                                                                          <sup></sup>

                                                                                                        </b>
                                                                                                        <span
                                                                                                          style={{
                                                                                                            display: "inline-block",
                                                                                                            width: 55,
                                                                                                            whiteSpace: "nowrap",
                                                                                                            textOverflow: "ellipsis"
                                                                                                          }}
                                                                                                        >
                                                                                                          <FlightInfoDestination Destination={handleAirport(segmentinfo['$']['Destination'])} onFlightDestinationChange={handleFlightDestinationChange} /> <br />
                                                                                                          <span className="apiairportresult">{handleApiAirport(segmentinfo['$']['Destination'])}</span>                                                                                          </span>
                                                                                                      </div>
                                                                                                    </span>
                                                                                                  </span>
                                                                                                );

                                                                                              }


                                                                                            })
                                                                                          }
                                                                                        </>
                                                                                      )
                                                                                    }
                                                                                  </>
                                                                                )
                                                                            )
                                                                          }

                                                                        </>
                                                                      )
                                                                  )
                                                                  }

                                                                  <div className="flt-i-price">
                                                                    {pricepoint['air:AirPricingInfo'] && (
                                                                      Array.isArray(pricepoint['air:AirPricingInfo']) ? (
                                                                        <>
                                                                          {(() => {
                                                                            const totalPrice =
                                                                              pricepoint['air:AirPricingInfo'][0]['$']['TotalPrice'];
                                                                            const seatType = cabinClass; // Set the seat type dynamically as needed
                                                                            const fareName = "Base Fare";
                                                                            const airline = inputOrigin;
                                                                            const finalPrice = calculateFinalPrice(totalPrice, markupdata, seatType, fareName, airline, flight_type);

                                                                            return (
                                                                              <>
                                                                                {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                {finalPrice.toFixed(2)} {/* Show final price with markup applied */}
                                                                              </>
                                                                            );
                                                                          })()}
                                                                        </>
                                                                      ) : (
                                                                        <>
                                                                          {(() => {
                                                                            const totalPrice =
                                                                              pricepoint['air:AirPricingInfo']['$']['TotalPrice'];
                                                                            const seatType = cabinClass; // Set the seat type dynamically as needed
                                                                            const fareName = "Base Fare";
                                                                            const airline = inputOrigin;
                                                                            const finalPrice = calculateFinalPrice(totalPrice, markupdata, seatType, fareName, airline, flight_type);

                                                                            return (
                                                                              <>
                                                                                {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                {finalPrice.toFixed(2)} {/* Show final price with markup applied */}
                                                                              </>
                                                                            );
                                                                          })()}
                                                                        </>
                                                                      )
                                                                    )}


                                                                  </div>



                                                                  <div className="clear" />
                                                                  {/* // flight details // */}
                                                                  <div className="flight-details" style={{ display: visibleFlightIndex === priceindex ? 'block' : 'none' }}>
                                                                    <Nav justify variant="tabs" className="flight_nav" defaultActiveKey={`Flight_Details${priceindex}`} activeKey={activeTab} onSelect={setActiveTab}>
                                                                      <Nav.Item>
                                                                        <Nav.Link eventKey={`Flight_Detail${priceindex}`} className={activeTab.includes(`Flight_Detail${priceindex}`) ? 'active' : ''}>Flight Detail</Nav.Link>
                                                                      </Nav.Item>
                                                                      <Nav.Item>
                                                                        <Nav.Link eventKey={`Fare_Summary${priceindex}`}>Fare Summary</Nav.Link>
                                                                      </Nav.Item>
                                                                      <Nav.Item>
                                                                        <Nav.Link eventKey={`Date_Change${priceindex}`}>Date Change</Nav.Link>
                                                                      </Nav.Item>
                                                                      <Nav.Item>
                                                                        <Nav.Link eventKey={`Cancellation${priceindex}`}>Cancellation</Nav.Link>
                                                                      </Nav.Item>
                                                                    </Nav>
                                                                    {pricepoint['air:AirPricingInfo'] && (
                                                                      Array.isArray(pricepoint['air:AirPricingInfo'])
                                                                        ? (
                                                                          <div>
                                                                            <div id={`Flight_Detail${priceindex}`} className="tabcontent" style={activeTab.includes(`Flight_Detail${priceindex}`) ? { display: 'block' } : { display: 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList'] &&
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption'] &&
                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                                                    ? (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d">
                                                                                                                <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])

                                                                                                                }

                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }

                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}
                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );
                                                                                                  }
                                                                                                })
                                                                                              }
                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d">
                                                                                                                <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                                }
                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo'][0]['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }
                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );
                                                                                                  }
                                                                                                })
                                                                                              }
                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    )
                                                                                )
                                                                              }
                                                                            </div>
                                                                            <div id={`Fare_Summary${priceindex}`} className="tabcontent" style={{ display: activeTab === `Fare_Summary${priceindex}` ? 'block' : 'none' }}>

                                                                              <div className="flight-details-a">
                                                                                Fare Breakup (For All Passengers)
                                                                              </div>

                                                                              <div className="flight-details-l">
                                                                                <div className="flight-details-b">
                                                                                  Total Fare
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  Base Price
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  Surcharge
                                                                                </div>
                                                                              </div>
                                                                              <div className="flight-details-r">
                                                                                <div className="flight-details-b">
                                                                                  {pricepoint.$.TotalPrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.TotalPrice.replace('INR', '')}
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.BasePrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.BasePrice.replace('INR', '')}
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.Taxes.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.Taxes.replace('INR', '')}
                                                                                </div>
                                                                              </div>
                                                                              <div className="clear" />
                                                                            </div>
                                                                            <div id={`Date_Change${priceindex}`} className="tabcontent" style={{ display: activeTab === `Date_Change${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    {flightorigins} to {flightdestinations} ||  &nbsp;
                                                                                    {flightDepartureDates}
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:ChangePenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline fee provided is indicative. CoTrav does not guarantee the exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                            <div id={`Cancellation${priceindex}`} className="tabcontent" style={{ display: activeTab === `Cancellation${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    {flightorigins} to {flightdestinations} ||  &nbsp;
                                                                                    {flightDepartureDates}
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo'][0]['air:CancelPenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline fee provided is indicative. CoTrav does not guarantee the exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        ) : (
                                                                          <div>
                                                                            <div id={`Flight_Detail${priceindex}`} className="tabcontent" style={activeTab.includes(`Flight_Detail${priceindex}`) ? { display: 'block' } : { display: 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList'] &&
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption'] &&
                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'] && (
                                                                                  Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'])
                                                                                    ? (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {
                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d">
                                                                                                                <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                                }
                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>
                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
  {segmentinfo?.['$'] && handleApiAirport(segmentinfo['$']['Origin'])}

  {segmentinfo?.['air:FlightDetailsRef']?.['$']?.['Key'] &&
    flightDetails?.map((flightdetailinfo, flightdetailindex) => {
      if (flightdetailinfo?.['$']?.['Key'] && 
          segmentinfo['air:FlightDetailsRef']?.['$']?.['Key'] === flightdetailinfo['$']['Key']) {
        return (
          <span key={flightdetailindex}>
            {flightdetailinfo?.['$']?.['OriginTerminal'] && `.T-${flightdetailinfo['$']['OriginTerminal']}`}
          </span>
        );
      }
      return null; // Prevent React warning for missing return
    })}
</div>

                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
  {segmentinfo?.['$']?.['Destination'] && handleApiAirport(segmentinfo['$']['Destination'])}

  {segmentinfo?.['air:FlightDetailsRef']?.['$']?.['Key'] &&
    flightDetails?.map((flightdetailinfo, flightdetailindex) => {
      if (flightdetailinfo?.['$']?.['Key'] && 
          segmentinfo?.['air:FlightDetailsRef']?.['$']?.['Key'] === flightdetailinfo['$']['Key']) {
        return (
          <span key={flightdetailindex}>
            {flightdetailinfo?.['$']?.['DestinationTerminal'] && 
              `.T-${flightdetailinfo['$']['DestinationTerminal']}`}
          </span>
        );
      }
      return null; // Prevent React warning for missing return
    })}
</div>

                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }


                                                                                                </div>
                                                                                              ))}


                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {

                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {
                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option'][0]['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }
                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
  {/* Check if segmentinfo.$ exists before accessing Origin */}
  {segmentinfo?.['$']?.['Origin'] && handleApiAirport(segmentinfo['$']['Origin'])}

  {/* Ensure segmentinfo.air:FlightDetailsRef and its properties exist before accessing */}
  {segmentinfo?.['air:FlightDetailsRef']?.['$']?.['Key'] &&
    flightDetails?.map((flightdetailinfo, flightdetailindex) => {
      if (
        flightdetailinfo?.['$']?.['Key'] &&
        segmentinfo?.['air:FlightDetailsRef']?.['$']?.['Key'] === flightdetailinfo['$']['Key']
      ) {
        return (
          <span key={flightdetailindex}>
            {flightdetailinfo?.['$']?.['OriginTerminal'] &&
              `.T-${flightdetailinfo['$']['OriginTerminal']}`}
          </span>
        );
      }
      return null; // Prevent React warning for missing return
    })}
</div>

                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
  {/* Ensure segmentinfo.$ exists before accessing Destination */}
  {segmentinfo?.['$']?.['Destination'] && handleApiAirport(segmentinfo['$']['Destination'])}

  {/* Ensure segmentinfo.air:FlightDetailsRef and its properties exist before accessing */}
  {segmentinfo?.['air:FlightDetailsRef']?.['$']?.['Key'] &&
    flightDetails?.map((flightdetailinfo, flightdetailindex) => {
      if (
        flightdetailinfo?.['$']?.['Key'] &&
        segmentinfo?.['air:FlightDetailsRef']?.['$']?.['Key'] === flightdetailinfo['$']['Key']
      ) {
        return (
          <span key={flightdetailindex}>
            {flightdetailinfo?.['$']?.['DestinationTerminal'] &&
              `.T-${flightdetailinfo['$']['DestinationTerminal']}`}
          </span>
        );
      }
      return null; // Prevent React warnings for missing return
    })}
</div>

                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );
                                                                                                  }
                                                                                                })
                                                                                              }
                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div>
                                                                                        {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'] &&
                                                                                          Array.isArray(pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'])
                                                                                          ? (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'].map((bookingInfo, bookingindex) => (
                                                                                                <div key={bookingindex}>
                                                                                                  {bookingInfo['$'] &&
                                                                                                    SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                      if (
                                                                                                        segmentinfo['$'] &&
                                                                                                        segmentinfo['$']['Key'] === bookingInfo['$']['SegmentRef']
                                                                                                      ) {

                                                                                                        return (
                                                                                                          <div key={segmentindex}>

                                                                                                            <div>
                                                                                                              <div className="flight-details-d">
                                                                                                                <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                                <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfo)} />
                                                                                                              </div>
                                                                                                              <div className="flight-details-a">
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handleAirline(segmentinfo['$']['Carrier'])
                                                                                                                } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                                &nbsp;

                                                                                                                {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                                {segmentinfo['$'] &&
                                                                                                                  handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                                }
                                                                                                                <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>


                                                                                                              </div>

                                                                                                              <div className="clear" />
                                                                                                              <div className="flight-details-l">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-m">
                                                                                                                <div className="flight-details-e">
                                                                                                                  {segmentinfo['$']
                                                                                                                    && (() => {
                                                                                                                      const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                      const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                      const minutes = flightTimeInMinutes % 60;
                                                                                                                      const formattedHours = String(hours).padStart(2, '0');
                                                                                                                      const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                      const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                      return formattedFlightTime;
                                                                                                                    })
                                                                                                                      ()}

                                                                                                                </div>
                                                                                                                <div className="flight-details-e">
                                                                                                                  <hr />
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="flight-details-r">
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                                </div>
                                                                                                                <div className="flight-details-b">
                                                                                                                  {segmentinfo['$'] &&
                                                                                                                    handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                                  }
                                                                                                                </div>
                                                                                                                <div className="flight-details-c">
                                                                                                                  {
                                                                                                                    segmentinfo['$'] &&
                                                                                                                    new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                      hour: 'numeric',
                                                                                                                      minute: 'numeric',
                                                                                                                      hour12: false,
                                                                                                                    })
                                                                                                                  }

                                                                                                                </div>
                                                                                                                <div className="flight-details-c1">
                                                                                                                  {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                                  {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                    flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                      if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                        return (
                                                                                                                          <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                            `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                        );
                                                                                                                      }
                                                                                                                    })
                                                                                                                  }
                                                                                                                </div>
                                                                                                              </div>
                                                                                                              <div className="clear" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        );
                                                                                                      }
                                                                                                    })
                                                                                                  }
                                                                                                </div>
                                                                                              ))}
                                                                                            </div>
                                                                                          ) : (
                                                                                            <div>
                                                                                              {pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$'] &&
                                                                                                pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef'] &&
                                                                                                SegmentList.map((segmentinfo, segmentindex) => {
                                                                                                  if (
                                                                                                    segmentinfo['$'] &&
                                                                                                    segmentinfo['$']['Key'] === pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo']['$']['SegmentRef']
                                                                                                  ) {

                                                                                                    const bookingInfoo = pricepoint['air:AirPricingInfo']['air:FlightOptionsList']['air:FlightOption']['air:Option']['air:BookingInfo'];

                                                                                                    return (
                                                                                                      <div key={segmentindex}>

                                                                                                        <div>
                                                                                                          <div className="flight-details-d">
                                                                                                            <input type="hidden" name="Segmentarray[]" value={JSON.stringify(segmentinfo)} />
                                                                                                            <input type="hidden" name="BookingInfoData[]" value={JSON.stringify(bookingInfoo)} />
                                                                                                          </div>
                                                                                                          <div className="flight-details-a">
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handleAirline(segmentinfo['$']['Carrier'])
                                                                                                            } . &nbsp;{segmentinfo['$']['Carrier']}{segmentinfo['$']['FlightNumber']} &nbsp;||
                                                                                                            &nbsp;

                                                                                                            {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])} to {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])} , &nbsp;
                                                                                                            {segmentinfo['$'] &&
                                                                                                              handledatemonth(segmentinfo['$']['DepartureTime'])
                                                                                                            }

                                                                                                            <span className="equipmentno">{segmentinfo['$']['Equipment']}</span>

                                                                                                          </div>

                                                                                                          <div className="clear" />
                                                                                                          <div className="flight-details-l">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Origin'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['DepartureTime'])
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['DepartureTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Origin'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['OriginTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['OriginTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-m">
                                                                                                            <div className="flight-details-e">
                                                                                                              {segmentinfo['$']
                                                                                                                && (() => {
                                                                                                                  const flightTimeInMinutes = parseInt(segmentinfo['$']['FlightTime']);
                                                                                                                  const hours = Math.floor(flightTimeInMinutes / 60);
                                                                                                                  const minutes = flightTimeInMinutes % 60;
                                                                                                                  const formattedHours = String(hours).padStart(2, '0');
                                                                                                                  const formattedMinutes = String(minutes).padStart(2, '0');
                                                                                                                  const formattedFlightTime = `${formattedHours}h ${formattedMinutes}m`;
                                                                                                                  return formattedFlightTime;
                                                                                                                })
                                                                                                                  ()}

                                                                                                            </div>
                                                                                                            <div className="flight-details-e">
                                                                                                              <hr />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="flight-details-r">
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] && handleAirport(segmentinfo['$']['Destination'])}

                                                                                                            </div>
                                                                                                            <div className="flight-details-b">
                                                                                                              {segmentinfo['$'] &&
                                                                                                                handleweekdatemonthyear(segmentinfo['$']['ArrivalTime'])
                                                                                                              }
                                                                                                            </div>
                                                                                                            <div className="flight-details-c">
                                                                                                              {
                                                                                                                segmentinfo['$'] &&
                                                                                                                new Date(segmentinfo['$']['ArrivalTime']).toLocaleTimeString('en-US', {
                                                                                                                  hour: 'numeric',
                                                                                                                  minute: 'numeric',
                                                                                                                  hour12: false,
                                                                                                                })
                                                                                                              }

                                                                                                            </div>
                                                                                                            <div className="flight-details-c1">
                                                                                                              {segmentinfo['$'] && handleApiAirport(segmentinfo['$']['Destination'])}
                                                                                                              {segmentinfo['air:FlightDetailsRef']['$'] && segmentinfo['air:FlightDetailsRef']['$']['Key'] &&
                                                                                                                flightDetails && flightDetails.map((flightdetailinfo, flightdetailindex) => {
                                                                                                                  if (segmentinfo['air:FlightDetailsRef']['$']['Key'] === flightdetailinfo['$']['Key']) {
                                                                                                                    return (
                                                                                                                      <span key={flightdetailindex}>{flightdetailinfo['$'] && flightdetailinfo['$']['DestinationTerminal'] &&
                                                                                                                        `.T-${flightdetailinfo['$']['DestinationTerminal']}`}</span>
                                                                                                                    );
                                                                                                                  }
                                                                                                                })
                                                                                                              }
                                                                                                            </div>
                                                                                                          </div>
                                                                                                          <div className="clear" />
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    );
                                                                                                  }
                                                                                                })
                                                                                              }
                                                                                            </div>
                                                                                          )
                                                                                        }
                                                                                      </div>
                                                                                    )
                                                                                )
                                                                              }
                                                                            </div>
                                                                            <div id={`Fare_Summary${priceindex}`} className="tabcontent" style={{ display: activeTab === `Fare_Summary${priceindex}` ? 'block' : 'none' }}>

                                                                              <div className="flight-details-a">
                                                                                Fare Breakup (For All Passengers)
                                                                              </div>

                                                                              <div className="flight-details-l">
                                                                                <div className="flight-details-b">
                                                                                  Total Fare
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  Base Price
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Category"]}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Category"]}
                                                                                    </div>
                                                                                  )}

                                                                                <div className="flight-details-c">
                                                                                  Surcharge
                                                                                </div>
                                                                              </div>
                                                                              <div className="flight-details-r">
                                                                                <div className="flight-details-b">
                                                                                  {pricepoint.$.TotalPrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.TotalPrice.replace('INR', '')}
                                                                                </div>
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.BasePrice.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.BasePrice.replace('INR', '')}
                                                                                </div>
                                                                                {Array.isArray(pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"])
                                                                                  ? pricepoint["air:AirPricingInfo"]["air:TaxInfo"].map((tax, index) => (
                                                                                    <div key={index} className="flight-details-c">
                                                                                      {tax["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {tax["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  ))
                                                                                  : pricepoint["air:AirPricingInfo"]?.["air:TaxInfo"] && (
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].includes("INR") ? "₹ " : ""}
                                                                                      {pricepoint["air:AirPricingInfo"]["air:TaxInfo"]["$"]["Amount"].replace("INR", "")}
                                                                                    </div>
                                                                                  )}
                                                                                <div className="flight-details-c">
                                                                                  {pricepoint.$.Taxes.includes('INR') ? '₹ ' : ''}
                                                                                  {pricepoint.$.Taxes.replace('INR', '')}
                                                                                </div>
                                                                              </div>
                                                                              <div className="clear" />
                                                                            </div>
                                                                            <div id={`Date_Change${priceindex}`} className="tabcontent" style={{ display: activeTab === `Date_Change${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:ChangePenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    {flightorigins} to {flightdestinations} ||  &nbsp;
                                                                                    {flightDepartureDates}
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )
                                                                                      }
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}
                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo']['air:ChangePenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline fee provided is indicative. CoTrav does not guarantee the exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                            <div id={`Cancellation${priceindex}`} className="tabcontent" style={{ display: activeTab === `Cancellation${priceindex}` ? 'block' : 'none' }}>
                                                                              {
                                                                                pricepoint['air:AirPricingInfo']['air:CancelPenalty'] &&
                                                                                <div>

                                                                                  <div className="flight-details-a">
                                                                                    {flightorigins} to {flightdestinations} ||  &nbsp;
                                                                                    {flightDepartureDates}
                                                                                  </div>

                                                                                  <div className="flight-details-l">
                                                                                    <div className="flight-details-b">
                                                                                      Time Frame
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$'] &&
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$']['PenaltyApplies'] ? (
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['$']['PenaltyApplies']
                                                                                      ) : (
                                                                                        'NA'
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="flight-details-r">
                                                                                    <div className="flight-details-b">
                                                                                      Airline Fee.
                                                                                    </div>
                                                                                    <div className="flight-details-c">
                                                                                      {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Amount'] ? (
                                                                                        <>
                                                                                          {pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Amount'].includes('INR') ? '₹ ' : ''}

                                                                                        </>
                                                                                      ) : (
                                                                                        pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Percentage'] ? (
                                                                                          pricepoint['air:AirPricingInfo']['air:CancelPenalty']['air:Percentage']
                                                                                        ) : (
                                                                                          'NA'
                                                                                        )
                                                                                      )}
                                                                                    </div>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                  <br className="clear" />
                                                                                  <div className="flight-details-c">
                                                                                    <mark>
                                                                                      *Please note that the airline fee provided is indicative. CoTrav does not guarantee the exact amount of the fee.
                                                                                    </mark>
                                                                                  </div>
                                                                                  <div className="clear" />
                                                                                </div>
                                                                              }
                                                                            </div>
                                                                          </div>
                                                                        )
                                                                    )
                                                                    }
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <br className="clear" />
                                                          </div>
                                                        </div>
                                                        <div className="clear" />
                                                      </div>
                                                      <br className="clear" />
                                                    </div>

                                                    {isDropdownVisible && selectedPriceIndex === priceindex && (
                                                      <div className="row selectcontainer">
                                                        {loading ? (
                                                          <div className="loader custom-loader item-center justify-center flex items-center">
                                                            <img className="loader-giff" style={{ width: '5rem', height: '5rem' }} src="/img/cotravloader.gif" alt="Loader" />
                                                            <p className="text-center ml-4 text-gray-600">
                                                            </p>
                                                          </div>
                                                        ) : (
                                                          (fareInfoRefsState && fareInfoRefsState.length > 0 ? (
                                                            fareInfoRefsState.map((fareInfoRefKey, fareInfoIndex) => {
                                                              const extractedFareInfoRef = fareInfoRefKey['FareInfoRef'];
                                                              const matchingFareInfo = FareList.find(
                                                                (fareInfo) => fareInfo['$'] && fareInfo['$']['Key'] === extractedFareInfoRef
                                                              );
                                                              if (matchingFareInfo) {
                                                                return (
                                                                  <div
                                                                    key={fareInfoIndex}
                                                                    className="col-md-3 optionsflights"
                                                                    style={{ display: "flex", width: '27%' }}
                                                                  >
                                                                    <div className="optionsflight">
                                                                      <div className="modal-data">
                                                                        {/* Display FareFamily as the Header */}
                                                                        <div className="seelctheader">
                                                                          {matchingFareInfo['$']['FareFamily']}
                                                                        </div>
                                                                        <div className="selectprice">
                                                                          {(() => {
                                                                            // Use Amount from matchingFareInfo for price
                                                                            const totalAmount = matchingFareInfo['$']['Amount'];
                                                                            const numericTotalPrice = totalAmount
                                                                              .replace("INR", "")
                                                                              .trim(); // Extract numeric part of price
                                                                            const airline = inputOrigin;
                                                                            const calculatedPrice = calculateFinalPrice(
                                                                              numericTotalPrice,
                                                                              markupdata,
                                                                              cabinClass,
                                                                              matchingFareInfo['$']['FareFamily'] // Pass the fare family name
                                                                              , airline, flight_type
                                                                            );

                                                                            return (
                                                                              <>
                                                                                {totalAmount.includes("INR") ? "₹ " : ""}
                                                                                {calculatedPrice}
                                                                              </>
                                                                            );
                                                                          })()}
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    {is_approved === '1'  && (
                                                                    
                                                                      <div className='buttonbook' ><button type='button' className="continuebutton" style={{ marginTop: "5px", color: "white", backgroundColor: "#785eff", border: "none", padding: "4px 10px", fontSize: '14px', marginLeft: '7px', marginRight: '5px', borderRadius: "3px" }} onClick={() => handleach(fareInfoRefKey)}>Book Now</button></div>
                                                                    )}
                                                                    <button
                                                                      className="add-btn"
                                                                      type="button"
                                                                      title="Share Flight Option"
                                                                      onClick={() => handleCheckboxChange(pricepoint["air:AirPricingInfo"], matchingFareInfo["$"]["Amount"], matchingFareInfo["$"]["FareFamily"])}
                                                                    >
                                                                      {
                                                                        selectedFlights.some((flight) => {
                                                                          return (
                                                                            flight["$"]["Key"] === pricepoint["air:AirPricingInfo"]["$"]["Key"] &&
                                                                            flight.fare_details.some(
                                                                              (fare) =>
                                                                                fare.fare_type === matchingFareInfo["$"]["FareFamily"] &&
                                                                                fare.price === matchingFareInfo["$"]["Amount"]
                                                                            )
                                                                          );
                                                                        }) ? '-' : '+'
                                                                      }
                                                                    </button>
                                                                  </div>
                                                                );
                                                              }
                                                              return null; // Skip if no matching FareInfo
                                                            })
                                                          ) : (
                                                            priceParse && priceParse.map((priceParseData, priceParseindex) => (

                                                              <div key={priceParseindex} className='col-md-3 optionsflights' style={{ display: "flex" }}>
                                                                <div className='optionsflight' style={{ width: "63%" }}>
                                                                  {priceParseData['air:AirPricingInfo'] && (
                                                                    Array.isArray(priceParseData['air:AirPricingInfo'])
                                                                      ? (
                                                                        priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] && (
                                                                          Array.isArray(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'])
                                                                            ? (
                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['air:Text'] ? (
                                                                                Array.isArray(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['air:Text'])
                                                                                  ? (
                                                                                    <div className="modal-data">
                                                                                      <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                        <button
                                                                                          type="button"
                                                                                          onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                          style={{
                                                                                            border: "none",
                                                                                            background: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            color: "#785eff",
                                                                                          }}
                                                                                          aria-label="Toggle Details"
                                                                                        >
                                                                                          <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                        </button></div>
                                                                                      <div className="selectprice">
                                                                                        {(() => {
                                                                                          const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                          const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                          const airline = inputOrigin;
                                                                                          const calculatedPrice = calculateFinalPrice(
                                                                                            numericTotalPrice,
                                                                                            markupdata,
                                                                                            cabinClass,
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                            , airline, flight_type
                                                                                          );

                                                                                          return (
                                                                                            <>
                                                                                              {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                              {calculatedPrice}
                                                                                            </>
                                                                                          );
                                                                                        })()}
                                                                                      </div>
                                                                                      {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                        priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['air:Text'].map((textinfor, textindex) => {
                                                                                          if (
                                                                                            textinfor['$'] &&
                                                                                            textinfor['$']['Type'] === "MarketingConsumer"
                                                                                          ) {

                                                                                            const infoArray = textinfor['_'].split('\n').filter(item => item.trim() !== '');

                                                                                            return (
                                                                                              <>
                                                                                                <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                                <div key={textindex} className="selectdetail">
                                                                                                  <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                                  <ul>
                                                                                                    {infoArray.map((item, index) => (
                                                                                                      <li key={index}>{item.trim()}</li>
                                                                                                    ))}
                                                                                                  </ul>
                                                                                                </div>
                                                                                              </>
                                                                                            );
                                                                                          }

                                                                                        })
                                                                                      )}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <>
                                                                                      <div>
                                                                                        <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                          <button
                                                                                            type="button"
                                                                                            onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                            style={{
                                                                                              border: "none",
                                                                                              background: "none",
                                                                                              cursor: "pointer",
                                                                                              fontSize: "16px",
                                                                                              color: "#785eff",
                                                                                            }}
                                                                                            aria-label="Toggle Details"
                                                                                          >
                                                                                            <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                          </button></div>
                                                                                        <div className="selectprice">
                                                                                          {(() => {
                                                                                            const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                            const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                            const airline = inputOrigin;
                                                                                            const calculatedPrice = calculateFinalPrice(
                                                                                              numericTotalPrice,
                                                                                              markupdata,
                                                                                              cabinClass,
                                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                              , airline, flight_type
                                                                                            );

                                                                                            return (
                                                                                              <>
                                                                                                {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                                {calculatedPrice}
                                                                                              </>
                                                                                            );
                                                                                          })()}
                                                                                        </div>
                                                                                        {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                          <>
                                                                                            <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                            <div className="selectdetail">
                                                                                              <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                              <ul><li>
                                                                                                {priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] && priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0] &&
                                                                                                  priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement'] &&
                                                                                                  priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement']['$'] &&
                                                                                                  priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement']['$']['Value']}
                                                                                              </li></ul>
                                                                                            </div>
                                                                                          </>
                                                                                        )}
                                                                                      </div>
                                                                                    </>
                                                                                  )
                                                                              ) : (
                                                                                <>
                                                                                  <div>
                                                                                    <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                      <button
                                                                                        type="button"
                                                                                        onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                        style={{
                                                                                          border: "none",
                                                                                          background: "none",
                                                                                          cursor: "pointer",
                                                                                          fontSize: "16px",
                                                                                          color: "#785eff",
                                                                                        }}
                                                                                        aria-label="Toggle Details"
                                                                                      >
                                                                                        <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                      </button></div>

                                                                                    <div className="selectprice">
                                                                                      {(() => {
                                                                                        const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                        const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                        const airline = inputOrigin;
                                                                                        const calculatedPrice = calculateFinalPrice(
                                                                                          numericTotalPrice,
                                                                                          markupdata,
                                                                                          cabinClass,
                                                                                          priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                          , airline, flight_type
                                                                                        );

                                                                                        return (
                                                                                          <>
                                                                                            {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                            {calculatedPrice}
                                                                                          </>
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                    {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                      <>
                                                                                        <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                        <div className="selectdetail">
                                                                                          <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                          <ul><li>
                                                                                            {priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] && priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0] &&
                                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement'] &&
                                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement']['$'] &&
                                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo'][0]['common_v52_0:Endorsement']['$']['Value']}
                                                                                          </li></ul>
                                                                                        </div>
                                                                                      </>
                                                                                    )}
                                                                                  </div>
                                                                                </>
                                                                              )

                                                                            ) : (
                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['air:Text'] ? (
                                                                                Array.isArray(priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['air:Text'])
                                                                                  ? (
                                                                                    <div>
                                                                                      <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                        <button
                                                                                          type="button"
                                                                                          onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                          style={{
                                                                                            border: "none",
                                                                                            background: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            color: "#785eff",
                                                                                          }}
                                                                                          aria-label="Toggle Details"
                                                                                        >
                                                                                          <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                        </button></div>

                                                                                      <div className="selectprice">
                                                                                        {(() => {
                                                                                          const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                          const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                          const airline = inputOrigin;
                                                                                          const calculatedPrice = calculateFinalPrice(
                                                                                            numericTotalPrice,
                                                                                            markupdata,
                                                                                            cabinClass,
                                                                                            priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                            , airline, flight_type
                                                                                          );

                                                                                          return (
                                                                                            <>
                                                                                              {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                              {calculatedPrice}
                                                                                            </>
                                                                                          );
                                                                                        })()}
                                                                                      </div>
                                                                                      {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                        priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['air:Text'].map((textinfor, textindex) => {
                                                                                          if (
                                                                                            textinfor['$'] &&
                                                                                            textinfor['$']['Type'] === "MarketingConsumer"
                                                                                          ) {

                                                                                            const infoArray = textinfor['_'].split('\n').filter(item => item.trim() !== '');

                                                                                            return (
                                                                                              <>
                                                                                                <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                                <div key={textindex} className="selectdetail">
                                                                                                  <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                                  <ul>
                                                                                                    {infoArray.map((item, index) => (
                                                                                                      <li key={index}>{item.trim()}</li>
                                                                                                    ))}
                                                                                                  </ul>
                                                                                                </div>
                                                                                              </>
                                                                                            );
                                                                                          }

                                                                                        })
                                                                                      )}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <>
                                                                                      <div>
                                                                                        <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                          <button
                                                                                            type="button"
                                                                                            onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                            style={{
                                                                                              border: "none",
                                                                                              background: "none",
                                                                                              cursor: "pointer",
                                                                                              fontSize: "16px",
                                                                                              color: "#785eff",
                                                                                            }}
                                                                                            aria-label="Toggle Details"
                                                                                          >
                                                                                            <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                          </button></div>

                                                                                        <div className="selectprice">
                                                                                          {(() => {
                                                                                            const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                            const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                            const airline = inputOrigin;
                                                                                            const calculatedPrice = calculateFinalPrice(
                                                                                              numericTotalPrice,
                                                                                              markupdata,
                                                                                              cabinClass,
                                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                              , airline, flight_type
                                                                                            );

                                                                                            return (
                                                                                              <>
                                                                                                {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                                {calculatedPrice}
                                                                                              </>
                                                                                            );
                                                                                          })()}
                                                                                        </div>
                                                                                        {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                          <>
                                                                                            <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                            <div className="selectdetail">
                                                                                              <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                              <ul><li>
                                                                                                {priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] &&
                                                                                                  priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement'] &&
                                                                                                  priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement']['$'] &&
                                                                                                  priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement']['$']['Value']}
                                                                                              </li></ul>
                                                                                            </div>
                                                                                          </>
                                                                                        )}
                                                                                      </div>
                                                                                    </>
                                                                                  )
                                                                              ) : (
                                                                                <>
                                                                                  <div>
                                                                                    <div className="seelctheader">{priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                      <button
                                                                                        type="button"
                                                                                        onClick={() => toggleDetails(priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                        style={{
                                                                                          border: "none",
                                                                                          background: "none",
                                                                                          cursor: "pointer",
                                                                                          fontSize: "16px",
                                                                                          color: "#785eff",
                                                                                        }}
                                                                                        aria-label="Toggle Details"
                                                                                      >
                                                                                        <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                      </button></div>

                                                                                    <div className="selectprice">
                                                                                      {(() => {
                                                                                        const totalPrice = priceParseData['air:AirPricingInfo'][0].$.TotalPrice;
                                                                                        const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                        const airline = inputOrigin;
                                                                                        const calculatedPrice = calculateFinalPrice(
                                                                                          numericTotalPrice,
                                                                                          markupdata,
                                                                                          cabinClass,
                                                                                          priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                          , airline, flight_type
                                                                                        );

                                                                                        return (
                                                                                          <>
                                                                                            {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                            {calculatedPrice}
                                                                                          </>
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                    {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                      <>
                                                                                        <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                        <div className="selectdetail">
                                                                                          <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                          <ul><li>
                                                                                            {priceParseData['air:AirPricingInfo'][0]['air:FareInfo'] &&
                                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement'] &&
                                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement']['$'] &&
                                                                                              priceParseData['air:AirPricingInfo'][0]['air:FareInfo']['common_v52_0:Endorsement']['$']['Value']}
                                                                                          </li></ul>
                                                                                        </div>
                                                                                      </>
                                                                                    )}
                                                                                  </div>
                                                                                </>
                                                                              )
                                                                            )

                                                                        )

                                                                      ) : (
                                                                        priceParseData['air:AirPricingInfo']['air:FareInfo'] && (
                                                                          Array.isArray(priceParseData['air:AirPricingInfo']['air:FareInfo'])
                                                                            ? (
                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['air:Text'] ? (
                                                                                Array.isArray(priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['air:Text'])
                                                                                  ? (
                                                                                    <div>
                                                                                      <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                        <button
                                                                                          type="button"
                                                                                          onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                          style={{
                                                                                            border: "none",
                                                                                            background: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            color: "#785eff",
                                                                                          }}
                                                                                          aria-label="Toggle Details"
                                                                                        >
                                                                                          <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                        </button></div>
                                                                                      <div className="selectprice">
                                                                                        {(() => {
                                                                                          const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                          const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                          const airline = inputOrigin;
                                                                                          const calculatedPrice = calculateFinalPrice(
                                                                                            numericTotalPrice,
                                                                                            markupdata,
                                                                                            cabinClass,
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                            , airline, flight_type
                                                                                          );

                                                                                          return (
                                                                                            <>
                                                                                              {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                              {calculatedPrice}
                                                                                            </>
                                                                                          );
                                                                                        })()}
                                                                                      </div>
                                                                                      {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                        priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['air:Text'].map((textinfor, textindex) => {
                                                                                          if (
                                                                                            textinfor['$'] &&
                                                                                            textinfor['$']['Type'] === "MarketingConsumer"
                                                                                          ) {

                                                                                            const infoArray = textinfor['_'].split('\n').filter(item => item.trim() !== '');

                                                                                            return (
                                                                                              <>
                                                                                                <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                                <div key={textindex} className="selectdetail">
                                                                                                  <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                                  <ul>
                                                                                                    {infoArray.map((item, index) => (
                                                                                                      <li key={index}>{item.trim()}</li>
                                                                                                    ))}
                                                                                                  </ul>
                                                                                                </div>
                                                                                              </>
                                                                                            );
                                                                                          }

                                                                                        })
                                                                                      )}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <>
                                                                                      <div>
                                                                                        <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                          <button
                                                                                            type="button"
                                                                                            onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                            style={{
                                                                                              border: "none",
                                                                                              background: "none",
                                                                                              cursor: "pointer",
                                                                                              fontSize: "16px",
                                                                                              color: "#785eff",
                                                                                            }}
                                                                                            aria-label="Toggle Details"
                                                                                          >
                                                                                            <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                          </button></div>
                                                                                        <div className="selectprice">
                                                                                          {(() => {
                                                                                            const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                            const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                            const airline = inputOrigin;
                                                                                            const calculatedPrice = calculateFinalPrice(
                                                                                              numericTotalPrice,
                                                                                              markupdata,
                                                                                              cabinClass,
                                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                              , airline, flight_type
                                                                                            );

                                                                                            return (
                                                                                              <>
                                                                                                {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                                {calculatedPrice}
                                                                                              </>
                                                                                            );
                                                                                          })()}
                                                                                        </div>
                                                                                        {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                          <>
                                                                                            <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                            <div className="selectdetail">
                                                                                              <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                              <ul><li>
                                                                                                {priceParseData['air:AirPricingInfo']['air:FareInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'][0] &&
                                                                                                  priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement'] &&
                                                                                                  priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement']['$'] &&
                                                                                                  priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement']['$']['Value']}
                                                                                              </li></ul>
                                                                                            </div>
                                                                                          </>
                                                                                        )}
                                                                                      </div>
                                                                                    </>
                                                                                  )
                                                                              ) : (
                                                                                <>
                                                                                  <div>
                                                                                    <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name']}
                                                                                      <button
                                                                                        type="button"
                                                                                        onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'])}

                                                                                        style={{
                                                                                          border: "none",
                                                                                          background: "none",
                                                                                          cursor: "pointer",
                                                                                          fontSize: "16px",
                                                                                          color: "#785eff",
                                                                                        }}
                                                                                        aria-label="Toggle Details"
                                                                                      >
                                                                                        <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                      </button></div>
                                                                                    <div className="selectprice">
                                                                                      {(() => {
                                                                                        const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                        const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                        const airline = inputOrigin;
                                                                                        const calculatedPrice = calculateFinalPrice(
                                                                                          numericTotalPrice,
                                                                                          markupdata,
                                                                                          cabinClass,
                                                                                          priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                          , airline, flight_type
                                                                                        );

                                                                                        return (
                                                                                          <>
                                                                                            {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                            {calculatedPrice}
                                                                                          </>
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                    {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['air:Brand']['$']['Name'] && (
                                                                                      <>
                                                                                        <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                        <div className="selectdetail">
                                                                                          <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                          <ul><li>
                                                                                            {priceParseData && priceParseData['air:AirPricingInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'][0] &&
                                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement'] &&
                                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement']['$'] &&
                                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo'][0]['common_v52_0:Endorsement']['$']['Value']}
                                                                                          </li></ul>
                                                                                        </div>
                                                                                      </>
                                                                                    )}
                                                                                  </div>
                                                                                </>
                                                                              )

                                                                            ) : (

                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['air:Text'] &&
                                                                                priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['air:Text'] ? (
                                                                                Array.isArray(priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['air:Text'])

                                                                                  ? (
                                                                                    <div>
                                                                                      <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                        <button
                                                                                          type="button"
                                                                                          onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                          style={{
                                                                                            border: "none",
                                                                                            background: "none",
                                                                                            cursor: "pointer",
                                                                                            fontSize: "16px",
                                                                                            color: "#785eff",
                                                                                          }}
                                                                                          aria-label="Toggle Details"
                                                                                        >
                                                                                          <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>

                                                                                        </button>
                                                                                      </div>
                                                                                      <div className="selectprice">
                                                                                        {(() => {
                                                                                          const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                          const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                          const airline = inputOrigin;
                                                                                          const calculatedPrice = calculateFinalPrice(
                                                                                            numericTotalPrice,
                                                                                            markupdata,
                                                                                            cabinClass,
                                                                                            priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                            , airline, flight_type
                                                                                          );

                                                                                          return (
                                                                                            <>
                                                                                              {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                              {calculatedPrice}
                                                                                            </>
                                                                                          );
                                                                                        })()}
                                                                                      </div>


                                                                                      {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] && (() => {
                                                                                        const validDetails = priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['air:Text'].filter(
                                                                                          (textinfor) => textinfor['$']?.Type === "MarketingConsumer"
                                                                                        );

                                                                                        const closeButton = (
                                                                                          <button className="selectdetail-close" style={{ marginTop: "-3%", marginRight: "-3%" }} onClick={() => setVisibleDetails(false)} >
                                                                                            &times;
                                                                                          </button>
                                                                                        );

                                                                                        return validDetails.length > 0 ? (
                                                                                          validDetails.map((textinfor, textindex) => {
                                                                                            const infoArray = textinfor['_']
                                                                                              .split('\n')
                                                                                              .filter((item) => item.trim() !== '');

                                                                                            return (
                                                                                              <div key={textindex}>
                                                                                                <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                                <div className="selectdetail">
                                                                                                  {closeButton}
                                                                                                  <ul>
                                                                                                    {infoArray.map((item, index) => (
                                                                                                      <li key={index}>{item.trim()}</li>
                                                                                                    ))}
                                                                                                  </ul>
                                                                                                </div>
                                                                                              </div>
                                                                                            );
                                                                                          })
                                                                                        ) : (
                                                                                          <div>
                                                                                            <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                            <div className="selectdetail">
                                                                                              {closeButton}
                                                                                              <p>No details are available at present. Please check back later.</p>
                                                                                            </div>
                                                                                          </div>
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                  ) : (
                                                                                    <>
                                                                                      <div>
                                                                                        <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                          <button
                                                                                            type="button"
                                                                                            onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                            style={{
                                                                                              border: "none",
                                                                                              background: "none",
                                                                                              cursor: "pointer",
                                                                                              fontSize: "16px",
                                                                                              color: "#785eff",
                                                                                            }}
                                                                                            aria-label="Toggle Details"
                                                                                          >
                                                                                            <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                          </button></div>

                                                                                        <div className="selectprice">
                                                                                          {(() => {
                                                                                            const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                            const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                            const airline = inputOrigin;
                                                                                            const calculatedPrice = calculateFinalPrice(
                                                                                              numericTotalPrice,
                                                                                              markupdata,
                                                                                              cabinClass,
                                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                              , airline, flight_type
                                                                                            );

                                                                                            return (
                                                                                              <>
                                                                                                {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                                {calculatedPrice}
                                                                                              </>
                                                                                            );
                                                                                          })()}
                                                                                        </div>
                                                                                        {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                          <>
                                                                                            <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                            <div className="selectdetail">
                                                                                              <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                              <ul><li>
                                                                                                {priceParseData['air:AirPricingInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'] &&
                                                                                                  priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement'] &&
                                                                                                  priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement']['$'] &&
                                                                                                  priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement']['$']['Value']}
                                                                                              </li></ul>
                                                                                            </div>
                                                                                          </>
                                                                                        )}
                                                                                      </div>
                                                                                    </>
                                                                                  )
                                                                              ) : (
                                                                                <>
                                                                                  <div>
                                                                                    <div className="seelctheader">{priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name']}
                                                                                      <button
                                                                                        type="button"
                                                                                        onClick={() => toggleDetails(priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'])}

                                                                                        style={{
                                                                                          border: "none",
                                                                                          background: "none",
                                                                                          cursor: "pointer",
                                                                                          fontSize: "16px",
                                                                                          color: "#785eff",
                                                                                        }}
                                                                                        aria-label="Toggle Details"
                                                                                      >
                                                                                        <i className="fas fa-info-circle" style={{ color: '#785eff', marginLeft: '5px', fontSize: '12px', cursor: 'pointer' }}></i>
                                                                                      </button></div>
                                                                                    <div className="selectprice">
                                                                                      {(() => {
                                                                                        const totalPrice = priceParseData['air:AirPricingInfo'].$.TotalPrice;
                                                                                        const numericTotalPrice = totalPrice.replace('INR', '').trim(); // Extract numeric part of price
                                                                                        const airline = inputOrigin;
                                                                                        const calculatedPrice = calculateFinalPrice(
                                                                                          numericTotalPrice,
                                                                                          markupdata,
                                                                                          cabinClass,
                                                                                          priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] // Pass the fare name (e.g., "ECO VALUE")
                                                                                          , airline, flight_type
                                                                                        );

                                                                                        return (
                                                                                          <>
                                                                                            {totalPrice.includes('INR') ? '₹ ' : ''}
                                                                                            {calculatedPrice}
                                                                                          </>
                                                                                        );
                                                                                      })()}
                                                                                    </div>
                                                                                    {visibleDetails && visibleDetailsByName === priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] && (
                                                                                      <>
                                                                                        <div className="popup-overlay" onClick={() => setVisibleDetails(false)}></div>
                                                                                        <div className="selectdetail">
                                                                                          <button className="selectdetail-close" style={{ marginTop: "-3.5%", marginRight: "-3.5%" }} onClick={() => setVisibleDetails(false)}>&times;</button>
                                                                                          <ul><li>
                                                                                            {priceParseData['air:AirPricingInfo'] && priceParseData['air:AirPricingInfo']['air:FareInfo'] &&
                                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement'] &&
                                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement']['$'] &&
                                                                                              priceParseData['air:AirPricingInfo']['air:FareInfo']['common_v52_0:Endorsement']['$']['Value']}
                                                                                          </li></ul>
                                                                                        </div>
                                                                                      </>
                                                                                    )}
                                                                                  </div>
                                                                                </>
                                                                              )
                                                                            )

                                                                        )
                                                                      )
                                                                  )}
                                                                </div>

                                                                {is_approved === '1'  && (
                                                                  <div className='buttonbook' style={{ width: "37%" }}><button type='button' className="continuebutton" style={{ marginTop: "7px", color: "white", backgroundColor: "#785eff", border: "none", padding: "5px 5px 5px 5px", borderRadius: "3px" }} onClick={() => handleselectedContinue(priceParseindex)}>Book Now</button></div>
                                                                )}
                                                                <button
                                                                  className="add-btn"
                                                                  type="button"
                                                                  title="Share Flight Option"
                                                                  onClick={() => {
                                                                    const fareName = extractFareName(priceParseData);
                                                                    const farePrice = priceParseData['$']['TotalPrice']
                                                                    handleCheckboxChange(pricepoint["air:AirPricingInfo"], farePrice, fareName)
                                                                  }}
                                                                >
                                                                  {
                                                                    selectedFlights.some((flight) => {
                                                                      return (
                                                                        flight["$"]["Key"] === pricepoint["air:AirPricingInfo"]["$"]["Key"] &&
                                                                        flight.fare_details.some(
                                                                          (fare) =>
                                                                            fare.fare_type === extractFareName(priceParseData) &&
                                                                            fare.price === priceParseData['$']['TotalPrice']
                                                                        )
                                                                      );
                                                                    })
                                                                      ? '-'
                                                                      : '+'
                                                                  }
                                                                </button>
                                                                {/* <button
                                                                      className="add-btn"
                                                                      type="button"
                                                                      onClick={() => {
                                                                        const fareName = priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'];
                                                                        const farePrice = priceParseData['air:AirPricingInfo']['$']['TotalPrice'].replace('INR', '').trim(); // Adjust logic if needed

                                                                        handleSegmentRefClick(priceParseData['air:AirSegmentRef']['$']['Key'], farePrice, fareName); // Call with appropriate values
                                                                      }}
                                                                    >
                                                                      {
                                                                        selectedFlightss.some(
                                                                          (flight) =>
                                                                            flight.segment["$"]["Key"] === priceParseData['air:AirSegmentRef']['$']['Key'] &&
                                                                            flight.fares.some(
                                                                              (fare) =>
                                                                                fare.name === priceParseData['air:AirPricingInfo']['air:FareInfo']['air:Brand']['$']['Name'] &&
                                                                                fare.price === priceParseData['air:AirPricingInfo']['$']['TotalPrice'].replace('INR', '').trim() // Adjust logic if needed
                                                                            )
                                                                        )
                                                                          ? '-'
                                                                          : '+'
                                                                      }
                                                                    </button> */}
                                                              </div>

                                                            ))
                                                          ))
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="flt-i-c">
                                                    <div className="flt-i-padding">

                                                      <button type="submit"
                                                        className="srch-btn" variant="primary" style={{ borderRadius: '18px' }}>
                                                        <span style={{ fontSize: "12px" }}>View Prices</span>
                                                      </button>
                                                      <div className="flight-line-b">
                                                        <b onClick={() => handleFlightDetails(priceindex)}>
                                                          {visibleFlightIndex === priceindex ? "Hide Flight Details" : "Show Flight Details"}
                                                        </b>
                                                      </div>
                                                      {visibleFlightIndex === priceindex && (
                                                        <div className="flight-details">
                                                          {/* Render your flight details here */}
                                                        </div>
                                                      )}
                                                      <div className="clear" />

                                                    </div>
                                                  </div>

                                                  <div className="clear" />
                                                </div>
                                              </div>
                                            )}

                                          </div>

                                        </form>
                                      </React.Fragment>
                                    )
                                  }
                                }
                              })

                          ) : (
                            <div key="not-found">
                              <div style={{ textAlign: 'center', background: 'white', padding: '10px' }}>
                                <p style={{ fontWeight: '600' }} className='datanotfound'>
                                  No Data is found for applied filter.
                                </p>
                                <p>We couldn't find any flights with all the filters you’ve selected. Try removing some filters</p>
                                <a href="/" className="back-home-btn" style={{ fontWeight: '600', lineHeight: '3.5', backgroundColor: '#785eff', padding: '15px 35px', color: '#fff', textDecoration: 'none', width: '100%', textTransform: 'uppercase', fontFamily: 'raleway', fontSize: '14px' }}>
                                  Back to Home
                                </a>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <div style={{ textAlign: 'center', background: 'white', padding: '10px' }}>
                            {/* <img src="img/taxivaxi/result_not_found/result not found 1 taxivaxi.png" style={{ width: '100%', paddingBottom: '50px' }} /> */}
                            <p style={{ fontWeight: '600' }} className='datanotfound'>This search flights are not available. Please modify your search..</p>
                            <a href="/" className="back-home-btn" style={{ fontWeight: '600', lineHeight: '3.5', backgroundColor: '#785eff', padding: '15px 35px', color: '#fff', textDecoration: 'none', width: '100%', textTransform: 'uppercase', fontFamily: 'raleway', fontSize: '14px' }}>Back to Home</a>
                          </div>
                        </>
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
      </div>
      {selectedFlights.length > 0 && (
        <div>
          {isMinimized ? (
            // Display the minimized ball when the popup is minimized
            <div className="minimized-ball" onClick={handleExpand}>
              ⚪
            </div>
          ) : (
            // Display the popup when not minimized
            <div className="selected-flight-container">
              <div className="selected-flight-header">
                <span>Selected Flights</span>
                <button className="close-btn" onClick={handleClose} style={{ marginRight: '2%' }}>
                  &minus;
                </button>
              </div>
              <div className="selected-flight-list">
                {selectedFlights.map((flight, flightIndex) => (
                  <div className="flight-item" key={flightIndex}>
                    <img
                      src={`https://devapi.taxivaxi.com/airline_logo_images/${flight.flightDetails[0]?.carrier}.png`}
                      alt={flight.flightDetails[0]?.carrier}
                      className="flight-logo"
                    />
                    <div className="flight-detailss">
                      <span className="flight-airline">
                        {handleAirline(flight.flightDetails[0]?.carrier)}{' '}
                        {flight.flightDetails[0]?.flightNumber}
                      </span>
                      <span className="flight-time">
                        {new Date(flight.flightDetails[0]?.departure_time).toLocaleTimeString([], {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}{' '}
                        -{' '}
                        {new Date(
                          flight.flightDetails[flight.flightDetails.length - 1]?.arrival_time
                        ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                      </span>
                    </div>
                    <div className="flight-price">
                      {flight.fare_details.map((fare, fareIndex) => (
                        <div
                          key={fareIndex}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between', // Ensures spacing between fare details and the button
                            alignItems: 'center', // Aligns content vertically
                            marginBottom: '6px',
                          }}
                        >
                          {/* Left: Price and Fare Type */}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',

                            }}
                          >
                            <span
                              style={{
                                fontSize: '16px',
                                fontWeight: fareIndex === 0 ? 'bold' : 'bold',
                                color: fareIndex === 0 ? '#000' : '#785eff',
                              }}
                            >
                              ₹ {fare.price.replace('INR', '').trim()}
                            </span>
                            <span
                              style={{
                                fontSize: '10px', textAlign: 'left',
                                color: fareIndex === 0 ? '#888' : '#785eff',
                              }}
                            >
                              {fare.fare_type}
                            </span>
                          </div>

                          {/* Right: Remove Button */}
                          <button
                            className="remove-btn"
                            style={{
                              background: 'none',
                              border: 'none',
                              color: 'red',
                              fontSize: '18px',
                              cursor: 'pointer',
                              marginLeft: '8px',
                            }}
                            onClick={() => handleRemoveFare(flightIndex, fareIndex)}
                          >
                            ×
                          </button>
                        </div>
                      ))}
                    </div>

                  </div>
                ))}
              </div>

              <div className="share-button-container">
                <button onClick={modalopen} className="share-btn">
                  Share Flight Options
                </button>
              </div>
            </div>
          )}
        </div>
      )}


      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} aria-labelledby="modal-title">
        <Modal.Header className="custom-modal-header">
          <Modal.Title id="modal-title">Share Flights with</Modal.Title>
          <button className="close-btn" onClick={() => setIsModalOpen(false)}>×</button>
        </Modal.Header>
        <Modal.Body className="custom-modal-body">
          <form>
            <div className="form-row">
              <div className="form-group">
                <label>Client Name</label>
                <input
                  type="text"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  disabled
                />
              </div>
              <div className="form-group">
                <label>SPOC Name</label>
                <input
                  type="text"
                  value={spocName}
                  onChange={(e) => setSpocName(e.target.value)}
                  disabled
                />
              </div>
            </div>
            <div className="form-group">
              <label>Booking ID</label>
              <input
                type="text"
                value={bookingid}
                disabled
              />
            </div>

            <div className="form-group">
  <label>Email</label>
  <div className="chips-input-container">
    {spocEmails.map((email, index) => (
      <div className="chip" key={index}>
        <span>{email}</span>
        <button type="button" onClick={() => setSpocEmails(spocEmails.filter(e => e !== email))}>×</button>
      </div>
    ))}
    <input
      type="email"
      value={spocEmailInput || ""}
      onChange={(e) => setSpocEmailInput(e.target.value)}
      placeholder={spocEmails.length === 0 ? "Add Email" : "Add Email"}
      onBlur={handleAddSpocEmailOnBlur} // Add email when input loses focus
    />
  </div>
</div>

            <div className="form-group">
              <label>Additional Email</label>
              <div className="chips-input-container">
                {additionalEmails
                  .filter((email) => typeof email === "string" && email.trim() !== "") // Filter undefined and empty emails
                  .map((email, index) => (
                    <div className="chip" key={index}>
                      <span>{email}</span>
                      <button type="button" onClick={() => handleRemoveEmail(email)}>×</button>
                    </div>
                  ))}
                <input
                  type="email"
                  value={additionalEmailInput || ""} // Ensure input is never undefined
                  onChange={(e) => setAdditionalEmailInput(e.target.value)}
                  placeholder={
                    additionalEmails.length === 0 && additionalEmailInput.trim() === ""
                      ? "Add email"
                      : "Add email"
                  } // Show placeholder only when no emails and input is empty
                  onBlur={handleAddEmailOnBlur} // Add email when input loses focus
                />
              </div>
            </div>


            <div className="form-group">
              <label>CC Email</label>
              <div className="chips-input-container">
                {ccEmails
                  .filter((email) => typeof email === "string" && email.trim() !== "") // Filter undefined and empty emails
                  .map((email, index) => (
                    <div className="chip" key={index}>
                      <span>{email}</span>
                      <button type="button" onClick={() => handleRemoveCCEmail(email)}>×</button>
                    </div>
                  ))}
                <input
                  type="email"
                  value={ccEmailInput || ""} // Ensure input is never undefined
                  onChange={(e) => setCCEmailInput(e.target.value)}
                  placeholder={
                    ccEmails.length === 0 && ccEmailInput.trim() === ""
                      ? "Add CC email"
                      : "Add CC email"
                  } // Show placeholder only when no emails and input is empty
                  onBlur={handleAddCCEmailOnBlur} // Add email when input loses focus
                />
              </div>
            </div>

            <div className="form-group">
              <label>Remark</label>
              <textarea
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="custom-modal-footer">
          <button className="send-button" onClick={handleSend}>SEND</button>
        </Modal.Footer>
      </Modal>



    </div >

  );
}

export default SearchFlight;                                